import * as React from "react";
import { Container, Grid, Typography } from "@mui/material";
import Tasks from "../components/dashboard/Tasks";
import Page from "../components/Page";
import Documents from "../components/dashboard/Documents";
import AppNewsUpdate from "../components/dashboard/AppNewsUpdate";
import { useTranslation } from "react-i18next";
import { Template } from "../structs/gen/template";
import { api } from "../services/api";
import { useNavigate } from "react-router-dom";
import { Menu } from "../structs/gen/menu";
import { UserContext } from "../App";
import { RoleTypeEnum } from "../structs/gen/enums/user";
import CaneldarEvents from "../components/dashboard/CalendarEvents";
import CalendarEvents from "../components/dashboard/CalendarEvents";
import AppTrafficBySite from "../components/dashboard/AppTrafficBySite";
import { Facebook } from "@mui/icons-material";
import AppOrderTimeline from "../components/dashboard/AppOrderTimeline";
import StatisticValue from "../components/dashboard/StatisticValue";
import Statistics from "../components/dashboard/Statistics";
// import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";

export default function Dashboard() {
  const { t } = useTranslation();
  const { userData } = React.useContext(UserContext);

  return (
    <Page title={t("dashboard:title")}>
      <Container maxWidth="xl">
        {/* <Typography variant="h4" sx={{ mb: 5 }}>
          {t("dashboard:hello_message")}
        </Typography> */}

        <Grid container spacing={3}>
          {/* <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Weekly Sales"
              total={714000}
              color={""} //icon={"ant-design:android-filled"}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="New Users"
              total={1352831}
              color="info"
              icon={"ant-design:apple-filled"}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Item Orders"
              total={1723315}
              color="warning"
              icon={"ant-design:windows-filled"}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary
              title="Bug Reports"
              total={234}
              color="error"
              icon={"ant-design:bug-filled"}
            />
          </Grid> */}
          {/* <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Website Visits"
              subheader="(+43%) than last year"
              chartLabels={[
                "01/01/2003",
                "02/01/2003",
                "03/01/2003",
                "04/01/2003",
                "05/01/2003",
                "06/01/2003",
                "07/01/2003",
                "08/01/2003",
                "09/01/2003",
                "10/01/2003",
                "11/01/2003",
              ]}
              chartData={[
                {
                  name: "Team A",
                  type: "column",
                  fill: "solid",
                  data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                },
                {
                  name: "Team B",
                  type: "area",
                  fill: "gradient",
                  data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                },
                {
                  name: "Team C",
                  type: "line",
                  fill: "solid",
                  data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Current Visits"
              chartData={[
                { label: "America", value: 4344 },
                { label: "Asia", value: 5435 },
                { label: "Europe", value: 1443 },
                { label: "Africa", value: 4443 },
              ]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.chart.blue[0],
                theme.palette.chart.violet[0],
                theme.palette.chart.yellow[0],
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Conversion Rates"
              subheader="(+43%) than last year"
              chartData={[
                { label: "Italy", value: 400 },
                { label: "Japan", value: 430 },
                { label: "China", value: 448 },
                { label: "Canada", value: 470 },
                { label: "France", value: 540 },
                { label: "Germany", value: 580 },
                { label: "South Korea", value: 690 },
                { label: "Netherlands", value: 1100 },
                { label: "United States", value: 1200 },
                { label: "United Kingdom", value: 1380 },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={[
                "English",
                "History",
                "Physics",
                "Geography",
                "Chinese",
                "Math",
              ]}
              chartData={[
                { name: "Series 1", data: [80, 50, 30, 40, 100, 20] },
                { name: "Series 2", data: [20, 30, 40, 80, 20, 80] },
                { name: "Series 3", data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(
                () => theme.palette.text.secondary,
              )}
            />
          </Grid>*/}
          {/* {userData && userData.role !== RoleTypeEnum.client && (
            <Grid item xs={12} md={6} lg={4}>
              <DateCalendar />
            </Grid>
          )} */}
          {/* <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              subheader=""
              list={[...Array(5)].map((_, index) => ({
                id: index,
                title: [
                  "1983, orders, $4220",
                  "12 Invoices have been paid",
                  "Order #37745 from September",
                  "New order placed #XF-2356",
                  "New order placed #XF-2346",
                ][index],
                type: `order${index + 1}`,
                time: Date.now(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Traffic by Site"
              subheader=""
              list={[
                {
                  name: "FaceBook",
                  value: 323234,
                  icon: (
                    <Facebook
                      style={{ color: "#1877F2" }}
                      width={32}
                      height={32}
                    />
                  ),
                },
                {
                  name: "Google",
                  value: 341212,
                  icon: (
                    <Facebook
                      style={{ color: "#1877F2" }}
                      width={32}
                      height={32}
                    />
                  ),
                },
                {
                  name: "Linkedin",
                  value: 411213,
                  icon: (
                    <Facebook
                      style={{ color: "#1877F2" }}
                      width={32}
                      height={32}
                    />
                  ),
                },
                {
                  name: "Twitter",
                  value: 443232,
                  icon: (
                    <Facebook
                      style={{ color: "#1877F2" }}
                      width={32}
                      height={32}
                    />
                  ),
                },
              ]}
            />
          </Grid> */}
          {userData && userData.role !== RoleTypeEnum.client && (
            <Grid item xs={12} md={6} lg={4}>
              <CalendarEvents title="Events" />
            </Grid>
          )}
          {userData && userData.role !== RoleTypeEnum.client && (
            <Grid item xs={12} md={6} lg={8}>
              <Tasks title={t("dashboard:tasks")} />
            </Grid>
          )}

          <Statistics />
          <Grid item xs={12} md={12} lg={12}>
            <Documents title="Recent documents" subheader="" />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
