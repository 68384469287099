import * as React from "react";
import Paper from "@mui/material/Paper";
import { api } from "../../services/api";
import {
  alpha,
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  Stack,
  styled,
  Toolbar,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Template } from "../../structs/gen/template";
import BuildFields from "../../components/field/BuildFields";
import { DOCUMENT_URL } from "../../structs/urls";
import { Document } from "../../structs/gen/document";
import Page from "../../components/Page";
import { Done } from "@mui/icons-material";
import LanguagePopover from "../../components/LanguagePopover";
import { useTranslation } from "react-i18next";
import { createDefaultDocument } from "../../structs/document";

async function apiGetTemplate(id: string): Promise<Template> {
  let resp = await api.get(`/api/public/template/${id}`);
  return resp.data;
}

async function apiSubmit(data: Document) {
  let resp = await api.post(`/api/public/document/submit`, data);
  console.log("resp", resp);
}

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

const DRAWER_WIDTH = 280;
const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const ToolbarRootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up("lg")]: {
    width: `100%`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

export default function DocumentFill() {
  const navigate = useNavigate();
  const params = useParams();
  const templateID = String(params["template_id"]!);
  const [template, setTemplate] = React.useState<Template | undefined>(
    undefined,
  );
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [document, setDocument] = React.useState<Document | undefined>(
    undefined,
  );
  const [loading, setLoading] = React.useState(true);
  const [errors, setErrors] = React.useState<any>({});
  const { t, i18n } = useTranslation();
  console.log("current language", i18n.language);
  const [isExecuting, setIsExecuting] = React.useState(false);

  if (loading) {
    setLoading(false);
    apiGetTemplate(templateID!).then((resp: Template) => {
      setTemplate(resp);
      setDocument(createDefaultDocument(resp));
    });
  }

  if (!template || !document) {
    return <CircularProgress />;
  }

  if (isSubmitted) {
    return (
      <Page title="Error">
        <Container>
          <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
            <Typography variant="h3" paragraph>
              Successfully sent
            </Typography>

            <Typography sx={{ color: "text.secondary" }}>
              The document was successfully completed and sent to the template
              creator.
            </Typography>

            <Done
              sx={{ height: 260, mx: "auto", my: { xs: 5, sm: 10 } }}
              style={{ height: 260, width: 260 }}
              color={"success"}
            />

            <Button
              size="large"
              variant="contained"
              onClick={() => {
                window.close();
              }}
            >
              Done
            </Button>
          </ContentStyle>
        </Container>
      </Page>
    );
  }

  return (
    <>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <ToolbarRootStyle>
          <ToolbarStyle>
            {/* <SearchbarStyle>
                <Input
                  autoFocus
                  disableUnderline
                  placeholder="Search…"
                  endAdornment={
                    <InputAdornment position="start">
                      <SearchOutlined
                        style={{
                          color: "text.disabled",
                          width: 20,
                          height: 20,
                        }}
                      />
                    </InputAdornment>
                  }
                  sx={{ mr: 1, fontWeight: "fontWeightBold" }}
                />
              </SearchbarStyle> */}
            <Box sx={{ flexGrow: 1 }} />
            <Typography color={"black"} textAlign={"center"} variant={"h5"}>
              {template.name}
            </Typography>
            <Box sx={{ flexGrow: 1 }} />

            <Stack
              direction="row"
              alignItems="center"
              spacing={{ xs: 0.5, sm: 1.5 }}
            >
              <LanguagePopover lang={i18n.language} />
              {/* <NotificationsPopover />*/}
            </Stack>
          </ToolbarStyle>
        </ToolbarRootStyle>
      </AppBar>
      <Page title={template.name}>
        <Box display="flex" justifyContent="center" style={{ marginTop: 100 }}>
          <Paper
            elevation={9}
            title={template && template.name}
            sx={{ mt: 1 }}
            style={{ padding: "20px", width: "700px" }}
          >
            <BuildFields
              templateId={template.id}
              config={template.data}
              onUpdated={(data, config, name, user_access) => {
                if (config != null) {
                  setTemplate({
                    ...template,
                    data: config,
                  });
                }
                let newData: any = {};
                let isUpdated: boolean = false;
                if (data != null) {
                  newData.data = data;
                  isUpdated = true;
                }
                if (name != null) {
                  newData.name = name;
                  isUpdated = true;
                }
                if (user_access != null) {
                  newData.user_access = user_access;
                  isUpdated = true;
                }
                if (isUpdated) {
                  setDocument({
                    ...document,
                    convertValues: document.convertValues,
                    ...newData,
                  });
                }
              }}
              document={document}
              formula={template.formula}
              setErrors={setErrors}
              errors={errors}
              isDev={false}
              isConstructorMode={false}
              onExecuting={(isExecuting) => {
                setIsExecuting(isExecuting);
              }}
              executeOn={template.execute_on}
            />
            <Button
              variant="contained"
              fullWidth
              color="success"
              style={{ marginTop: "20px" }}
              disabled={isExecuting}
              onClick={async () => {
                if (errors.length === undefined || errors.length === 0) {
                  await apiSubmit(document!);
                  setIsSubmitted(true);
                }
              }}
            >
              Submit
            </Button>
          </Paper>
        </Box>
      </Page>
    </>
  );
}
