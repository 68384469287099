import * as React from "react";
import TableContainer from "@mui/material/TableContainer";
import { api } from "../../services/api";
import { User, UserListResponse } from "../../structs/gen/user";
import { Button, Card, Container, Stack, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { PDF_EDIT_URL, USER_EDIT_URL } from "../../structs/urls";
import Page from "../../components/Page";
import { Add, Delete } from "@mui/icons-material";
import DeleteWithConfirm from "../../components/DeleteWithConfirm";
import { useTranslation } from "react-i18next";
import { PDF, PDFListResponse } from "../../structs/gen/pdf";

async function apiList(): Promise<PDFListResponse> {
  let resp = await api.post(`/api/pdf/list`, {
    offset: 0,
    next: 100000,
    filters: [],
  });
  console.log("resp.data", resp.data);
  return resp.data;
}

async function apiDelete(id: string, callback: any) {
  let resp = await api.delete(`/api/pdf/${id}`);
  callback(resp.data);
}

export default function List() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [rows, setRows] = React.useState<PDF[] | undefined>(undefined);
  const [loading, setLoading] = React.useState(true);

  if (loading) {
    setLoading(false);
    apiList().then((resp) => {
      setRows(resp.rows);
    });
  }

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: t("pdf_list:field_id"),
      flex: 1,
    },
    {
      field: "name",
      headerName: t("pdf_list:field_name"),
      flex: 1,
    },
    {
      field: "action",
      headerName: t("pdf_list:action"),
      sortable: false,
      renderCell: (params) => {
        const onDelete = () => {
          var userID = String(params.id);
          apiDelete(userID, () => {
            setRows(rows?.filter((v) => v.id !== userID));
          });
        };

        return <DeleteWithConfirm onClick={onDelete} />;
      },
    },
    // {
    //   field: 'lastName',
    //   headerName: 'Last name',
    //   width: 150,
    //   editable: true,
    // },
    // {
    //   field: 'age',
    //   headerName: 'Age',
    //   type: 'number',
    //   width: 110,
    //   editable: true,
    // },
    // {
    //   field: 'fullName',
    //   headerName: 'Full name',
    //   description: 'This column has a value getter and is not sortable.',
    //   sortable: false,
    //   width: 160,
    //   valueGetter: (params: GridValueGetterParams) =>
    //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    // },
  ];

  console.log("rows", rows);
  return (
    <>
      <Page title={t("pdf_list:title")}>
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              {t("pdf_list:title")}
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                navigate(PDF_EDIT_URL);
              }}
              startIcon={<Add />}
            >
              {t("pdf_list:create")}
            </Button>
          </Stack>

          <Card>
            <TableContainer sx={{ minWidth: 800 }}>
              <DataGrid
                rows={rows ? rows : []}
                columns={columns}
                pageSize={10}
                disableSelectionOnClick
                onCellClick={(e) => {
                  console.log(e);
                  if (e.field !== "action") navigate(PDF_EDIT_URL + e.id);
                }}
                autoHeight
                loading={loading && !rows}
              />
            </TableContainer>

            {/* <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={USERLIST.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
          </Card>
        </Container>
      </Page>
    </>
  );
}

//TODO: https://github.com/Hopding/pdf-lib
