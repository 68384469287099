import {
  PasswordOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Modal,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { api } from "../../services/api";

async function pwd(password: string) {
  try {
    let resp = await api.post(`/api/password`, {
      token: "",
      password: password,
    });
    return resp.data;
  } catch (e: any) {
    console.log("resp", e.response.data);
    return e.response.data;
  }
}

const modalStyle = {
  // position: "absolute" as "absolute",
  // top: "50%",
  // left: "50%",
  // transform: "translate(-50%, -50%)",

  position: "absolute" as "absolute",
  marginLeft: "auto",
  marginRight: "auto",
  left: 0,
  right: 0,
  textAlign: "center",
  marginTop: "25px",
  marginBottom: "25px",

  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function Password({ email }: { email: string }) {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);

  return (
    <>
      <Modal open={isShowModal} onClose={() => setIsShowModal(false)}>
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit field
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={email}
            disabled
            onChange={() => {}}
            hidden
          />
          <InputLabel htmlFor="outlined-adornment-password">
            New Password
          </InputLabel>
          <OutlinedInput
            fullWidth
            style={{ width: "100%" }}
            label="New Password"
            id="outlined-adornment-password"
            type={showPassword ? "text" : "password"}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            value={password}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          <Button
            onClick={async () => {
              await pwd(password);
              setIsShowModal(false);
            }}
            color="success"
          >
            Save
          </Button>
        </Box>
      </Modal>
      <Button
        startIcon={<PasswordOutlined />}
        variant="contained"
        fullWidth
        style={{ margin: "20px" }}
        onClick={async () => {
          setIsShowModal(true);
        }}
      >
        Update password
      </Button>
    </>
  );
}
