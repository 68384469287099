import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DASHBOARD_URL, LOGIN_URL } from "../structs/urls";
import { api, setToken } from "../services/api";
import { useNavigate } from "react-router-dom";

const theme = createTheme();

async function register(email: string) {
  try {
    let resp = await api.post(`/api/register`, {
      email: email,
    });
    console.log("resp.data", resp.data);
    setToken(resp.data.access_token);
    return resp.data;
  } catch (e: any) {
    console.log("e", e);
    console.log("resp", e.response);
    return e.response.data;
  }
}

export default function SignUp() {
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState<undefined | string>(undefined);
  const navigate = useNavigate();

  const registerAction = async () => {
    var respData = await register(email.trim());
    if (respData.error && respData.error_description) {
      setError(respData.error_description);
    } else {
      navigate(DASHBOARD_URL);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form" noValidate sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  error={!!error}
                  helperText={error && error!}
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={email}
                  onChange={(e) => {
                    setError(undefined);
                    setEmail(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value="allowExtraEmails"
                      color="primary"
                      checked={true}
                    />
                  }
                  label="I have read and agree to the privacy policy, terms of service, and community guidelines."
                />
              </Grid>
            </Grid>
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={registerAction}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href={LOGIN_URL} variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
