import * as React from "react";
import {
  Autocomplete,
  Button,
  Card,
  Collapse,
  FormControl,
  IconButton,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import {
  Add,
  Delete,
  Expand,
  ExpandLess,
  ExpandMore,
  Translate,
} from "@mui/icons-material";
import Page from "./Page";
import { availableLanguages } from "./../i18n";

export default function MultiLanguageLabel({
  value,
  multiValue,
  onChange,
  onChangeMultiLabel,
}: {
  value: string;
  multiValue: { [key: string]: string };
  onChange: (value: string) => void;
  onChangeMultiLabel: (value: { [key: string]: string }) => void;
}) {
  const [open, setOpen] = React.useState(false);
  const [selectedLang, setSelectedLang] = React.useState("");
  const [langs, setLangs] = React.useState(["ua", "en", "it"]);
  const langsRef = React.useRef<null | HTMLDivElement>(null);

  let mapMultiValue = [];
  let keys: string[] = [];
  for (var key in multiValue) {
    mapMultiValue.push({ key: key, v: multiValue[key] });
    keys.push(key);
  }
  console.log("multiValue", multiValue, mapMultiValue);
  //Map<Languages, string>
  return (
    <>
      <TextField
        fullWidth
        label="Label"
        value={value}
        type={"text"}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        InputProps={{
          endAdornment: (
            <IconButton
              onClick={() => {
                setOpen(!open);
              }}
            >
              <>
                <Translate />
                {open ? <ExpandLess /> : <ExpandMore />}
              </>
            </IconButton>
            // <Button
            //
            //   endIcon={}
            // >
            //   <div style={{ fontSize: "10px" }}>{"Translations"}</div>
            // </Button>
          ),
        }}
      />

      {open && (
        <>
          {mapMultiValue.map(({ key, v }) => {
            return (
              <Page style={{ maxHeight: 180, overflow: "scroll" }}>
                <Card
                  sx={{ p: 1, display: "flex", alignItems: "center" }}
                  ref={langsRef}
                >
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="lang">{key}</InputLabel>
                    <OutlinedInput
                      label={key}
                      value={v}
                      type={"text"}
                      fullWidth
                      onChange={(e) => {
                        multiValue[key] = e.target.value;
                        onChangeMultiLabel({
                          ...multiValue,
                        });
                      }}
                      endAdornment={
                        <IconButton
                          onClick={() => {
                            var newMultiValue: { [key: string]: string } = {};
                            for (var k in multiValue) {
                              if (k !== key) {
                                newMultiValue[k] = multiValue[k];
                              }
                            }
                            onChangeMultiLabel({ ...newMultiValue });
                          }}
                          sx={{ p: "10px" }}
                        >
                          <Delete style={{ width: "36px" }} color="error" />
                        </IconButton>
                      }
                    />
                  </FormControl>
                </Card>
              </Page>
            );
          })}
          {availableLanguages.filter((lang) => !keys.includes(lang)).length >
            0 && (
            <FormControl fullWidth sx={{ m: 1 }}>
              <Autocomplete
                disablePortal
                options={availableLanguages.filter(
                  (lang) => !keys.includes(lang),
                )}
                value={selectedLang}
                onChange={(e, key) => {
                  console.log("v", key);
                  if (key) {
                    if (!multiValue) multiValue = {};
                    multiValue[key] = "";
                    setSelectedLang("");
                    onChangeMultiLabel({
                      ...multiValue,
                    });
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} label="language" />
                )}
              />
            </FormControl>
          )}
        </>
      )}
    </>
  );
}
