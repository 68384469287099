import {
  Autocomplete,
  Box,
  Button,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { api } from "../../services/api";
import { Menu, MenuListResponse } from "../../structs/gen/menu";
import MultiLanguageLabel from "../MultiLanguageLabel";

interface MenuModalProps {
  show: boolean;
  template_id: string;
  menu_id?: string;
  moveToMenu: (menu_id: string | undefined) => void;
  onClose: () => void;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

async function menuList(): Promise<MenuListResponse> {
  let resp = await api.post(`/api/menu/list`, {
    offset: 0,
    next: 10,
    filters: [],
  });
  return resp.data;
}

async function apiSave(id: string | undefined, data: Menu): Promise<Menu> {
  let resp = await api.post(`/api/menu/${id ? id : ""}`, data);
  return resp.data;
}

const filter = createFilterOptions<Menu>();

const MenuModal: React.FC<MenuModalProps> = ({
  show,
  template_id,
  menu_id,
  moveToMenu,
  onClose,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [menus, setMenus] = useState<Menu[]>([]);
  const [newMenu, setNewMenu] = useState<Menu | null>(null);
  const [openNewMenu, setOpenNewMenu] = useState(false);

  if (loading) {
    setLoading(false);
    menuList().then((resp) => {
      setMenus([
        {
          id: "",
          name: "/",
          name_intl: {},
        },
        ...resp.rows,
      ]);
    });
  }
  return (
    <>
      <Dialog open={openNewMenu} onClose={() => setOpenNewMenu(false)}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            // setValue({
            //   title: dialogValue.title,
            //   year: parseInt(dialogValue.year, 10),
            // });
            setOpenNewMenu(false);
          }}
        >
          <DialogTitle>Add a new folder</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Did you miss any folder in the list? Please, add it!
            </DialogContentText>
            {newMenu && (
              <MultiLanguageLabel
                value={newMenu?.name}
                multiValue={newMenu.name_intl}
                onChange={(name) => {
                  setNewMenu({
                    ...newMenu,
                    name: name,
                  });
                }}
                onChangeMultiLabel={(name_intl) => {
                  setNewMenu({
                    ...newMenu,
                    name_intl: name_intl,
                  });
                }}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpenNewMenu(false);
              }}
            >
              Cancel
            </Button>
            <Button type="submit">Add</Button>
          </DialogActions>
        </form>
      </Dialog>
      <Modal
        open={show}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {t("dialog:title")}
          </Typography>
          <Autocomplete
            freeSolo
            disablePortal
            id="combo-box-demo"
            options={menus}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              const { inputValue } = params;
              // Suggest the creation of a new value
              const isExisting = options.some(
                (option) => inputValue === option.name,
              );
              if (inputValue !== "" && !isExisting) {
                filtered.push({
                  name: inputValue,
                  id: "new",
                  name_intl: {},
                });
              }

              return filtered;
            }}
            groupBy={(option) => option.parent_menu_id || ""}
            getOptionLabel={(option) => {
              if (typeof option === "string") {
                return option;
              } else {
                return option.name;
              }
            }}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Folder" />}
            onChange={async (event, newValue) => {
              console.log("v", newValue);

              if (typeof newValue === "string") {
                // setValue({
                //   title: newValue,
                // });
              } else if (newValue) {
                if (newValue.id === "new") {
                  let menu = await apiSave(undefined, newValue);
                  moveToMenu(menu.id);
                } else if (newValue.id === "") {
                  moveToMenu(undefined);
                } else {
                  moveToMenu(newValue.id);
                }
              }
            }}
            clearOnBlur
          />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Button color="primary" onClick={onClose}>
              Save
            </Button>

            <Button color="error" onClick={onClose}>
              Cancel
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};
export default MenuModal;
