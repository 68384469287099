import React, { useState, useEffect, useRef } from "react";
import SinglePage from "./SinglePage";
import ModifyPage from "./ModifyPage";
import TextArea, { TextAreaSettings } from "./TextArea";
import Image from "./Image";
import { Button } from "@mui/material";
import "./PDF.css";
import { Template } from "../../structs/gen/template";
import Object from "./Object";

export enum PDFElementType {
  text = "text",
  freehand = "freehand",
  image = "image",
}

export interface PDFElement {
  page: number;
  type: PDFElementType;
  id: string;
  x: number;
  y: number;
  ref: any;
  field_id: string | undefined;
  width: number;
  height: number;
}

export interface TextPDFElement extends PDFElement {
  text: string | undefined;
  fontSize: number;
}

export interface ImagePDFElement extends PDFElement {
  url: string | undefined;
}

export default function PDF({
  pdfURL,
  result,
  setResult,
  template,
  isEditingPDF,
  fileName,
}: {
  pdfURL: string;
  result: PDFElement[];
  setResult: (result: PDFElement[]) => void;
  template: Template | undefined;
  isEditingPDF: boolean;
  fileName: string;
}) {
  const [windowSize, setWindowSize] = useState<any>({});
  const [pageNumber, setPageNumber] = useState(1);
  const [redoStack, setRedoStack] = useState<any[]>([]);
  const [flag, setFlag] = useState("");
  const [bounds, setBounds] = useState<any>({});
  const [buttonType, setButtonType] = useState("");
  const tempRef = useRef<any>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    function handleResize() {
      if (
        windowSize &&
        windowSize.w === window.innerWidth &&
        windowSize.h === window.innerHeight
      ) {
        return;
      }
      setWindowSize({
        w: window.innerWidth,
        h: window.innerHeight,
      });
      // console.log(
      //   "resized to: ",
      //   windowSize,
      //   window.innerWidth,
      //   "x",
      //   window.innerHeight,
      // );

      setResult([...result]);
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [result]);

  //Keep track of current page number
  const pageChange = (num: any) => {
    setPageNumber(num);
  };

  //Function to add text in PDF
  const addText = () => {
    setResult([
      ...result,
      {
        id: generateKey(),
        x: 0,
        y: 0,
        text: "Example text",
        width: 100,
        height: 20,
        page: pageNumber,
        type: PDFElementType.text,
        ref: tempRef,
        fontSize: 16,
      } as TextPDFElement,
    ]);
  };

  //Function to add image in PDF
  const addImage = () => {
    setResult([
      ...result,
      {
        id: generateKey(),
        x: 0,
        y: 0,
        width: 100,
        height: 100,
        url: undefined,
        page: pageNumber,
        type: PDFElementType.image,
        ref: tempRef,
      } as ImagePDFElement,
    ]);
  };

  //Undo function for both line and text
  const undo = () => {
    let temp = result.pop();
    if (temp) {
      if (temp.type === "freehand") {
        //Flag for DrawArea reference
        setFlag("undo");
      }
      setRedoStack((stack: any) => [...stack, temp]);
      setResult(result);
    }
  };

  //Flag for DrawArea reference
  const changeFlag = () => {
    setFlag("");
  };

  //Redo functionality
  const redo = () => {
    let top = redoStack.pop();
    if (top) {
      if (top.type === "freehand") {
        //Flag for DrawArea reference
        setFlag("redo");
      }
      setResult([...result, top]);
    }
  };

  const getPaths = (el: any) => {
    setResult([...result, el]);
  };

  const getBounds = (obj: any) => {
    setBounds(obj);
  };

  const generateKey = () => {
    return `${new Date().getTime()}`;
  };

  const onFieldChange = (id: string, field_id: string | undefined) => {
    console.log("onFieldChange", id);
    let indx = result.findIndex((x) => x.id === id);
    let item = { ...result[indx] };
    item.field_id = field_id;
    result[indx] = item;
    setResult(result);
  };

  const onTextChange = (id: string, txt: string | undefined) => {
    let indx = result.findIndex((x) => x.id === id);
    let item = { ...result[indx] } as TextPDFElement;
    item.text = txt;
    result[indx] = item;
    setResult(result);
  };

  const onSetRef = (id: string, ref: any) => {
    let indx = result.findIndex((x) => x.id === id);
    let item = { ...result[indx] };
    item.ref = ref;
    result[indx] = item;
    setResult(result);
  };

  const onChangeFontSize = (id: string, fontSize: number) => {
    let indx = result.findIndex((x) => x.id === id);
    let item = { ...result[indx] } as TextPDFElement;
    item.fontSize = fontSize;
    result[indx] = item;
    setResult(result);
  };

  const onImageURlChanges = (id: string, src: string | undefined, ref: any) => {
    let indx = result.findIndex((x) => x.id === id);
    let item = { ...result[indx] } as ImagePDFElement;
    item.url = src;
    item.ref = ref;
    result[indx] = item;
    setResult(result);
  };

  const onDelete = (id: string) => {
    setResult(result.filter((x) => x.id !== id));
  };

  const onMove = (id: string, x: number, y: number) => {
    let indx = result.findIndex((x) => x.id === id);
    let item = { ...result[indx] };
    item.x = x;
    item.y = y;
    result[indx] = item;
    setResult(result);
  };

  const onResize = (id: string, width: number, height: number) => {
    let indx = result.findIndex((x) => x.id === id);
    let item = { ...result[indx] };
    item.width = width;
    item.height = height;
    result[indx] = item;
    setResult(result);
  };

  const changeButtonType = (type: any) => {
    setButtonType(type);
  };

  const resetButtonType = () => {
    setButtonType("");
  };

  return (
    <div className="App">
      {!isLoading &&
        result.map((res) => {
          let isShowing = "hidden";
          if (res.page !== pageNumber) {
            return;
          }
          var field;
          var settingsOptions = undefined;
          switch (res.type) {
            case PDFElementType.text:
              field = (
                <TextArea
                  key={res.id}
                  value={(res as TextPDFElement).text}
                  fontSize={(res as TextPDFElement).fontSize}
                  style={{
                    //visibility: isShowing,
                    color: "black",
                    fontWeight: "normal",
                    zIndex: 20,
                    //position: "absolute",
                    width: res.width,
                    height: res.height,
                  }}
                />
                //<h1 key={index} style = {{textAlign: "justify",color: "red" ,fontWeight:'normal',width: 200, height: 80,fontSize: 33+'px', fontSize: 16, zIndex:10, position: "absolute", left: res.x+'px', top: res.y +'px'}}>{res.text}</h1>
              );
              settingsOptions = (
                <TextAreaSettings
                  value={(res as TextPDFElement).text}
                  fontSize={(res as TextPDFElement).fontSize}
                  onTextChange={(txt) => {
                    onTextChange(res.id, txt);
                  }}
                  onChangeFontSize={(fontSize) => {
                    onChangeFontSize(res.id, fontSize);
                  }}
                />
              );
              break;
            case PDFElementType.image:
              field = (
                <Image
                  key={res.id}
                  src={(res as ImagePDFElement).url}
                  style={{
                    //visibility: isShowing,
                    zIndex: 20,
                    //position: "absolute",
                    width: res.width,
                    height: res.height,
                  }}
                />
                //<h1 key={index} style = {{textAlign: "justify",color: "red" ,fontWeight:'normal',width: 200, height: 80,fontSize: 33+'px', fontSize: 16, zIndex:10, position: "absolute", left: res.x+'px', top: res.y +'px'}}>{res.text}</h1>
              );
              break;
          }
          return (
            <Object
              key={res.id}
              field_type={res.type}
              x={res.x}
              y={res.y}
              width={res.width}
              height={res.height}
              field_id={res.field_id}
              template={template}
              settingsOptions={settingsOptions}
              onDelete={() => {
                onDelete(res.id);
              }}
              onMove={(x, y) => {
                onMove(res.id, x, y);
              }}
              onResize={(w, h) => {
                onResize(res.id, w, h);
              }}
              onFieldChange={(field_id) => {
                onFieldChange(res.id, field_id);
              }}
              onSetRef={(ref) => onSetRef(res.id, ref)}
            >
              {field}
            </Object>
          );
        })}

      {isEditingPDF ? (
        <div className="navbar">
          {/* <Button onClick={undo}>Undo</Button>
<Button onClick={redo}>Redo</Button> */}
          <Button onClick={addText}>Add text</Button>
          <Button onClick={addImage}>Add image</Button>
          <Button onClick={() => changeButtonType("download")}>Download</Button>
        </div>
      ) : (
        <div className="navbar">
          <Button onClick={() => changeButtonType("download")}>Download</Button>
        </div>
      )}

      {/* 
      <button onClick = {undo} style = {{marginTop: "1%"}}>Undo</button>
      <button onClick = {redo} style = {{marginTop: "1%"}}>Redo</button>
      <br></br>
      <button onClick={addText} style = {{marginTop: "1%"}}>Add Text</button>*/}
      <SinglePage
        resetButtonType={resetButtonType}
        buttonType={buttonType}
        pdf={pdfURL}
        pageChange={pageChange}
        getPaths={getPaths}
        flag={flag}
        getBounds={getBounds}
        changeFlag={changeFlag}
        setIsLoading={setIsLoading}
      />
      <ModifyPage
        resetButtonType={resetButtonType}
        buttonType={buttonType}
        pdf={pdfURL}
        result={result}
        bounds={bounds}
        fileName={fileName}
      />
    </div>
  );
}
