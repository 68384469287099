import { useContext, useRef, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// @mui
import { alpha } from "@mui/material/styles";
import {
  Box,
  Divider,
  Typography,
  Stack,
  MenuItem,
  Avatar,
  IconButton,
} from "@mui/material";
import MenuPopover from "./menu/MenuPopover";
import { setToken } from "../services/api";
import { LOGIN_URL } from "../structs/urls";
import { stringAvatar } from "../utils/avatar";
import { UserData } from "../structs/gen/user";
import { UserContext } from "../App";
import { LoadingButton } from "@mui/lab";
import { RoleTypeEnum } from "../structs/gen/enums/user";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: "Settings",
    icon: "eva:settings-2-fill",
    linkTo: "/user_settings",
  },
  {
    label: "Company Settings",
    icon: "eva:settings-2-fill",
    linkTo: "/company_settings",
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const { userData, setUserData } = useContext(UserContext);

  const [open, setOpen] = useState(null);

  const handleOpen = (event: any) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  if (!userData) {
    return <LoadingButton />;
  }

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        // sx={{
        //   p: 0,
        //   ...(open && {
        //     "&:before": {
        //       zIndex: 1,
        //       content: "''",
        //       width: "100%",
        //       height: "100%",
        //       borderRadius: "50%",
        //       position: "absolute",
        //       bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
        //     },
        //   }),
        // }}
      >
        {userData.avatar ? (
          <Avatar src={userData.avatar} alt="photoURL" />
        ) : (
          <Avatar {...stringAvatar(userData.user_name)} />
        )}
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          "& .MuiMenuItem-root": {
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {userData.user_name}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {userData.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.filter((option) => {
            if (option.label === "Company Settings") {
              return (
                userData &&
                (userData.role === RoleTypeEnum.admin ||
                  userData.role === RoleTypeEnum.root)
              );
            }
            return true;
          }).map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem
          onClick={() => {
            setToken(undefined);
            setUserData(undefined);
            navigate(LOGIN_URL);
            handleClose();
          }}
          sx={{ m: 1 }}
          style={{
            color: "red",
          }}
        >
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}

// function Logout() {
//   return (
//     <ListItemButton
//       component={RouterLink}
//       to={""}
//       style={{
//         height: 48,
//         position: "relative",
//         textTransform: "capitalize",
//       }}
//     >
//       <Icon
//         style={{
//           width: 22,
//           height: 22,
//           color: "red",
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//         }}
//       >

//       </Icon>
//       <Typography style={{ color: "red" }}>Logout</Typography>
//       {/* {info && info} */}
//     </ListItemButton>
//   );
// }
