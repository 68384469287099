import { useTranslation } from "react-i18next";
import {
  ChoiceFieldType,
  DateTimeFieldType,
  DividerFieldType,
  FileFieldType,
  InputFieldType,
  LabelFieldType,
  MediaFieldType,
  OptionChoice,
  SelectFieldType,
  SignatureFieldType,
  TableFieldType,
  UserLinkFieldType,
} from "../../structs/gen/template";

import {
  ChoiceTypeEnum,
  FieldTypeEnum,
  InputValueTypeEnum,
  SourceTypeEnum,
} from "../../structs/gen/enums/template";
import {
  CheckBox,
  DateRange,
  DriveFileRenameOutline,
  FileOpen,
  ImageAspectRatio,
  InputOutlined,
  LineStyle,
  Person,
  SelectAll,
  TableChart,
  TextFields,
} from "@mui/icons-material";
import { generateOptionKey } from "../../components/field/choice/ChoiceConfig";

export const FieldTypes = (): any[] => {
  console.log("FieldTypes", "t");
  const { t } = useTranslation();
  return [
    {
      name: "label",
      icon: <TextFields />,
      type: FieldTypeEnum.label,
      config: {
        type: FieldTypeEnum.label,
        is_visible: true,
        label: t("template_edit:fields.label"),
        label_intl: {},
        font_size: 16,
      } as LabelFieldType,
    },
    {
      name: "input",
      icon: <InputOutlined />,
      type: FieldTypeEnum.text,
      config: {
        type: FieldTypeEnum.text,
        is_visible: true,
        label: t("template_edit:fields.input"),
        label_intl: {},
        is_required: false,
        is_disabled: false,
        value_type: InputValueTypeEnum.any,
        min_lines: 1,
        max_lines: 1,
        placeholder: "",
        placeholder_intl: {},
      } as InputFieldType,
    },
    {
      name: "choice",
      icon: <CheckBox />,
      type: FieldTypeEnum.choice,
      config: {
        key: "",
        type: FieldTypeEnum.choice,
        is_visible: true,
        label: t("template_edit:fields.choice"),
        label_intl: {},
        is_required: false,
        is_disabled: false,
        is_multiple: false,
        value_type: ChoiceTypeEnum.checkbox,
        default_values: [],
        options: [
          {
            key: generateOptionKey(),
            label: "Option",
            label_intl: {},
          },
        ],
        convertValues: new ChoiceFieldType().convertValues,
      } as ChoiceFieldType,
    },
    {
      name: "datetime",
      icon: <DateRange />,
      type: FieldTypeEnum.datetime,
      config: {
        label: t("template_edit:fields.datetime"),
        label_intl: {},
        type: FieldTypeEnum.datetime,
        is_visible: true,
        is_required: false,
        is_disabled: false,
        default_value: "",
        value_type: "",
      } as DateTimeFieldType,
    },
    {
      name: "select",
      icon: <SelectAll />,
      type: FieldTypeEnum.select,
      config: {
        key: "",
        type: FieldTypeEnum.select,
        label: t("template_edit:fields.select"),
        label_intl: {},
        is_visible: true,
        is_required: false,
        is_disabled: false,
        is_multiple: false,
        source_type: SourceTypeEnum.template,
        source_value: "",
        source_fields: [],
        default_values: [],
      } as SelectFieldType,
    },
    {
      name: "divider",
      icon: <LineStyle />,
      type: FieldTypeEnum.divider,
      config: {
        type: FieldTypeEnum.divider,
        is_visible: true,
      } as DividerFieldType,
    },
    {
      name: "file",
      icon: <FileOpen />,
      type: FieldTypeEnum.file,
      config: {
        label: t("template_edit:fields.file"),
        label_intl: {},
        key: "",
        type: FieldTypeEnum.file,
        is_visible: true,
        is_required: false,
        is_disabled: false,
        is_preview: false,
        max_items: 1,
        max_size: 1,
        mimetypes: [],
      } as FileFieldType,
    },
    {
      name: "media",
      icon: <ImageAspectRatio />,
      type: FieldTypeEnum.media,
      config: {
        type: FieldTypeEnum.media,
        is_visible: true,
        src: "",
      } as MediaFieldType,
    },
    {
      name: "signature",
      icon: <DriveFileRenameOutline />,
      type: FieldTypeEnum.signature,
      config: {
        type: FieldTypeEnum.signature,
        label: t("template_edit:fields.signature"),
        label_intl: {},
        is_required: false,
        is_disabled: false,
      } as SignatureFieldType,
    },
    {
      name: "table",
      icon: <TableChart />,
      type: FieldTypeEnum.table,
      config: {
        type: FieldTypeEnum.table,
        is_visible: true,
        headers: [
          {
            label: "Key",
            label_intl: {},
          },
          {
            label: "Value 1",
            label_intl: {},
          },
        ],
        convertValues: new TableFieldType().convertValues,
      } as TableFieldType,
    },
    {
      name: "user",
      icon: <Person />,
      type: FieldTypeEnum.user_link,
      config: {
        type: FieldTypeEnum.user_link,
        label: t("template_edit:fields.user_link"),
        label_intl: {},
        is_required: false,
        is_disabled: false,
        is_multiple: false,
        is_visible: true,
        roles: [],
        key: "",
      } as UserLinkFieldType,
    },
  ];
};
