import * as React from "react";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { api } from "../services/api";
import { AccessListResponse, Access } from "../structs/gen/access";

async function list(): Promise<AccessListResponse> {
  let resp = await api.post(`/api/access/list`, {
    offset: 0,
    next: 10,
    filters: [],
  });
  console.log("resp.data", resp.data);
  return resp.data;
}

export default function AccessComponent({
  values,
  onChange,
  label,
}: {
  values: string[];
  onChange: (values: string[]) => void;
  label: string;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [accessList, setAccessList] = useState<Access[]>([]);
  const [valueAccessList, setValueAccessList] = useState<Access[]>([]);

  if (!isLoading) {
    setIsLoading(true);

    list().then((resp) => {
      setAccessList(resp.rows);
      var currentValueAccessList: Access[] = [];
      for (var v in values) {
        for (var r in resp.rows) {
          if (resp.rows[r].key === values[v]) {
            currentValueAccessList.push(resp.rows[r]);
          }
        }
      }
      setValueAccessList(currentValueAccessList);
    });
  }

  return (
    <Autocomplete
      options={accessList} //.sort((a, b) => -b.name.localeCompare(a.name))
      groupBy={(option) => option.group}
      getOptionLabel={(option) => option.name}
      multiple
      fullWidth
      value={valueAccessList}
      onChange={(event: any, newValues: Access[] | null) => {
        if (newValues) {
          onChange([...newValues.map((v) => v.key)]);
          setValueAccessList(newValues);
        } else {
          setValueAccessList([]);
        }
      }}
      disableCloseOnSelect
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  );
}
