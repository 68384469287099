import * as React from "react";
import Paper from "@mui/material/Paper";
import { api } from "../../services/api";
import { User } from "../../structs/gen/user";
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  useTheme,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { EMAIL_LIST_URL, PDF_LIST_URL } from "../../structs/urls";
// import Access from "../components/Access";
import { PDF } from "../../structs/gen/pdf";
import { Email } from "../../structs/gen/email";
import AceEditor from "react-ace";

async function apiGet(id: string): Promise<Email> {
  let resp = await api.get(`/api/email/${id}`);
  return resp.data;
}

async function apiSave(id: string | undefined, data: Email): Promise<any> {
  console.log("id", id);
  var url = `/api/email/`;
  if (id) {
    url += id;
  }
  let resp = await api.post(url, data);
  return resp.data;
}

export default function Users() {
  const navigate = useNavigate();
  const params = useParams();
  const [email, setEmail] = React.useState<Email | undefined>(undefined);
  const [loading, setLoading] = React.useState(true);
  const theme = useTheme();

  if (loading) {
    setLoading(false);
    if (params.id && params.id !== "") {
      apiGet(params.id).then((resp) => {
        setEmail(resp);
      });
      return <CircularProgress />;
    } else {
      setEmail({
        id: "",
        name: "",
        body: "",
        subject: "",
      });
    }
    return <CircularProgress />;
  }

  if (!email) {
    return <CircularProgress />;
  }
  return (
    <Box display="flex" justifyContent="center">
      <Paper
        elevation={9}
        sx={{ mt: 1 }}
        style={{ padding: "20px", maxWidth: 700, minWidth: 500 }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              value={email.name}
              name="Name"
              autoFocus
              onChange={(e) => {
                setEmail({
                  ...email,
                  name: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="subject"
              label="Subject"
              value={email.subject}
              name="Subject"
              onChange={(e) => {
                setEmail({
                  ...email,
                  subject: e.target.value,
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <AceEditor
              style={{
                width: "100%",
              }}
              value={email.body}
              mode="ace/mode/html"
              theme={
                theme.palette.mode === "dark" ? "gruvbox_dark_hard" : undefined
              }
              showPrintMargin={false}
              showGutter={true}
              highlightActiveLine={true}
              onChange={(v) => {
                console.log("v", v);
                setEmail({
                  ...email,
                  body: v,
                });
              }}
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
                showLineNumbers: true,
                tabSize: 4,
              }}
            />
          </Grid>
          <Button
            variant="contained"
            fullWidth
            color="success"
            style={{ marginTop: 20 }}
            onClick={async () => {
              await apiSave(email.id, email);
              navigate(EMAIL_LIST_URL);
            }}
          >
            Save
          </Button>
        </Grid>
      </Paper>
    </Box>
  );
}
