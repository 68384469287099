import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { api } from "../../services/api";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { FieldType, Template } from "../../structs/gen/template";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Input,
  Stack,
  TableContainer,
} from "@mui/material";
import { DOCUMENT_URL } from "../../structs/urls";
import { useNavigate, useParams } from "react-router-dom";
import Page from "../../components/Page";
import { Add, Clear, Search } from "@mui/icons-material";
import { FieldTypeEnum } from "../../structs/gen/enums/template";
import { InputFieldTypeObject } from "../../components/field/input/input_field_model";
import DeleteWithConfirm from "../../components/DeleteWithConfirm";
import { FilterListDto } from "../../structs/gen/basic";
import { Document, DocumentListResponse } from "../../structs/gen/document";
import { getFieldValue } from "../../utils/field";
import { getDocumentDataAsText } from "../../structs/document";
import { fToNow } from "../../utils/formatTime";

const nextTake = 1000;

async function apiDelete(id: string) {
  let resp = await api.delete(`/api/document/${id}`);
  return resp.data;
}

async function apiList(): Promise<DocumentListResponse> {
  let resp = await api.post(`/api/document/list_recent`, {
    offset: 0,
    next: nextTake,
    filters: [],
  });
  return resp.data;
}

// async function lazyLoad(
//   templateID: string,
//   offset: number,
//   next: number,
//   data: any,
//   setData: any,
// ) {
//   var resp = await apiList(templateID, offset, next);
//   var result: any[] = [];
//   for (var r in resp.rows) {
//     var doc = resp.rows[r];
//     var row: any = {
//       id: doc.id,
//     };
//     for (var d in doc.data) {
//       row[d] = doc.data[d];
//     }
//     result.push(row);
//   }
//   var newData = [...data, ...result];
//   setData(newData);
//   if (resp.rows.length === nextTake) {
//     await lazyLoad(templateID, offset + nextTake, nextTake, newData, setData);
//   }
// }

export default function DocumentList() {
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [searchText, setSearchText] = React.useState<string>("");
  const [documents, setDocuments] = React.useState<Document[]>([]);

  if (loading) {
    setLoading(false);
    apiList().then((r) => {
      setDocuments(r.rows);
    });
  }

  return (
    <>
      <Page title="Search documents">
        <Container maxWidth={false}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              Search documents
            </Typography>
            <Input
              autoFocus
              fullWidth
              style={{
                marginLeft: "40px",
                marginRight: "40px",
              }}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              startAdornment={<Search />}
              endAdornment={
                searchText !== "" && (
                  <IconButton
                    onClick={() => {
                      setSearchText("");
                    }}
                  >
                    <Clear />
                  </IconButton>
                )
              }
            />
          </Stack>

          <TableContainer sx={{ minWidth: 800 }}>
            <Grid container justifyContent="center" spacing={2}>
              {documents
                .sort(
                  (a, b) =>
                    Date.parse(b.created_at.toString()) -
                    Date.parse(a.created_at.toString()),
                )
                .map((doc) => (
                  <Grid item key={-1} sx={{ padding: "5px" }}>
                    <Card sx={{ width: 270, height: 300 }}>
                      <CardHeader
                        title={doc.key !== "" ? doc.key : doc.id}
                        subheader={
                          doc.template_name !== ""
                            ? doc.template_name
                            : undefined
                        }
                        style={{ height: 85 }}
                      />
                      <CardActions
                        onClick={() => {
                          navigate(
                            DOCUMENT_URL + doc.template_id + "/edit/" + doc.id,
                          );
                        }}
                      >
                        <CardContent style={{ width: "100%" }}>
                          <div
                            style={{
                              height: 145,
                              width: "100%",
                              overflowY: "scroll",
                            }}
                          >
                            {doc.data &&
                              getDocumentDataAsText(
                                doc.data,
                                doc.template_data,
                              ).map((field) => (
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  {field}{" "}
                                </Typography>
                              ))}
                          </div>

                          {doc.created_at && (
                            <Typography variant="body2" color="text.secondary">
                              Last changes:
                              <b>
                                {" " +
                                  fToNow(new Date(doc.created_at.toString()))}
                              </b>
                            </Typography>
                          )}
                        </CardContent>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
            </Grid>
          </TableContainer>
        </Container>
      </Page>
    </>
  );
}
