import * as React from "react";
import Paper from "@mui/material/Paper";
import { api } from "../../services/api";
import { User } from "../../structs/gen/user";
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  useTheme,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { PDF_LIST_URL } from "../../structs/urls";
// import Access from "../components/Access";
import { PDF } from "../../structs/gen/pdf";
import PDFEditor, {
  ImagePDFElement,
  PDFElementType,
  TextPDFElement,
} from "../../components/pdf/PDF";
import { File } from "../../structs/gen/file";
import { Template, TemplateListResponse } from "../../structs/gen/template";
import { Code, PictureAsPdf } from "@mui/icons-material";
import { Document, DocumentListResponse } from "../../structs/gen/document";
import { getFieldValue } from "../../utils/field";

async function apiGet(id: string): Promise<PDF> {
  let resp = await api.get(`/api/pdf/${id}`);
  return resp.data;
}

async function apiSave(id: string | undefined, data: PDF): Promise<any> {
  console.log("id", id);
  var url = `/api/pdf/`;
  if (id) {
    url += id;
  }
  let resp = await api.post(url, data);
  return resp.data;
}

async function apiListTemplates(): Promise<TemplateListResponse> {
  let resp = await api.post(`/api/template/list`, {
    offset: 0,
    next: 100,
    filters: [],
  });
  return resp.data;
}

async function apiListDocuments(
  templateID: string,
): Promise<DocumentListResponse> {
  let resp = await api.post(`/api/document/list/${templateID}`, {
    offset: 0,
    next: 100000,
    filters: [],
  });
  return resp.data;
}

export default function PDFEdit() {
  const navigate = useNavigate();
  const params = useParams();
  const [pdf, setPDF] = React.useState<PDF | undefined>(undefined);
  const [loading, setLoading] = React.useState(true);
  const [templates, setTemplates] = React.useState<Template[]>([]);
  const [documents, setDocuments] = React.useState<Document[]>([]);
  const [document, setDocument] = React.useState<Document | undefined>(
    undefined,
  );
  const theme = useTheme();
  const [isEditingPDF, setIsEditingPDF] = React.useState(true);

  if (loading) {
    setLoading(false);
    apiListTemplates().then((resp) => {
      setTemplates(resp.rows);
    });
    if (params.id && params.id !== "") {
      apiGet(params.id).then((resp) => {
        setPDF(resp);
      });
      return <CircularProgress />;
    } else {
      setPDF({
        data: [],
        file_id: "",
        file_url: "",
        id: "",
        name: "",
        template_id: "",
      });
    }
    return <CircularProgress />;
  }

  if (!pdf) {
    return <CircularProgress />;
  }
  return (
    <Box display="flex" justifyContent="center">
      <Paper
        elevation={9}
        sx={{ mt: 1 }}
        style={{ padding: "20px", width: 660 }}
      >
        <Grid container spacing={1} columns={16}>
          <Grid item xs={16}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                "& > *": {
                  m: 1,
                },
              }}
            >
              <ButtonGroup>
                <Button
                  startIcon={<Code />}
                  onClick={() => {
                    setIsEditingPDF(true);
                  }}
                  variant={isEditingPDF ? "contained" : "outlined"}
                >
                  Edit
                </Button>
                <Button
                  endIcon={<PictureAsPdf />}
                  onClick={() => {
                    setIsEditingPDF(false);
                  }}
                  variant={isEditingPDF ? "outlined" : "contained"}
                >
                  Generate
                </Button>
              </ButtonGroup>
            </Box>
            <FormControl fullWidth sx={{ m: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Name"
                value={pdf.name}
                name="Name"
                autoFocus
                disabled={!isEditingPDF}
                onChange={(e) => {
                  setPDF({
                    ...pdf,
                    name: e.target.value,
                  });
                }}
              />
            </FormControl>
            {isEditingPDF ? (
              <>
                <FormControl fullWidth sx={{ m: 1 }}>
                  <InputLabel id="select-template">Select template</InputLabel>
                  <Select
                    labelId="select-template"
                    id="demo-simple-source"
                    defaultValue={pdf.template_id}
                    label="Select template"
                    onChange={(e) => {
                      var template_id = e.target.value;
                      setPDF({
                        ...pdf,
                        template_id: template_id,
                      });
                    }}
                  >
                    {templates.map((t) => (
                      <MenuItem key={t.id} value={t.id}>
                        {t.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth sx={{ m: 1 }}>
                  <Button variant="contained" component="label">
                    Upload File
                    <input
                      type="file"
                      hidden
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        console.log("input onChange");
                        if (e.target && e.target.files) {
                          for (var id = 0; id < e.target.files.length; id++) {
                            let formData = new FormData();
                            formData.append("file", e.target.files[id]);
                            api
                              .post("/api/file/", formData)
                              .then((res: any) => {
                                console.log(res);
                                var f = res.data as File;
                                setPDF({
                                  ...pdf,
                                  file_id: f.id,
                                  file_url: f.url,
                                });
                              });
                          }
                          console.log("event.target.files", e.target.files);
                        }
                      }}
                    />
                  </Button>
                </FormControl>
              </>
            ) : (
              <>
                <FormControl fullWidth sx={{ m: 1 }}>
                  <InputLabel id="select-document">Select document</InputLabel>
                  <Select
                    labelId="select-document"
                    id="demo-simple-document"
                    defaultValue={document && document.id}
                    onOpen={() => {
                      apiListDocuments(pdf.template_id).then((resp) => {
                        setDocuments(resp.rows);
                      });
                    }}
                    label="Select document"
                    onChange={(e) => {
                      var document_id = e.target.value;
                      var d = documents.find((d) => d.id === document_id);
                      if (d) {
                        console.log("pdf.data", pdf.data);
                        //Clean previous data
                        for (var r in pdf.data) {
                          switch (pdf.data[r].type) {
                            case PDFElementType.text:
                              var t = pdf.data[r] as TextPDFElement;
                              t.text = "";
                              pdf.data[r] = t;
                              break;
                            case PDFElementType.image:
                              var img = pdf.data[r] as ImagePDFElement;
                              img.url = undefined;
                              pdf.data[r] = img;
                              break;
                          }
                        }

                        var template = templates.find(
                          (t) => t.id === pdf.template_id,
                        );

                        //Fill data from document
                        for (var r in pdf.data) {
                          for (var df in d.data) {
                            if (pdf.data[r].field_id === df) {
                              switch (pdf.data[r].type) {
                                case PDFElementType.text:
                                  var t = pdf.data[r] as TextPDFElement;

                                  if (template) {
                                    t.text =
                                      getFieldValue(
                                        df,
                                        d.data[df],
                                        template.data,
                                      ) + "";
                                  } else {
                                    t.text = d.data[df] + "";
                                  }

                                  pdf.data[r] = t;
                                  break;
                                case PDFElementType.image:
                                  var img = pdf.data[r] as ImagePDFElement;
                                  if (Array.isArray(d.data[df])) {
                                    img.url = d.data[df][0].url; //Select first file from files
                                    console.log("img.url", img.url);
                                  } else {
                                    img.url = d.data[df]; //Select signature
                                  }
                                  pdf.data[r] = img;
                                  break;
                              }
                            }
                          }
                        }
                        console.log("pdf.data after", pdf.data);
                        setPDF({ ...pdf, data: pdf.data });
                      }
                      setDocument(d);
                    }}
                  >
                    {documents.map((t) => (
                      <MenuItem key={t.id} value={t.id}>
                        {t.key !== "" ? t.key : t.id}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth sx={{ m: 1 }}>
                  <Button variant="contained" component="label" disabled>
                    Generate PDF
                    <input
                      type="file"
                      hidden
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        console.log("input onChange");
                        if (e.target && e.target.files) {
                          for (var id = 0; id < e.target.files.length; id++) {
                            let formData = new FormData();
                            formData.append("file", e.target.files[id]);
                            api
                              .post("/apic/file/", formData)
                              .then((res: any) => {
                                console.log(res);
                                var f = res.data as File;
                                setPDF({
                                  ...pdf,
                                  file_id: f.id,
                                  file_url: f.url,
                                });
                              });
                          }
                          console.log("event.target.files", e.target.files);
                        }
                      }}
                    />
                  </Button>
                </FormControl>
              </>
            )}
            {pdf.file_url && (
              <PDFEditor
                isEditingPDF={isEditingPDF}
                pdfURL={pdf.file_url}
                result={pdf.data}
                setResult={(result) => {
                  setPDF({
                    ...pdf,
                    data: result,
                  });
                }}
                template={templates.find((t) => t.id === pdf.template_id)}
                fileName={pdf.name + (document ? "_" + document.id : "")}
              />
            )}
            {/* <Document
              file={{
                url: "https://pdf-lib.js.org/assets/with_update_sections.pdf", // "https://slicedinvoices.com/pdf/wordpress-pdf-invoice-plugin-sample.pdf", //
              }}
              onLoadSuccess={onDocumentLoadSuccess}
              onSourceError={(err) => console.log(err)}
              onSourceSuccess={() => console.log("SUCCESS")}
              onLoadError={() => console.log("ERR")}
            >
              <DrawArea
                getPaths={getPaths}
                page={pageNumber}
                flag={flag}
                getBounds={getBounds}
                changeFlag={changeFlag}
                cursor={isText ? "text" : "default"}
                buttonType={buttonType}
                resetButtonType={resetButtonType}
              >
                <Page pageNumber={pageNumber} />
              </DrawArea>
              <Page
                pageNumber={pageNumber}
                renderAnnotationLayer={false}
                onLoadSuccess={removeTextLayerOffset}
              />
            </Document>
            <p>
              Page {pageNumber} of {numPages}
            </p> */}
          </Grid>
        </Grid>
        {isEditingPDF && (
          <Grid container spacing={2} style={{ marginTop: 20 }}>
            <Button
              variant="contained"
              fullWidth
              color="success"
              onClick={async () => {
                if (pdf.data.map) {
                  pdf.data = pdf.data.map((d: any) => {
                    if (d.ref) {
                      delete d.ref;
                    }
                    return d;
                  });
                }
                console.log(pdf.data);
                await apiSave(pdf!.id, pdf);
                navigate(PDF_LIST_URL);
              }}
            >
              Save
            </Button>
          </Grid>
        )}
      </Paper>
    </Box>
  );
}

// function startDownload(pdf: PDF) {
//   let formData = new FormData();
//   formData.append("index.html", new Blob([pdf.html]), "index.html");
//   api
//     .post(
//       "https://devapp.elena.sh/pdf?orientation=Portrait&page-size=A4", //your url
//       formData,
//       {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//         responseType: "blob", // important
//       },
//     )
//     .then((response: any) => {
//       // this.setState({
//       //   is_downloading: false,
//       // });
//       const url = window.URL.createObjectURL(new Blob([response.data]));
//       const link = document.createElement("a");
//       link.href = url;
//       let fileName = "";
//       if (pdf && pdf.name && pdf.name !== "") {
//         fileName += pdf.name;
//       }
//       // if (contract && contract.key) {
//       //   if (fileName !== "") fileName += " - " + contract.key;
//       //   else fileName += "empty - " + contract.key;
//       // }
//       if (fileName === "") {
//         fileName = "empty";
//       }
//       link.setAttribute("download", fileName + ".pdf"); //or any other extension
//       document.body.appendChild(link);
//       link.click();
//     });
// }
