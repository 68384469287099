import React from "react";
import {
  Box,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import LabelField from "./label/LabelField";
import { IFieldType } from "./generic";
import { LabelFieldTypeObject } from "./label/label_field_model";
import InputField from "./input/InputField";
import { InputFieldTypeObject } from "./input/input_field_model";
import DateTimeField from "./datetime/DateTimeField";
import { DateTimeFieldTypeObject } from "./datetime/datetime_field_model";
import ChoiceField from "./choice/ChoiceField";
import { ChoiceFieldTypeObject } from "./choice/choice_field_model";
import SelectField from "./select/SelectField";
import { SelectFieldTypeObject } from "./select/select_field_model";
import MediaField from "./media/MediaField";
import { MediaFieldTypeObject } from "./media/media_field_model";
import FileField from "./file/FileField";
import { FileFieldTypeObject } from "./file/file_field_model";
import SignatureField from "./signature/SignatureField";
import { FieldTypeEnum } from "../../structs/gen/enums/template";
import UserLinkField from "./user_link/UserLinkField";
import { UserLinkFieldTypeObject } from "./user_link/user_link_field_model";
import { SignatureFieldTypeObject } from "./signature/signature_field_model";
import TableField from "./table/TableField";
import { TableFieldTypeObject } from "./table/table_field_model";

function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number,
) {
  return { name, calories, fat, carbs, protein };
}

const BuildField = ({
  key,
  config,
  value,
  onChange,
  errors,
}: {
  key: string;
  config: IFieldType;
  value: any;
  onChange: (key: string, v: any) => void;
  errors: string[];
}) => {
  switch (config.type) {
    case FieldTypeEnum.label:
      return (
        <Box style={{ paddingTop: "10px", paddingBottom: "10px" }}>
          <LabelField id={config.key} config={config as LabelFieldTypeObject} />
        </Box>
      );
    case FieldTypeEnum.divider:
      return (
        <Box style={{ paddingTop: "10px", paddingBottom: "10px" }}>
          <Divider id={config.key} />
        </Box>
      );
    case FieldTypeEnum.text:
      return (
        <Box style={{ paddingTop: "10px", paddingBottom: "10px" }}>
          <InputField
            id={config.key}
            config={config as InputFieldTypeObject}
            value={value}
            onChange={(v) => {
              onChange(config.key, v);
            }}
            errors={errors}
          />
        </Box>
      );
    case FieldTypeEnum.datetime:
      return (
        <Box style={{ paddingTop: "10px", paddingBottom: "10px" }}>
          <DateTimeField
            id={config.key}
            value={value}
            config={config as DateTimeFieldTypeObject}
            onChange={(v) => {
              onChange(config.key, v);
            }}
            errors={errors}
          />
        </Box>
      );
    case FieldTypeEnum.choice:
      return (
        <Box style={{ paddingTop: "10px", paddingBottom: "10px" }}>
          <ChoiceField
            id={config.key}
            config={config as ChoiceFieldTypeObject}
            values={value ? value : []}
            onChange={(v) => {
              onChange(config.key, v);
            }}
            errors={errors}
          />
        </Box>
      );
    case FieldTypeEnum.select:
      return (
        <Box style={{ paddingTop: "10px", paddingBottom: "10px" }}>
          <SelectField
            id={config.key}
            config={config as SelectFieldTypeObject}
            values={value ? value : []}
            onChange={(v) => {
              onChange(config.key, v);
            }}
            errors={errors}
          />
        </Box>
      );
    case FieldTypeEnum.media:
      return (
        <Box style={{ paddingTop: "10px", paddingBottom: "10px" }}>
          <MediaField
            id={config.key}
            config={config as MediaFieldTypeObject}
            // config={config as SelectFieldTypeObject}
            // values={value ? value : []}
            // onChange={(v) => {
            //   onChange(config.key, v);
            // }}
          />
        </Box>
      );
    case FieldTypeEnum.file:
      return (
        <Box style={{ paddingTop: "10px", paddingBottom: "10px" }}>
          <FileField
            id={config.key}
            config={config as FileFieldTypeObject}
            onChange={(v) => {
              onChange(config.key, v);
            }}
            values={value ? value : []}
            errors={errors}
            // config={config as SelectFieldTypeObject}
            // values={value ? value : []}
            // onChange={(v) => {
            //   onChange(config.key, v);
            // }}
          />
        </Box>
      );
    case FieldTypeEnum.signature:
      return (
        <Box style={{ paddingTop: "10px", paddingBottom: "10px" }}>
          <SignatureField
            id={config.key}
            config={config as SignatureFieldTypeObject}
            onChange={(v) => {
              onChange(config.key, v);
            }}
            value={value}
            errors={errors}
            // config={config as SelectFieldTypeObject}
            // values={value ? value : []}
            // onChange={(v) => {
            //   onChange(config.key, v);
            // }}
          />
        </Box>
      );
    case FieldTypeEnum.user_link:
      return (
        <Box style={{ paddingTop: "10px", paddingBottom: "10px" }}>
          <UserLinkField
            id={config.key}
            config={config as UserLinkFieldTypeObject}
            onChange={(v) => {
              onChange(config.key, v);
            }}
            values={value ? value : []}
            errors={errors}
          />
        </Box>
      );
    case FieldTypeEnum.table:
      return (
        <Box style={{ paddingTop: "10px", paddingBottom: "10px" }}>
          <TableField
            id={config.key}
            config={config as TableFieldTypeObject}
            value={value}
          />
        </Box>
      );
  }

  return <>Not implemented</>;
};

export default BuildField;
