import React, { useState, useEffect, useRef } from "react";
import Immutable from "immutable";

function DrawArea(props: any) {
  const [lines, setLines] = useState<any[]>([]);
  //const [isDrawing, setIsDrawing] = useState(false);
  const [redoEl, setRedoEl] = useState<any[]>([]);
  const [isCrosshair, setIsCrosshair] = useState(false);
  const drawAreaEl = useRef<any>(null);

  useEffect(() => {
    if (document !== null) {
      var drawArea = document.getElementById("drawArea");
      if (drawArea !== null) {
        drawArea!.addEventListener("mouseup", handleMouseUp);
        props.getBounds({
          x: drawAreaEl.current!.getBoundingClientRect().left,
          y: drawAreaEl.current!.getBoundingClientRect().bottom,
        });
        return () => {
          drawArea!.removeEventListener("mouseup", handleMouseUp);
        };
      }
    }
  }, []);

  useEffect(() => {
    if (props.flag === "undo") {
      setRedoEl((arr: any[]) => [...arr, lines.pop()]);
      setLines(lines);
    }
    if (props.flag === "redo") {
      setLines((lines: any[]) => [...lines, redoEl.pop()]);
    }
    props.changeFlag();
  }, [props.flag]);

  useEffect(() => {
    if (props.buttonType === "draw") {
      addMouseDown();
      props.resetButtonType();
    }
  }, [props.buttonType]);

  useEffect(() => {
    if (lines.length) {
      props.getPaths(lines[lines.length - 1]);
    }
  }, []);

  const handleMouseUp = () => {
    setIsCrosshair(false);
  };

  const handleMouseDown = (e: any) => {
    if (e.button !== 0) {
      return;
    }
    const point = relativeCoordinatesForEvent(e);
    let obj = {
      arr: [point],
      page: props.page,
      type: "freehand",
    };
    setLines((prevlines) => [...prevlines, obj]);
  };

  const relativeCoordinatesForEvent = (e: any) => {
    const boundingRect = drawAreaEl.current.getBoundingClientRect();
    return Immutable.Map({
      x: e.clientX - boundingRect.left,
      y: e.clientY - boundingRect.top,
    });
  };

  const addMouseDown = () => {
    setIsCrosshair(true);
    document!
      .getElementById("drawArea")!
      .addEventListener("mousedown", handleMouseDown, { once: true });
  };

  return (
    <>
      {/*<button onClick = {addMouseDown} style = {{marginBottom: "1%", marginTop: "1%"}}>Draw</button>*/}
      <div
        id="drawArea"
        ref={drawAreaEl}
        style={isCrosshair ? { cursor: "crosshair" } : { cursor: props.cursor }}
      >
        {props.children}
      </div>
    </>
  );
}

export default DrawArea;
