/* Do not change, this code is generated from Golang structs */


export class String {


    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);

    }
}
export class User {
    id: string;
    email: string;
    name: string;
    role: string;
    password?: String;
    is_google_auth: boolean;
    is_blocked: boolean;
    actions: string[];
    access: string[];
    avatar?: string;
    is_two_factor_auth_enabled: boolean;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.id = source["id"];
        this.email = source["email"];
        this.name = source["name"];
        this.role = source["role"];
        this.password = this.convertValues(source["password"], String);
        this.is_google_auth = source["is_google_auth"];
        this.is_blocked = source["is_blocked"];
        this.actions = source["actions"];
        this.access = source["access"];
        this.avatar = source["avatar"];
        this.is_two_factor_auth_enabled = source["is_two_factor_auth_enabled"];
    }

	convertValues(a: any, classs: any, asMap: boolean = false): any {
	    if (!a) {
	        return a;
	    }
	    if (a.slice) {
	        return (a as any[]).map(elem => this.convertValues(elem, classs));
	    } else if ("object" === typeof a) {
	        if (asMap) {
	            for (const key of Object.keys(a)) {
	                a[key] = new classs(a[key]);
	            }
	            return a;
	        }
	        return new classs(a);
	    }
	    return a;
	}
}
export class UserListResponse {
    count: number;
    rows: User[];

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.count = source["count"];
        this.rows = this.convertValues(source["rows"], User);
    }

	convertValues(a: any, classs: any, asMap: boolean = false): any {
	    if (!a) {
	        return a;
	    }
	    if (a.slice) {
	        return (a as any[]).map(elem => this.convertValues(elem, classs));
	    } else if ("object" === typeof a) {
	        if (asMap) {
	            for (const key of Object.keys(a)) {
	                a[key] = new classs(a[key]);
	            }
	            return a;
	        }
	        return new classs(a);
	    }
	    return a;
	}
}
export class MenuItem {
    menu_id?: string;
    icon: string;
    url: string;
    key: string;
    label: string;
    sub_menu: MenuItem[];

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.menu_id = source["menu_id"];
        this.icon = source["icon"];
        this.url = source["url"];
        this.key = source["key"];
        this.label = source["label"];
        this.sub_menu = this.convertValues(source["sub_menu"], MenuItem);
    }

	convertValues(a: any, classs: any, asMap: boolean = false): any {
	    if (!a) {
	        return a;
	    }
	    if (a.slice) {
	        return (a as any[]).map(elem => this.convertValues(elem, classs));
	    } else if ("object" === typeof a) {
	        if (asMap) {
	            for (const key of Object.keys(a)) {
	                a[key] = new classs(a[key]);
	            }
	            return a;
	        }
	        return new classs(a);
	    }
	    return a;
	}
}
export class UserData {
    user_name: string;
    email: string;
    avatar?: string;
    menu: MenuItem[];
    company_name: string;
    company_logo?: string;
    background_image?: string;
    companyId: string;
    language: string;
    role: string;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.user_name = source["user_name"];
        this.email = source["email"];
        this.avatar = source["avatar"];
        this.menu = this.convertValues(source["menu"], MenuItem);
        this.company_name = source["company_name"];
        this.company_logo = source["company_logo"];
        this.background_image = source["background_image"];
        this.companyId = source["companyId"];
        this.language = source["language"];
        this.role = source["role"];
    }

	convertValues(a: any, classs: any, asMap: boolean = false): any {
	    if (!a) {
	        return a;
	    }
	    if (a.slice) {
	        return (a as any[]).map(elem => this.convertValues(elem, classs));
	    } else if ("object" === typeof a) {
	        if (asMap) {
	            for (const key of Object.keys(a)) {
	                a[key] = new classs(a[key]);
	            }
	            return a;
	        }
	        return new classs(a);
	    }
	    return a;
	}
}
