import { format, formatDistanceToNow } from "date-fns";
import moment from "moment";

// ----------------------------------------------------------------------

export function fDate(date: Date) {
  return format(new Date(date), "dd MMMM yyyy");
}

export function fDateTime(date: Date) {
  return format(new Date(date), "dd MMM yyyy HH:mm");
}

export function fDateTimeSuffix(date: Date) {
  return format(new Date(date), "dd/MM/yyyy hh:mm p");
}

export function fToNow(date: Date) {
  try {
    return formatDistanceToNow(date, {
      addSuffix: true,
    });
  } catch (e) {
    return "Error";
  }
}

export function fDateTimeToGolang(date: Date) {
  return moment(new Date()).format("YYYY-MM-DDTHH:mm:ssZ");
}

export function convertUTCDateToLocalDate(date: Date) {
  var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

  var offset = date.getTimezoneOffset() / 60;
  var hours = date.getHours();

  newDate.setHours(hours - offset);

  return newDate;
}
