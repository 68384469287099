/* Do not change, this code is generated from Golang structs */


export enum RoleTypeEnum {
    root = "root",
    admin = "admin",
    employee = "employee",
    client = "client",
    programmer = "programmer",
    partner = "partner",
}