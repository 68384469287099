import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {
  Link as RouterLink,
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { api, setToken, isLogin } from "../services/api";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import useResponsive from "../hooks/useResponsive";
import MenuItem from "../components/menu/MenuItem";
import Scrollbar from "../components/Scrollbar";
import { styled } from "@mui/material/styles";
import { Menu } from "../structs/menu";
import {
  alpha,
  Avatar,
  Container,
  createTheme,
  CssBaseline,
  FormControlLabel,
  FormGroup,
  Icon,
  Input,
  InputAdornment,
  Link,
  Paper,
  Stack,
  Switch,
  ThemeProvider,
  useTheme,
} from "@mui/material";
import LanguagePopover from "../components/LanguagePopover";
import MenuIcon from "@mui/icons-material/Menu";
import AccountPopover from "../components/AccountPopover";
import { LOGIN_URL } from "../structs/urls";
import { useTranslation } from "react-i18next";
import { availableLanguages } from "../i18n";
import "../i18n";
import ChatPopover from "../components/ChatPopover";
import { UserContext } from "../App";
import { UserData } from "../structs/gen/user";

const DRAWER_WIDTH = 280;
const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

async function getUserData() {
  let resp = await api.get(`/api/user/data`);
  return resp.data;
}

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const SearchbarStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: APPBAR_MOBILE,
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  padding: theme.spacing(0, 3),
  backgroundColor: `${alpha(theme.palette.background.default, 0.72)}`,
  [theme.breakpoints.up("md")]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const ToolbarRootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

const AccountStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
}));

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const MenuStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

export default function MenuRenderer() {
  const { t, i18n } = useTranslation();
  // The light theme is used by default
  const [isDarkTheme, setIsDarkTheme] = React.useState(false);
  const { userData, setUserData } = React.useContext(UserContext);

  // This function is triggered when the Switch component is toggled
  const changeTheme = () => {
    setIsDarkTheme(!isDarkTheme);
  };

  React.useEffect(() => {
    // Add listener to update styles
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (e) => setIsDarkTheme(e.matches));

    // Setup dark/light mode for the first time
    setIsDarkTheme(window.matchMedia("(prefers-color-scheme: dark)").matches);

    // Remove listener
    return () => {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .removeEventListener("change", () => {});
    };
  }, []);

  const { pathname } = useLocation();

  const navigate = useNavigate();
  if (!isLogin()) {
    setTimeout(() => navigate(LOGIN_URL), 1000);
  }

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [navItems, setNavItems] = React.useState<Menu[] | undefined>(undefined);
  const [open, setOpen] = React.useState(false);

  const isDesktop = useResponsive("up", "lg");

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  if (!userData) {
    getUserData().then((resp: UserData) => {
      setNavItems(resp.menu!);
      if (resp.language && availableLanguages.indexOf(resp.language) > -1) {
        i18n.changeLanguage(resp.language);
      }
      setUserData(resp);
    });
  }

  const renderNavbar = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box
        sx={{ mb: 2, mt: 2, mx: 2.5 }}
        style={{ marginTop: isDesktop ? 0 : 60 }}
      >
        <Link underline="none" component={RouterLink} to="/">
          <AccountStyle>
            <Avatar src={userData && userData.company_logo} alt="photoURL" />
            <Box sx={{ ml: 2 }}>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {userData && userData!.company_name}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>

      <Box sx={{ px: 2, pb: 2, mt: 2 }}>
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={isDarkTheme} onChange={changeTheme} />}
            label={t("menu:darkMode")}
          />
        </FormGroup>
      </Box>

      <Box sx={{ flexGrow: 1 }} />

      {navItems && (
        <MenuItem
          navConfig={navItems}
          onClick={() => {
            setOpen(false);
          }}
        />
      )}

      {/* <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
          <Box
            component="img"
            src="/static/illustrations/illustration_avatar.png"
            sx={{ width: 100, position: 'absolute', top: -50 }}
          />

          <Box sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant="h6">
              Get more?
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              From only $69
            </Typography>
          </Box>

          <Button href="https://material-ui.com/store/items/minimal-dashboard/" target="_blank" variant="contained">
            Upgrade to Pro
          </Button>
        </Stack>
      </Box> */}
    </Scrollbar>
  );

  return (
    <ThemeProvider
      theme={
        isDarkTheme
          ? createTheme({
              palette: {
                mode: "dark",
              },
            })
          : createTheme({
              palette: {
                mode: "light",
              },
            })
      }
    >
      <CssBaseline />
      <RootStyle>
        <AppBar
          position="fixed"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <ToolbarRootStyle>
            <ToolbarStyle>
              <IconButton
                onClick={() => setOpen(true)}
                sx={{ mr: 1, color: "text.primary", display: { lg: "none" } }}
              >
                <MenuIcon />
              </IconButton>

              {/* <SearchbarStyle>
                <Input
                  autoFocus
                  disableUnderline
                  placeholder="Search…"
                  endAdornment={
                    <InputAdornment position="start">
                      <SearchOutlined
                        style={{
                          color: "text.disabled",
                          width: 20,
                          height: 20,
                        }}
                      />
                    </InputAdornment>
                  }
                  sx={{ mr: 1, fontWeight: "fontWeightBold" }}
                />
              </SearchbarStyle> */}
              <Box sx={{ flexGrow: 1 }} />

              <Stack
                direction="row"
                alignItems="center"
                spacing={{ xs: 0.5, sm: 1.5 }}
              >
                <LanguagePopover lang={userData?.language} />
                {userData && <ChatPopover />}
                <AccountPopover />
              </Stack>
            </ToolbarStyle>
          </ToolbarRootStyle>
        </AppBar>
        <MenuStyle>
          {!isDesktop && (
            <Drawer
              open={open}
              onClose={() => setOpen(false)}
              PaperProps={{
                sx: { width: DRAWER_WIDTH },
              }}
            >
              {renderNavbar}
            </Drawer>
          )}

          {isDesktop && (
            <Drawer
              open
              variant="persistent"
              PaperProps={{
                sx: {
                  width: DRAWER_WIDTH,
                  bgcolor: "background.default",
                  borderRightStyle: "dashed",
                },
              }}
            >
              {renderNavbar}
            </Drawer>
          )}
        </MenuStyle>
        <MainStyle>
          <Outlet />
        </MainStyle>
      </RootStyle>
    </ThemeProvider>
  );
}
