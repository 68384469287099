import moment from "moment";
import { DateTimeTypeEnum, FieldTypeEnum } from "../structs/gen/enums/template";
import {
  ChoiceFieldType,
  DateTimeFieldType,
  FieldType,
  SelectFieldType,
} from "../structs/gen/template";

export function getFieldValue(key: string, value: any, templateData: any) {
  for (var t in templateData) {
    const field: FieldType = templateData[t];
    if (field.key === key) {
      switch (field.type) {
        case FieldTypeEnum.text:
          return value;
        case FieldTypeEnum.datetime:
          var dateTimeField = field as DateTimeFieldType;
          switch (dateTimeField.type) {
            case DateTimeTypeEnum.date:
              return moment(new Date(value)).format("YYYY-MM-DD");
            case DateTimeTypeEnum.time:
              return moment(new Date(value)).format("hh:mm:ss");
            default:
              return moment(new Date(value)).format("YYYY-MM-DD hh:mm:ss");
          }
        case FieldTypeEnum.choice:
          var choices = [];
          var choiceField = field as ChoiceFieldType;
          for (var k in value) {
            for (var opt in choiceField.options) {
              if (choiceField.options[opt].key === value[k]) {
                choices.push(choiceField.options[opt].label);
                break;
              }
            }
          }
          return choices.join(",");
        case FieldTypeEnum.select:
          var selected: string[] = [];
          var selectField = field as SelectFieldType;

          for (var sf in selectField.source_fields) {
            for (var ks in value) {
              if(value[ks].data){
                if(value[ks].data[selectField.source_fields[sf]])
                  selected.push(value[ks].data[selectField.source_fields[sf]]);
              }
            }
          }
          console.log("getFieldValue", key, value, selectField, selected.join(","));
          return selected.join(",");
      }
    }
  }
  return "";
}
