import * as React from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Modal,
  Paper,
  Stack,
  TableContainer,
  Typography,
  Card,
  CardHeader,
  Divider,
} from "@mui/material";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CreditCardDetail from "../components/stripe/CreditCardDetail";
import { Create, PaymentRounded } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { api } from "../services/api";
import Page from "../components/Page";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import DeleteWithConfirm from "../components/DeleteWithConfirm";
import Cards from "../components/subscription/Cards";
import { Invoice } from "../structs/gen/subscription";
import Invoices from "../components/subscription/Invoices";
import Usage from "../components/subscription/Usage";

async function invoiceList(): Promise<Invoice[]> {
  let resp = await api.get(`/api/subscription/invoices`);
  return resp.data;
}

export default function Subscription() {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(true);
  const [rows, setRows] = React.useState<Invoice[] | undefined>(undefined);

  if (loading) {
    setLoading(false);
    invoiceList().then((resp) => {
      setRows(resp);
    });
  }
  return (
    <Page title={"Subscription"}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} lg={9}>
          <Usage />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Cards />
        </Grid>
      </Grid>
      <div style={{ marginTop: 30 }}>
        <Page title={t("user_list:title")}>
          <Container>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={5}
            >
              <Typography variant="h4" gutterBottom>
                Invoices
              </Typography>
            </Stack>

            <Invoices />
          </Container>
        </Page>
      </div>
    </Page>
  );
}
