import { Save } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { api } from "../../../services/api";
import { MediaFieldTypeObject } from "./media_field_model";
import {
  FieldType,
  Template,
  TemplateListResponse,
} from "../../../structs/gen/template";
import { FieldTypeEnum } from "../../../structs/gen/enums/template";

export default function SelectConfig({
  config,
  onSave,
}: {
  config: MediaFieldTypeObject;
  onSave: (newConfig: MediaFieldTypeObject) => void;
}) {
  const [newConfig, setNewConfig] = useState(config);

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      <div>
        <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <Input
            fullWidth
            type="file"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target && e.target.files) {
                const formData = new FormData();
                formData.append("file", e.target.files[0]);
                api.post("/api/file/", formData).then((res: any) => {
                  console.log(res);
                  setNewConfig({
                    ...newConfig,
                    src: res.data.url,
                  });
                });
                console.log("event.target.files", e.target.files);
              }
            }}
          />
        </FormControl>

        <Button
          onClick={() => {
            console.log("newConfig", newConfig);
            onSave(newConfig);
          }}
          fullWidth
          startIcon={<Save />}
          color={"success"}
          variant="contained"
        >
          Save
        </Button>
      </div>
    </Box>
  );
}
