// @mui
import PropTypes from "prop-types";
import {
  Box,
  Card,
  Paper,
  Typography,
  CardHeader,
  CardContent,
  Grid,
  CardActions,
  Button,
  Divider,
} from "@mui/material";
// utils
import { fShortenNumber } from "../../utils/formatNumber";
import React, { useState } from "react";
import { api } from "../../services/api";
import { DocumentListResponse, Document } from "../../structs/gen/document";
import { useNavigate } from "react-router-dom";
import { DOCUMENT_URL } from "../../structs/urls";
import { fToNow } from "../../utils/formatTime";
import { getFieldValue } from "../../utils/field";
import { More, MoreVert } from "@mui/icons-material";
import { getDocumentDataAsText } from "../../structs/document";

async function apiList(): Promise<DocumentListResponse> {
  let resp = await api.post(`/api/document/list_recent`, {
    offset: 0,
    next: 10,
    filters: [],
  });
  return resp.data;
}

Documents.propTypes = {
  title: PropTypes.string,
  //subheader: PropTypes.string,
};

export default function Documents({
  title,
  subheader,
}: {
  title: string;
  subheader: string | undefined;
}) {
  const navigate = useNavigate();
  const [isLoadingDocuments, setIsLoadingDocuments] = useState(true);
  const [isShowingMore, setIsShowingMore] = useState(false);

  const [documents, setDocuments] = useState<Document[]>([]);

  if (isLoadingDocuments) {
    setIsLoadingDocuments(false);
    apiList().then((r) => {
      setDocuments(r.rows);
    });
  }
  return (
    <Card variant="outlined">
      <CardHeader title={title} subheader={subheader} />

      <CardContent>
        <Grid container justifyContent="center" spacing={2}>
          {documents
            .filter((v, index) => isShowingMore || index < 4)
            .sort(
              (a, b) =>
                Date.parse(b.created_at.toString()) -
                Date.parse(a.created_at.toString()),
            )
            .map((doc) => (
              <Grid item key={-1} sx={{ padding: "5px" }}>
                <Card sx={{ width: 270, height: 300 }}>
                  <CardHeader
                    title={doc.key !== "" ? doc.key : doc.id}
                    subheader={
                      doc.template_name !== "" ? doc.template_name : undefined
                    }
                    style={{ height: 85 }}
                  />
                  <CardActions
                    onClick={() => {
                      navigate(
                        DOCUMENT_URL + doc.template_id + "/edit/" + doc.id,
                      );
                    }}
                  >
                    <CardContent style={{ width: "100%" }}>
                      <div
                        style={{
                          height: 145,
                          width: "100%",
                          overflowY: "scroll",
                        }}
                      >
                        {doc.data &&
                          getDocumentDataAsText(
                            doc.data,
                            doc.template_data,
                          ).map((field) => (
                            <Typography variant="body2" color="text.secondary">
                              {field}{" "}
                            </Typography>
                          ))}
                      </div>

                      {doc.created_at && (
                        <Typography variant="body2" color="text.secondary">
                          Last changes:
                          <b>
                            {" " + fToNow(new Date(doc.created_at.toString()))}
                          </b>
                        </Typography>
                      )}
                    </CardContent>
                  </CardActions>
                </Card>
              </Grid>
            ))}
        </Grid>
      </CardContent>
      <Divider />
      <Box sx={{ p: 2, textAlign: "right" }}>
        <Button
          size="small"
          color="primary"
          endIcon={<MoreVert />}
          onClick={() => {
            setIsShowingMore(!isShowingMore);
          }}
        >
          {isShowingMore ? "Less" : "More"}
        </Button>
      </Box>
    </Card>
  );
}
