import React from "react";
import {
  Box,
  FormControl,
  FormLabel,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DateTimeFieldTypeObject } from "./datetime_field_model";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { DateTimeTypeEnum } from "../../../structs/gen/enums/template";

const DateTimeField = ({
  id,
  value,
  config,
  onChange,
  errors,
}: {
  id: string;
  value: string;
  config: DateTimeFieldTypeObject;
  onChange: (value: string) => void;
  errors: string[];
}) => {
  const { i18n } = useTranslation();
  // const [dateValue, setDateValue] = React.useState<Date | null>(
  //   new Date("2014-08-18T21:11:54"),
  // );

  const handleChange = (newValue: string | null) => {
    //setDateValue(newValue);
    if (newValue == null) {
      onChange("");
    } else {
      var formatedTime = moment(newValue).format("YYYY-MM-DDTHH:mm:ss.sssZ");
      onChange(formatedTime);
    }
  };

  const datetime = (
    <DateTimePicker
      inputFormat="dd.MM.yyyy HH:mm"
      disabled={config.is_disabled}
      label={config.placeholder || ""}
      value={value || config.default_value}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField {...params} error={false} fullWidth />
      )}
    />
  );

  const time = (
    <TimePicker
      disabled={config.is_disabled}
      label={config.placeholder}
      inputFormat="HH:mm"
      value={value || config.default_value}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          required={config.is_required}
          error={false}
          fullWidth
        />
      )}
    />
  );

  const date = (
    <DatePicker
      disabled={config.is_disabled}
      label={config.placeholder}
      value={value || config.default_value}
      onChange={handleChange}
      inputFormat="dd.MM.yyyy"
      renderInput={(params) => (
        <TextField
          {...params}
          required={config.is_required}
          error={false}
          fullWidth
        />
      )}
    />
  );

  return (
    <FormControl
      fullWidth
      disabled={config.is_disabled}
      required={config.is_required}
      error={errors && errors.length > 0}
    >
      <FormLabel component="legend">
        {(config.label_intl &&
          config.label_intl[i18n.language] &&
          config.label_intl[i18n.language]) ||
          config.label}
      </FormLabel>
      <Box flexDirection="row" display="flex">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          {config.value_type === DateTimeTypeEnum.time
            ? time
            : config.value_type === DateTimeTypeEnum.date
            ? date
            : datetime}
        </LocalizationProvider>
        {/* {errors && errors[name] && (
        <FormHelperText error>{errors[name].message}</FormHelperText>
      )} */}
      </Box>
    </FormControl>
    // <Box flexDirection="row" display="flex" className={classes.root}>
  );
};

export default React.memo(DateTimeField);
