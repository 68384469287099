import { TextField } from "@mui/material";
import React, { createRef, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { InputFieldTypeObject } from "./input_field_model";

const InputField = ({
  id,
  config,
  value,
  onChange,
  errors,
}: {
  id: string;
  config: InputFieldTypeObject;
  value: string | undefined;
  onChange: (value: string | undefined) => void;
  errors: string[];
}) => {
  const ref = useRef<HTMLInputElement>();
  const { i18n } = useTranslation();
  const [currentValue, setCurrentValue] = useState<string | undefined>(value);
  const [isSaved, setIsSaved] = useState(true);
  console.log("InputField", id, value, currentValue);

  // useEffect(() => {
  //   console.log("useEffect currentValue", id);
  //   const delayDebounceFn = setTimeout(() => {
  //     if (currentValue !== value) {
  //       console.log(currentValue);
  //       if (currentValue === "") {
  //         onChange(undefined);
  //         return;
  //       }
  //       onChange(currentValue);
  //       setIsSaved(true);
  //     }
  //   }, 500);

  //   return () => clearTimeout(delayDebounceFn);
  // }, [currentValue]);

  useEffect(() => {
    console.log("useEffect value", id, value);
    setCurrentValue(value);
  }, [id, value]);

  if (isSaved && value !== currentValue) {
    console.log("reset current value", id);
    if (ref && ref.current) ref.current.value = value || "";
    setCurrentValue(value);
  }

  return (
    <TextField
      value={currentValue}
      onChange={(e) => {
        setIsSaved(false);
        setCurrentValue(e.target.value);
      }}
      fullWidth
      error={errors && errors.length > 0}
      //helperText={errors && errors.length > 0 && errors[0]}
      label={
        (config.label_intl &&
          config.label_intl[i18n.language] &&
          config.label_intl[i18n.language]) ||
        config.label
      }
      id={id}
      name={id}
      type={"text"}
      required={config.is_required}
      disabled={config.is_disabled}
      placeholder={config.placeholder}
      minRows={config.min_lines}
      maxRows={config.max_lines}
      multiline={config.min_lines > 1 || config.max_lines > 1}
      InputLabelProps={{ shrink: true }}
      inputRef={ref}
      onBlur={() => {
        console.log("onBlur", id, currentValue, value);
        if (currentValue !== value) {
          console.log(currentValue);
          if (currentValue === "") {
            onChange(undefined);
            return;
          }
          onChange(currentValue);
          setIsSaved(true);
        }
      }}
      // inputProps={{
      //   ...inputProps,
      //   "aria-label":
      //     inputProps && inputProps["aria-label"]
      //       ? inputProps["aria-label"]
      //       : name,
      //   style: {
      //     /** TODO: fix this spacing */
      //     marginLeft: !Icon ? 20 : 0,
      //   },
      // }}
      // InputProps={{
      //   ...props.InputProps,
      //   startAdornment: Icon && (
      //     <InputAdornment position="start">
      //       <Icon color="action" />
      //     </InputAdornment>
      //   ),
      //   endAdornment: errors[name] && (
      //     <InputAdornment position="end">
      //       <Error color="secondary" style={{ fontSize: 12 }} />
      //     </InputAdornment>
      //   ),
      // }}
    />
  );
};

export default React.memo(InputField);
