/* Do not change, this code is generated from Golang structs */


export enum StatisticType {
    line_chart = "line_chart",
    value = "value",
}
export enum StatisticBlockSize {
    full = "full",
    quarter = "quarter",
    half = "half",
}