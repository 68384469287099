import {
  FormControl,
  FormControlLabel,
  Radio,
  Checkbox,
  Chip,
  Stack,
  InputLabel,
  FormLabel,
  FormGroup,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ChoiceTypeEnum } from "../../../structs/gen/enums/template";
import { OptionChoice } from "../../../structs/gen/template";
import { ChoiceFieldTypeObject } from "./choice_field_model";

const Field = ({
  id,
  config,
  values,
  onChange,
  errors,
}: {
  id: string;
  config: ChoiceFieldTypeObject;
  values: string[];
  onChange: (value: string[]) => void;
  errors: string[];
}) => {
  var item;
  const { i18n } = useTranslation();
  switch (config.value_type) {
    case ChoiceTypeEnum.button:
      item = (
        <Stack
          direction="row"
          spacing={1}
          style={{
            marginTop: 10,
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          {config.options.map((option) => {
            const isChecked = values && values.indexOf(option.key) > -1;
            return (
              <Chip
                key={option.key}
                label={
                  (option.label_intl &&
                    option.label_intl[i18n.language] &&
                    option.label_intl[i18n.language]) ||
                  option.label
                }
                variant={isChecked ? "filled" : "outlined"}
                color={isChecked ? "primary" : "default"}
                style={{
                  margin: 2,
                }}
                onClick={(e) => {
                  if (config.is_multiple) {
                    if (!isChecked)
                      onChange([
                        ...values.filter((v) => v !== option.key),
                        option.key,
                      ]);
                    else onChange(values.filter((v) => v !== option.key));
                  } else {
                    if (!isChecked) onChange([option.key]);
                    else onChange([]);
                  }
                }}
              />
            );
          })}
        </Stack>
      );
      break;
    case ChoiceTypeEnum.checkbox:
      item = config.options.map((option) => {
        const isChecked = values && values.indexOf(option.key) > -1;
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={isChecked}
                key={option.key}
                onChange={(e) => {
                  if (e.target.checked)
                    onChange([
                      ...values.filter((v) => v !== option.key),
                      option.key,
                    ]);
                  else onChange(values.filter((v) => v !== option.key));
                }}
              />
            }
            label={
              (option.label_intl &&
                option.label_intl[i18n.language] &&
                option.label_intl[i18n.language]) ||
              option.label
            }
          />
        );
      });
      break;
    case ChoiceTypeEnum.radio:
      item = config.options.map((option) => {
        const isChecked = values && values.indexOf(option.key) > -1;
        return (
          <FormControlLabel
            control={
              <Radio
                key={option.key}
                checked={isChecked}
                onChange={(e) => {
                  if (e.target.checked) onChange([option.key]);
                  else onChange([]);
                }}
              />
            }
            label={
              (option.label_intl &&
                option.label_intl[i18n.language] &&
                option.label_intl[i18n.language]) ||
              option.label
            }
          />
        );
      });
      break;
    case ChoiceTypeEnum.dropdown:
      return (
        <FormControl
          fullWidth
          disabled={config.is_disabled}
          required={config.is_required}
          error={errors && errors.length > 0}
        >
          <InputLabel id="demo-multiple-name-label">
            {(config.label_intl &&
              config.label_intl[i18n.language] &&
              config.label_intl[i18n.language]) ||
              config.label}
          </InputLabel>
          <Select
            value={values}
            label={
              (config.label_intl &&
                config.label_intl[i18n.language] &&
                config.label_intl[i18n.language]) ||
              config.label
            }
            multiple={config.is_multiple}
            onChange={(e) => {
              const {
                target: { value },
              } = e;
              onChange(typeof value === "string" ? value.split(",") : value);
            }}
          >
            {config.options.map((option) => {
              return (
                <MenuItem value={option.key} key={option.key}>
                  {(option.label_intl &&
                    option.label_intl[i18n.language] &&
                    option.label_intl[i18n.language]) ||
                    option.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      );
  }

  // key={option.key}
  // checked={isChecked}
  // onChange={(e) => {
  //   if (e.target.checked) onChange([option.key]);
  //   else onChange([]);
  // }}
  return (
    <FormControl
      fullWidth
      disabled={config.is_disabled}
      required={config.is_required}
      error={errors && errors.length > 0}
    >
      <FormLabel component="legend">
        {(config.label_intl &&
          config.label_intl[i18n.language] &&
          config.label_intl[i18n.language]) ||
          config.label}
      </FormLabel>
      {item}
    </FormControl>
  );
};

export default React.memo(Field);
