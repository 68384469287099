import * as React from "react";
import Grid from "@mui/material/Grid";
import {
  StripeTextFieldNumber,
  StripeTextFieldExpiry,
  StripeTextFieldCVC,
} from "./commonTextFields";
import { Box, Button } from "@mui/material";
import { Payment } from "@mui/icons-material";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { api } from "../../services/api";

async function addCard(pm_id: string): Promise<any> {
  let resp = await api.post(`/api/subscription/card/${pm_id}`, {});
  return resp.data;
}

export default function CreditCardDetail({ onChange }: { onChange: any }) {
  const stripe = useStripe();
  const elements = useElements();
  const [state, setState] = React.useState({
    cardNumberComplete: false,
    expiredComplete: false,
    cvcComplete: false,
    cardNumberError: undefined,
    expiredError: undefined,
    cvcError: undefined,
  });

  const onElementChange =
    (field: any, errorField: any) =>
    ({
      complete,
      error = { message: null },
    }: {
      complete: any;
      error: any;
    }) => {
      setState({ ...state, [field]: complete, [errorField]: error.message });
    };

  const { cardNumberError, expiredError, cvcError } = state;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StripeTextFieldNumber
            error={Boolean(cardNumberError)}
            labelErrorMessage={cardNumberError}
            id="card-element"
            onChange={onElementChange("cardNumberComplete", "cardNumberError")}
          />
        </Grid>
        <Grid item xs={6}>
          <StripeTextFieldExpiry
            error={Boolean(expiredError)}
            labelErrorMessage={expiredError}
            onChange={onElementChange("expiredComplete", "expiredError")}
          />
        </Grid>
        <Grid item xs={6}>
          <StripeTextFieldCVC
            error={Boolean(cvcError)}
            labelErrorMessage={cvcError}
            onChange={onElementChange("cvcComplete", "cvcError")}
          />
        </Grid>
        {/* <pre>{JSON.stringify(state, null, 2)}</pre> */}
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Button
            startIcon={<Payment />}
            color={"success"}
            disabled={
              !(
                state.cardNumberComplete &&
                state.expiredComplete &&
                state.cvcComplete
              )
            }
            onClick={async () => {
              var cardElement = elements?.getElement(CardNumberElement);
              var pm = await stripe?.createPaymentMethod({
                type: "card",
                card: cardElement!,
              });
              console.log("pm", pm, pm?.paymentMethod?.id);
              await addCard(pm?.paymentMethod?.id!);
              onChange();
            }}
          >
            Save card
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

class PaymentForm extends React.Component<any> {
  handleSubmit = async () => {
    const { elements, stripe } = this.props;
    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      console.log("[error]", error);
    } else {
      console.log("[PaymentMethod]", paymentMethod);
      // ... SEND to your API server to process payment intent
    }
  };

  render() {
    return (
      <>
        <h1>stripe form</h1>
        <CardElement />
        <button onClick={this.handleSubmit}>Buy</button>
      </>
    );
  }
}
