/* eslint-disable */
// @mui
import { Breakpoint, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// ----------------------------------------------------------------------

export default function useResponsive(
  query: string,
  key: Breakpoint,
  // start: number | Breakpoint | undefined,
  // end: number | Breakpoint | undefined,
) {
  const theme = useTheme();

  if (query === "up") {
    const mediaUp = useMediaQuery(theme.breakpoints.up(key));

    return mediaUp;
  }

  if (query === "down") {
    const mediaDown = useMediaQuery(theme.breakpoints.down(key));

    return mediaDown;
  }

  // if (query === "between") {
  //   const mediaBetween = useMediaQuery(theme.breakpoints.between(start!, end!));

  //   return mediaBetween;
  // }

  // if (query === "only") {
  //   const mediaOnly = useMediaQuery(theme.breakpoints.only(key));

  //   return mediaOnly;
  // }
  return null;
}
