import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { api, setToken } from "../services/api";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  CHECK_EMAIL_URL,
  CONFIRM_URL,
  DASHBOARD_URL,
  FORGOT_PASSWORD_URL,
  REGISTER_URL,
} from "./../structs/urls";
import {
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { Google, Visibility, VisibilityOff } from "@mui/icons-material";

const theme = createTheme();
async function login(email: string, password: string) {
  try {
    let resp = await api.post(`/api/login`, {
      email: email,
      password: password,
    });
    setToken(resp.data.access_token);
    return resp.data;
  } catch (e: any) {
    return {
      error: "invalid_grant",
      error_description: "The username or password is incorrect.",
    };
  }
}

function getGoogleUrl(from: string) {
  const rootUrl = `https://accounts.google.com/o/oauth2/v2/auth`;

  const options = {
    redirect_uri: "https://app.elena.sh/api/oauth/google",
    client_id:
      "134406530564-bs7vnmdbu82p73qcq5c9l5qr12uu29nn.apps.googleusercontent.com",
    access_type: "offline",
    response_type: "code",
    prompt: "consent",
    scope: [
      "https://www.googleapis.com/auth/userinfo.profile",
      "https://www.googleapis.com/auth/userinfo.email",
    ].join(" "),
    state: from,
  };

  const qs = new URLSearchParams(options);

  return `${rootUrl}?${qs.toString()}`;
}

export default function SignIn() {
  const location = useLocation();
  let from = ((location.state as any)?.from?.pathname as string) || "/";

  const [searchParams, setSearchParams] = useSearchParams();
  const company = searchParams.get("company");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [remember, setRemember] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const [error, setError] = React.useState<undefined | string>(undefined);
  const navigate = useNavigate();

  const loginAction = async () => {
    var respData = await login(email.trim(), password);
    if (respData.error && respData.error_description) {
      setError(respData.error_description);
    } else {
      if (!respData.is_two_factor_auth_enabled) navigate(DASHBOARD_URL);
      else navigate(CONFIRM_URL);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          minHeight: "100vh",
          backgroundSize: "cover",
          WebkitBackgroundSize: "cover",
          overflow: "scroll",
          backgroundRepeat: "no-repeat",
          background:
            company === "resonor"
              ? "url('/static/resonor-gold.png') center center no-repeat"
              : "",
        }}
      >
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "25px",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                helperText={error && error!}
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={email}
                onChange={(e) => {
                  setError(undefined);
                  setEmail(e.target.value);
                }}
                autoFocus
              />
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  error={!!error}
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  value={password}
                  autoComplete="current-password"
                  onChange={(e) => {
                    setError(undefined);
                    setPassword(e.target.value);
                  }}
                  onKeyPress={(ev) => {
                    console.log(`Pressed keyCode ${ev.key}`);
                    if (ev.key === "Enter") {
                      loginAction();
                      // Do code here
                      ev.preventDefault();
                    }
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              {/* <FormControlLabel
              control={
                <Checkbox
                  value="remember"
                  color="primary"
                  checked={remember}
                  onChange={(e) => setRemember(e.target.checked)}
                />
              }
              label="Remember me"
            /> */}
              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={loginAction}
              >
                Sign In
              </Button>
              <Divider>OR</Divider>
              <Button
                component={Link}
                fullWidth
                href={getGoogleUrl(from)}
                sx={{
                  backgroundColor: "color(srgb 0.8592 0.2903 0.2237)",
                  borderRadius: 1,
                  mt: 2,
                  mb: 2,
                  textTransform: "none",
                  py: "0.6rem",
                  columnGap: "1rem",
                  textDecoration: "none",
                  color: "white",
                  cursor: "pointer",
                  fontWeight: 500,
                  "&:hover": {
                    backgroundColor: "#fff",
                    boxShadow: "0 1px 13px 0 rgb(0 0 0 / 15%)",
                    color: "#393e45",
                  },
                }}
                startIcon={<Google />}
              >
                Login with Google
              </Button>
              {/* <Link display="flex" justifyContent="center" alignItems="center">
                Google
              </Link> */}
              <Grid container>
                <Grid item xs>
                  <Link href={FORGOT_PASSWORD_URL} variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href={REGISTER_URL} variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </div>
    </ThemeProvider>
  );
}
