import React from "react";
import { Navigate, useRoutes } from "react-router-dom";

import Main from "./pages/Main";

import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import Register from "./pages/Register";
import Dashboard from "./pages/Dashboard";
import UserList from "./pages/user/UserList";
import DocumentEdit from "./pages/document/DocumentEdit";
import DocumentList from "./pages/document/DocumentList";
import UserEdit from "./pages/user/UserEdit";
import TemplateEdit from "./pages/template/TemplateEdit";
import UserSettings from "./pages/UserSettings";
import TwoStepAuthConfirm from "./pages/TwoStepAuthConfirm";
import FileList from "./pages/file/FileList";
import Password from "./pages/Password";
import ForgotPassword from "./pages/ForgotPassword";
import Subscription from "./pages/Subscription";
import PDFEdit from "./pages/pdf/PDFEdit";
import PDFList from "./pages/pdf/PDFList";
import DocumentFill from "./pages/document/DocumentFill";
import TemplateExamples from "./pages/template/TemplateExamples";
import EmailList from "./pages/email/EmailList";
import EmailEdit from "./pages/email/EmailEdit";
import TemplateList from "./pages/template/TemplateList";
import CompanySettings from "./pages/CompanySettings";
import DocumentSearch from "./pages/document/DocumentSearch";

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <Main />,
      children: [
        { path: "/", element: <Dashboard /> },
        { path: "/user_settings", element: <UserSettings /> },
        { path: "/company_settings", element: <CompanySettings /> },
        {
          path: "user",
          children: [
            { path: "list", element: <UserList /> },
            { path: "edit/", element: <UserEdit /> },
            { path: "edit/:id", element: <UserEdit /> },
          ],
        },
        {
          path: "template",
          children: [
            { path: "list", element: <TemplateList /> },
            { path: "edit/", element: <TemplateEdit /> },
            { path: "edit/:id", element: <TemplateEdit /> },
          ],
        },
        {
          path: "file",
          children: [{ path: "list", element: <FileList /> }],
        },
        { path: "/subscription", element: <Subscription /> },
        {
          path: "pdf",
          children: [
            { path: "list", element: <PDFList /> },
            { path: "edit/", element: <PDFEdit /> },
            { path: "edit/:id", element: <PDFEdit /> },
          ],
        },
        {
          path: "email",
          children: [
            { path: "list", element: <EmailList /> },
            { path: "edit/", element: <EmailEdit /> },
            { path: "edit/:id", element: <EmailEdit /> },
          ],
        },
        {
          path: "document",
          children: [
            { path: ":template_id", element: <DocumentList /> },
            { path: ":template_id/edit/", element: <DocumentEdit /> },
            { path: ":template_id/edit/:id", element: <DocumentEdit /> },
          ],
        },
        {
          path: "documents",
          element: <DocumentSearch />,
        },
      ],
    },
    {
      path: "/document/fill/:template_id",
      element: <DocumentFill />,
    },
    {
      path: "/examples",
      element: <TemplateExamples />,
    },
    { path: "/confirm", element: <TwoStepAuthConfirm /> },
    { path: "/forgot", element: <ForgotPassword /> },
    { path: "/password/:token", element: <Password /> },
    { path: "/login", element: <Login /> },
    { path: "/register", element: <Register /> },
    { path: "/404", element: <NotFound /> },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
