import * as React from "react";
import Paper from "@mui/material/Paper";
import { api } from "../../services/api";
import {
  Avatar,
  AvatarGroup,
  Badge,
  BadgeProps,
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Template } from "../../structs/gen/template";
import BuildFields from "../../components/field/BuildFields";
import { DOCUMENT_URL } from "../../structs/urls";
import {
  DeltaDocumentHistory,
  Document,
  DocumentHistory,
  DocumentHistoryListResponse,
} from "../../structs/gen/document";
import { ChevronLeft, ChevronRight, Edit, Image } from "@mui/icons-material";
import { User, UserListResponse } from "../../structs/gen/user";
import { convertUTCDateToLocalDate, fToNow } from "../../utils/formatTime";
import { createDefaultDocument } from "../../structs/document";

const modalStyle = {
  // position: "absolute" as "absolute",
  // top: "50%",
  // left: "50%",
  // transform: "translate(-50%, -50%)",

  position: "absolute" as "absolute",
  marginLeft: "auto",
  marginRight: "auto",
  left: 0,
  right: 0,
  textAlign: "center",
  marginTop: "25px",
  marginBottom: "25px",

  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

async function apiList(callback: any) {
  let resp = await api.post(`/api/user/list`, {
    offset: 0,
    next: 10,
    filters: [],
  });
  callback(resp.data);
}

function Access({
  userAccess,
  onChange,
}: {
  userAccess: string[];
  onChange: (ua: string[]) => void;
}) {
  console.log("userAccess", userAccess);
  const [isOpen, setIsOpen] = React.useState(false);
  const [rows, setRows] = React.useState<User[]>([]);
  const [loading, setLoading] = React.useState(true);

  if (loading) {
    setLoading(false);
    apiList((resp: UserListResponse) => {
      setRows(resp.rows);
    });
  }
  return (
    <>
      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Access
          </Typography>
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-chip-label">Access</InputLabel>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              value={userAccess}
              onChange={(v) => {
                if (typeof v.target.value === "string") {
                  onChange([v.target.value]);
                } else {
                  onChange(v.target.value);
                }
              }}
              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((user_id) => {
                    var user = rows.find((u) => u.id === user_id);
                    if (!user) return <></>;
                    return (
                      <Chip
                        avatar={
                          user.avatar !== "" ? (
                            <Avatar alt={user.name} src={user.avatar} />
                          ) : undefined
                        }
                        key={user.id}
                        label={user.name}
                      />
                    );
                  })}
                </Box>
              )}
            >
              {rows.map((r) => (
                <MenuItem key={r.id} value={r.id}>
                  {r.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            onClick={async () => {
              setIsOpen(false);
            }}
            color="success"
          >
            Done
          </Button>
        </Box>
      </Modal>
      <Card sx={{ m: 1, p: 1 }}>
        <CardHeader
          title="Access"
          action={
            <IconButton aria-label="settings" onClick={() => setIsOpen(true)}>
              <Edit />
            </IconButton>
          }
        />
        {userAccess && userAccess.length > 0 && (
          <AvatarGroup total={userAccess.length}>
            {userAccess.map((user_id, i) => {
              var user = rows.find((u) => u.id === user_id);
              if (i < 3 && user) {
                return <Avatar alt={user.name} src={user.avatar} />;
              }
              return <></>;
            })}
          </AvatarGroup>
        )}
      </Card>
    </>
  );
}

export default Access;
