import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { TableFieldTypeObject } from "./table_field_model";

const TableField = ({
  id,
  config,
  value,
}: {
  id: string;
  config: TableFieldTypeObject;
  value: string[][];
}) => {
  const { i18n } = useTranslation();
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {config.headers.map((h, id) => {
              return (
                <TableCell align={id !== 0 ? "right" : undefined}>
                  {h.label}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {value &&
            value.map((row) => (
              <TableRow
                key={row[0]}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {row.map((v, id) => (
                  <TableCell
                    align={id !== 0 ? "right" : undefined}
                    component={id === 0 ? "th" : undefined}
                    scope={id === 0 ? "row" : undefined}
                  >
                    {v}
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default React.memo(TableField);
