import React, { useRef } from "react";
import { Template } from "../../structs/gen/template";

function Image({ src, style }: { src: string | undefined; style: any }) {
  const imageRef = useRef<any>(null);
  const divAreaRef = useRef<any>(null);
  console.log("src", src);

  return (
    <>
      <div style={{ ...style }} ref={divAreaRef}>
        <img
          ref={imageRef}
          // style={{
          //   height: imageHeight,
          //   zIndex: 10,
          //   background: "transparent",
          //   border: "none",
          //   color: "black",
          //   fontFamily: "helvetica",
          // }}
          //onChange={onChangeHandler}
          //onBlur={onBlurHandler}
          src={src ? src : "/no-image.png"}
          width={style.width}
          height={style.height}
        />
      </div>
    </>
  );
}

export default Image;
