import * as React from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Modal,
  Paper,
  Stack,
  TableContainer,
  Typography,
  Card,
  CardHeader,
  Divider,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { api } from "../../services/api";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import DeleteWithConfirm from "../../components/DeleteWithConfirm";
import { Invoice } from "../../structs/gen/subscription";
import moment from "moment";
import { humanFileSize, secondsToString } from "../../utils/formatNumber";

async function invoiceList(): Promise<Invoice[]> {
  let resp = await api.get(`/api/subscription/invoices`);
  return resp.data;
}

export default function Invoices() {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(true);
  const [rows, setRows] = React.useState<any[] | undefined>(undefined);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: t("user_list:id"),
      flex: 1,
    },
    {
      field: "created_at",
      headerName: "Date",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
    },
    {
      field: "amount_with_coins",
      headerName: "Amount",
      flex: 1,
    },
    {
      field: "bucket_usage_bytes",
      headerName: "Usage",
      flex: 1,
    },
    {
      field: "count_documents",
      headerName: "Documents",
      flex: 1,
    },
    {
      field: "compute_exec_sec",
      headerName: "Compute time",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "action",
      headerName: t("user_list:action"),
      sortable: false,
      renderCell: (params: any) => {
        const onDelete = () => {
          var id = String(params.id);
        };

        return <DeleteWithConfirm onClick={onDelete} />;
      },
    },
  ];

  if (loading) {
    setLoading(false);
    invoiceList().then((resp) => {
      var formated = resp.map((r) => {
        return {
          ...r,
          created_at: moment(r.created_at).format("YYYY-MM-DD"),
          bucket_usage_bytes: humanFileSize(r.bucket_usage_bytes, true, 2),
          compute_exec_sec: secondsToString(r.compute_exec_sec * 1000),
        };
      });
      setRows(formated);
    });
  }
  return (
    <Card>
      <TableContainer sx={{ minWidth: 800 }}>
        <DataGrid
          rows={rows ? rows : []}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          onCellClick={(e) => {
            console.log(e);
            //if (e.field !== "action") navigate(USER_EDIT_URL + e.row.id);
          }}
          autoHeight
          loading={loading && !rows}
        />
      </TableContainer>
    </Card>
  );
}
