import * as React from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Modal,
  Paper,
  Stack,
  TableContainer,
  Typography,
  Card,
  CardHeader,
  Divider,
  CardContent,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { api } from "../../services/api";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import DeleteWithConfirm from "../../components/DeleteWithConfirm";
import { Invoice, SubscriptionUsage } from "../../structs/gen/subscription";
import {
  fShortenNumber,
  humanFileSize,
  secondsToString,
} from "../../utils/formatNumber";
import {
  ComputerOutlined,
  DocumentScanner,
  Euro,
  FileUpload,
  Money,
} from "@mui/icons-material";

async function usageGet(): Promise<SubscriptionUsage> {
  let resp = await api.get(`/api/subscription/usage`);
  return resp.data;
}

export default function Usage() {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(true);
  const [usage, setUsage] = React.useState<SubscriptionUsage | undefined>(
    undefined,
  );

  if (loading) {
    setLoading(false);
    usageGet().then((resp) => {
      setUsage(resp);
    });
  }
  return (
    <>
      <Box
        sx={{
          display: "grid",
          gap: 2,
          gridTemplateColumns: "repeat(4, 1fr)",
        }}
      >
        <Block
          name={"cost"}
          color={"green"}
          icon={<Euro />}
          value={
            (usage?.estimated_price_monthly
              ? (usage?.estimated_price_monthly! / 100.0).toFixed(2)
              : 0) + " / month"
          }
          description={"Estimated price"}
        />
        <Block
          name={"documents"}
          icon={<DocumentScanner />}
          value={usage?.count_documents!}
          description={"Total amount of documents"}
          color={"blue"}
        />
        <Block
          name={"bytes"}
          icon={<FileUpload />}
          value={humanFileSize(usage?.usage_bytes!, true, 2)}
          description={"Total size of the files"}
          color={"blue"}
        />
        <Block
          name={"calculation"}
          icon={<ComputerOutlined />}
          value={secondsToString(usage?.calculation_time_seconds! * 1000)}
          description={"Total time of calculation"}
          color={"blue"}
        />
      </Box>
    </>
  );
}

function Block({
  name,
  value,
  icon,
  description,
  color,
}: {
  name: string;
  value: any;
  icon: any;
  description: string;
  color: string;
}) {
  return (
    <Paper
      key={name}
      variant="outlined"
      sx={{ py: 2.5, textAlign: "center", color: color }}
    >
      <Box sx={{ mb: 0.5 }}>{icon}</Box>

      <Typography variant="h6">{value}</Typography>

      <Typography variant="body2" sx={{ color: "text.secondary" }}>
        {description}
      </Typography>
    </Paper>
  );
}
