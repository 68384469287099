import { Add, Delete, DragIndicatorOutlined, Save } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import MultiLanguageLabel from "../../MultiLanguageLabel";
import { TableFieldTypeObject } from "./table_field_model";
import Page from "../../Page";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";
import { reorderList } from "../../../utils/order";

export default function TableConfig({
  config,
  onSave,
}: {
  config: TableFieldTypeObject;
  onSave: (newConfig: TableFieldTypeObject) => void;
}) {
  const [newConfig, setNewConfig] = useState(config);
  const optionsRef = useRef<null | HTMLDivElement>(null);

  const onDragEnd = (result: DropResult) => {
    console.log("onDragEnd", onDragEnd, result);
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reordered = reorderList(
      config.headers,
      result.source.index,
      result.destination.index,
    );

    console.log("reordered", reordered);

    setNewConfig({
      ...newConfig,
      convertValues: newConfig.convertValues,
      headers: reordered,
    });
  };

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      <div>
        <Page style={{ maxHeight: 300, overflow: "scroll" }}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="choice_config">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {config.headers.map((opt, i) => (
                    <Draggable key={i} draggableId={i + ""} index={i}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                          )}
                        >
                          <Card
                            sx={{ p: 1, display: "flex", alignItems: "center" }}
                            key={i}
                            id={i + ""}
                            ref={optionsRef}
                          >
                            <DragIndicatorOutlined />
                            <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
                              <MultiLanguageLabel
                                value={opt.label}
                                onChange={(v) => {
                                  setNewConfig({
                                    ...newConfig,
                                    convertValues: newConfig.convertValues,
                                    headers: newConfig.headers.map((f, id) => {
                                      if (id === i) {
                                        f.label = v;
                                      }
                                      return f;
                                    }),
                                  });
                                }}
                                multiValue={opt.label_intl}
                                onChangeMultiLabel={(v) => {
                                  setNewConfig({
                                    ...newConfig,
                                    convertValues: newConfig.convertValues,
                                    headers: newConfig.headers.map((f, id) => {
                                      if (id === i) {
                                        f.label_intl = v;
                                      }
                                      return f;
                                    }),
                                  });
                                }}
                              />
                            </FormControl>
                            <IconButton
                              onClick={() => {
                                config.headers.splice(i, 1);
                                setNewConfig({
                                  ...config,
                                  convertValues: config.convertValues,
                                  headers: config.headers,
                                });
                              }}
                              sx={{ p: "10px" }}
                            >
                              <Delete style={{ width: "36px" }} color="error" />
                            </IconButton>
                          </Card>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Page>
        <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <Button
            startIcon={<Add />}
            color={"success"}
            onClick={() => {
              config.headers.push({ label: "New", label_intl: {} });
              setNewConfig({
                ...config,
                convertValues: config.convertValues,
                headers: config.headers,
              });
              setTimeout(function () {
                optionsRef.current?.scrollIntoView({ behavior: "smooth" });
              }, 100);
            }}
          >
            New header
          </Button>
        </FormControl>

        <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <FormControlLabel
            control={
              <Switch
                defaultChecked={newConfig.is_visible}
                value={newConfig.is_visible}
                onChange={(e) => {
                  setNewConfig({
                    ...newConfig,
                    convertValues: newConfig.convertValues,
                    is_visible: e.target.checked,
                  });
                }}
              />
            }
            label="Is visible"
          />
        </FormControl>

        <Button
          onClick={() => {
            console.log("newConfig", newConfig);
            onSave(newConfig);
          }}
          fullWidth
          startIcon={<Save />}
          color={"success"}
          variant="contained"
        >
          Save
        </Button>
      </div>
    </Box>
  );
}

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? "lightblue" : "none",
  overflow: "scroll",
});

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",

  // change background colour if dragging
  background: isDragging ? "none" : "none",

  // styles we need to apply on draggables
  ...draggableStyle,
  left: 0,
});

function getID(): string {
  return (Math.random() + 1).toString(36).substring(7);
}
