import * as React from "react";
import { Save, Send, Settings } from "@mui/icons-material";
// @mui
import {
  Divider,
  MenuItem,
  Box,
  Button,
  Typography,
  FormControl,
  TextField,
  InputLabel,
  Select,
  Chip,
  Avatar,
  FormLabel,
  InputAdornment,
  FormHelperText,
  IconButton,
} from "@mui/material";
// components
// import Iconify from '../../../components/Iconify';
import { api } from "../../services/api";
import { Task } from "../../structs/gen/task";
import {
  UtilGenerateScheduleRequest,
  UtilGenerateScheduleResponse,
} from "../../structs/gen/utils";
import { useTranslation } from "react-i18next";
import { Template } from "../../structs/gen/template";
import { User } from "../../structs/gen/user";
import { TaskStatus } from "../../structs/gen/enums/task";
import { Document } from "../../structs/gen/document";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const modalStyle = {
  // position: "absolute" as "absolute",
  // top: "50%",
  // left: "50%",
  // transform: "translate(-50%, -50%)",

  position: "absolute" as "absolute",
  marginLeft: "auto",
  marginRight: "auto",
  left: 0,
  right: 0,
  textAlign: "center",
  marginTop: "25px",
  marginBottom: "25px",

  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

async function taskSave(id: string | undefined, data: Task) {
  if (id) {
    let resp = await api.post(`/api/task/${id}`, data);
    return resp;
  } else {
    let resp = await api.post(`/api/task/`, data);
    return resp;
  }
}

async function generateCrontab(
  text: string,
): Promise<UtilGenerateScheduleResponse> {
  var req: UtilGenerateScheduleRequest = {
    text: text,
  };
  let resp = await api.post(`/api/utils/generate_schedule`, req);
  return resp.data;
}

export default function TaskForm({
  task,
  setTask,
  users,
  templates,
  documents,
  onSave,
}: {
  task: Task;
  setTask: (value: React.SetStateAction<Task | undefined>) => void;
  users: User[] | undefined;
  templates: Template[];
  documents: Document[];
  onSave: () => void;
}) {
  const { t } = useTranslation();

  const [isCronTextChanged, setIsCronTextChanged] = React.useState(false);

  return (
    <Box sx={modalStyle}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        {task.id === "" ? t("task:create") : "Edit task"}
      </Typography>

      <FormControl fullWidth sx={{ m: 1 }}>
        <TextField
          fullWidth
          label={t("task:title")}
          value={task.title}
          type={"text"}
          onChange={(e) => {
            setTask({
              ...task,
              title: e.target.value,
              convertValues: task.convertValues,
            });
          }}
        />
      </FormControl>

      <FormControl fullWidth sx={{ m: 1 }}>
        <InputLabel id="select-template">Status</InputLabel>
        <Select
          labelId="select-status"
          id="demo-simple-source"
          defaultValue={task.status}
          label="Status"
          onChange={(e) => {
            setTask({
              ...task,
              status: e.target.value,
              convertValues: task.convertValues,
            });
          }}
        >
          <MenuItem key={TaskStatus.todo} value={TaskStatus.todo}>
            To do
          </MenuItem>
          <MenuItem key={TaskStatus.doing} value={TaskStatus.doing}>
            Doing
          </MenuItem>
          <MenuItem key={TaskStatus.done} value={TaskStatus.done}>
            Done
          </MenuItem>
        </Select>
      </FormControl>

      {(task.status === TaskStatus.doing ||
        task.status === TaskStatus.done) && (
        <FormControl fullWidth sx={{ m: 1 }}>
          <TextField
            fullWidth
            label="Time spent"
            value={task.spent_minutes}
            type="number"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">minutes</InputAdornment>
              ),
            }}
            onChange={(e) => {
              setTask({
                ...task,
                spent_minutes: +e.target.value,
                convertValues: task.convertValues,
              });
            }}
          />
        </FormControl>
      )}

      <FormControl fullWidth sx={{ m: 1 }}>
        <InputLabel id="select-employee">Select employee</InputLabel>
        <Select
          labelId="select-employee"
          id="demo-simple-source-employee"
          defaultValue={task.user_id}
          label="Select employee"
          onChange={(e) => {
            setTask({
              ...task,
              user_id: e.target.value === "" ? undefined : e.target.value,
              convertValues: task.convertValues,
            });
          }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem key="elena" value="elena">
            <Chip
              avatar={
                <Avatar
                  alt="Elena"
                  src="https://static.tildacdn.com/lib/tildaicon/33376331-6561-4232-a339-616337393631/touragency_emoji.svg"
                />
              }
              key="elena"
              label="Elena"
            />
          </MenuItem>
          {users &&
            users.map((value) => (
              <MenuItem key={value.id} value={value.id}>
                <Chip
                  avatar={
                    value.avatar !== "" ? (
                      <Avatar alt={value.name} src={value.avatar} />
                    ) : undefined
                  }
                  key={value.id}
                  label={value.name}
                />
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <FormControl fullWidth sx={{ m: 1 }}>
        <InputLabel id="select-template">Select template</InputLabel>
        <Select
          labelId="select-template"
          id="demo-simple-source"
          defaultValue={task.template_id}
          label="Select template"
          onChange={(e) => {
            setTask({
              ...task,
              template_id: e.target.value === "" ? undefined : e.target.value,
              convertValues: task.convertValues,
            });
          }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {templates.map((t) => (
            <MenuItem key={t.id} value={t.id}>
              {t.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {task.template_id && (
        <FormControl fullWidth sx={{ m: 1 }}>
          <InputLabel id="select-document">Select document</InputLabel>
          <Select
            labelId="select-document"
            id="demo-simple-source"
            defaultValue={task.document_id}
            label="Select document"
            onChange={(e) => {
              setTask({
                ...task,
                document_id: e.target.value === "" ? undefined : e.target.value,
                convertValues: task.convertValues,
              });
            }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {documents.map((t) => (
              <MenuItem key={t.id} value={t.id}>
                {t.key}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <FormControl fullWidth sx={{ m: 1 }}>
        <TextField
          fullWidth
          label={t("task:description")}
          value={task.description}
          type={"text"}
          multiline
          minRows={3}
          onChange={(e) => {
            setTask({
              ...task,
              description: e.target.value,
              convertValues: task.convertValues,
            });
          }}
        />
      </FormControl>

      <FormControl fullWidth sx={{ m: 1 }}>
        <FormLabel component="legend">Date and time</FormLabel>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            inputFormat="dd.MM.yyyy HH:mm"
            value={task.deadline_datetime || ""}
            onChange={(newValue: string | null) => {
              console.log("newValue", newValue);
              if (newValue && newValue !== null && newValue !== "") {
                var formatedTime = moment(newValue).format(
                  "YYYY-MM-DDTHH:mm:ss.sssZ",
                );
                setTask({
                  ...task,
                  deadline_datetime: formatedTime,
                  convertValues: task.convertValues,
                });
              } else {
                setTask({
                  ...task,
                  deadline_datetime: undefined,
                  convertValues: task.convertValues,
                });
              }
            }}
            renderInput={(params) => (
              <TextField {...params} error={false} fullWidth />
            )}
          />
        </LocalizationProvider>
      </FormControl>

      <Divider>OR</Divider>

      <FormControl fullWidth sx={{ m: 1 }}>
        <TextField
          fullWidth
          label="Schedule"
          value={task.cron_text}
          helperText={task.cron}
          placeholder="Every day at 14:00"
          type={"text"}
          onChange={(e) => {
            if (e.target.value === "") {
              if (isCronTextChanged) {
                setIsCronTextChanged(false);
              }
              setTask({
                ...task,
                cron_text: undefined,
                cron: undefined,
                convertValues: task.convertValues,
              });
            } else {
              setTask({
                ...task,
                cron_text: e.target.value,
                cron: undefined,
                convertValues: task.convertValues,
              });

              if (!isCronTextChanged) {
                setIsCronTextChanged(true);
              }
            }
          }}
          InputProps={{
            endAdornment: isCronTextChanged ? (
              <InputAdornment position="end">
                <IconButton
                  onClick={async () => {
                    if (task.cron_text) {
                      let cron = await generateCrontab(task.cron_text);

                      setTask({
                        ...task,
                        cron: cron.crontab,
                        convertValues: task.convertValues,
                      });

                      setIsCronTextChanged(false);
                    }
                  }}
                >
                  <Send />
                </IconButton>
              </InputAdornment>
            ) : undefined,
          }}
          aria-describedby="schedule-helper-text"
        />
      </FormControl>

      <br />
      <Button
        fullWidth
        onClick={async () => {
          await taskSave(task.id, task);
          onSave();
        }}
        startIcon={<Save />}
        color={"success"}
      >
        {t("task:save")}
      </Button>
    </Box>
  );
}
