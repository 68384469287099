/* Do not change, this code is generated from Golang structs */


export class String {


    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);

    }
}
export class Time {


    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);

    }
}
export class Task {
    id: string;
    title: string;
    description: string;
    status: string;
    spent_minutes?: number;
    deadline_datetime?: Time;
    user_name?: string;
    user_id?: String;
    template_id?: string;
    document_id?: string;
    cron?: string;
    cron_text?: string;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.id = source["id"];
        this.title = source["title"];
        this.description = source["description"];
        this.status = source["status"];
        this.spent_minutes = source["spent_minutes"];
        this.deadline_datetime = this.convertValues(source["deadline_datetime"], Time);
        this.user_name = source["user_name"];
        this.user_id = this.convertValues(source["user_id"], String);
        this.template_id = source["template_id"];
        this.document_id = source["document_id"];
        this.cron = source["cron"];
        this.cron_text = source["cron_text"];
    }

	convertValues(a: any, classs: any, asMap: boolean = false): any {
	    if (!a) {
	        return a;
	    }
	    if (a.slice) {
	        return (a as any[]).map(elem => this.convertValues(elem, classs));
	    } else if ("object" === typeof a) {
	        if (asMap) {
	            for (const key of Object.keys(a)) {
	                a[key] = new classs(a[key]);
	            }
	            return a;
	        }
	        return new classs(a);
	    }
	    return a;
	}
}
export class TaskListResponse {
    count: number;
    tasks: Task[];

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.count = source["count"];
        this.tasks = this.convertValues(source["tasks"], Task);
    }

	convertValues(a: any, classs: any, asMap: boolean = false): any {
	    if (!a) {
	        return a;
	    }
	    if (a.slice) {
	        return (a as any[]).map(elem => this.convertValues(elem, classs));
	    } else if ("object" === typeof a) {
	        if (asMap) {
	            for (const key of Object.keys(a)) {
	                a[key] = new classs(a[key]);
	            }
	            return a;
	        }
	        return new classs(a);
	    }
	    return a;
	}
}