import * as React from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Modal,
  Paper,
  Stack,
  TableContainer,
  Typography,
  Card as CardComp,
  CardHeader,
  Divider,
} from "@mui/material";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Add, Create, PaymentRounded } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { api } from "../../services/api";
import { Card } from "../../structs/gen/subscription";
import Page from "../../components/Page";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import DeleteWithConfirm from "../../components/DeleteWithConfirm";
import CreditCardDetail from "../stripe/CreditCardDetail";
import { pad2 } from "../../utils/formatNumber";

const modalStyle = {
  // position: "absolute" as "absolute",
  // top: "50%",
  // left: "50%",
  // transform: "translate(-50%, -50%)",

  position: "absolute" as "absolute",
  marginLeft: "auto",
  marginRight: "auto",
  left: 0,
  right: 0,
  textAlign: "center",
  marginTop: "25px",
  marginBottom: "25px",

  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const stripePromise = loadStripe(
  "pk_live_51LsPnLEAnASruTRMXmq4vRrL4Two93LAoioCIWynwjMw3LxKdTt9baakjlfz68vJY7IKzpEgvITCY448dVB54VAZ00TCbJYiPS",
);

async function cardList(): Promise<Card[]> {
  let resp = await api.get(`/api/subscription/cards`);
  return resp.data;
}

async function cardDelete(id: string): Promise<void> {
  await api.delete(`/api/subscription/cards/${id}`);
}

export default function Cards() {
  const [loading, setLoading] = React.useState(true);
  const [addCardOpen, setAddCardOpen] = React.useState(false);
  const [cards, setCards] = React.useState<Card[]>([]);
  if (loading) {
    setLoading(false);
    cardList().then((resp) => {
      setCards(resp);
    });
  }
  return (
    <Elements stripe={stripePromise}>
      <Modal open={addCardOpen} onClose={() => setAddCardOpen(false)}>
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Create payment method
          </Typography>
          <br />
          <ElementsConsumer>
            {(ctx: any) => (
              <CreditCardDetail
                onChange={() => {
                  setLoading(true);
                  setAddCardOpen(false);
                }}
                {...ctx}
              />
            )}
          </ElementsConsumer>
        </Box>
      </Modal>
      <CardComp>
        <CardHeader title={"Cards"} />

        <Stack spacing={3} sx={{ p: 3, pr: 0 }}>
          {cards.map((card) => {
            return (
              <Stack direction="row" alignItems="center" spacing={2}>
                {/* <Box
              component="img"
              alt={title}
              src={image}
              sx={{ borderRadius: 1.5, flexShrink: 0 }}
            /> */}

                <Box sx={{ flexGrow: 1 }}>
                  <Typography
                    variant="body2"
                    sx={{ color: "text.secondary" }}
                    noWrap
                  >
                    {card.brand} {"**** " + card.number}
                  </Typography>
                </Box>

                <Typography
                  variant="caption"
                  sx={{ pr: 3, flexShrink: 0, color: "text.secondary" }}
                >
                  {pad2(card.exp_month) + "/" + card.exp_year}
                </Typography>
                <DeleteWithConfirm
                  onClick={async () => {
                    await cardDelete(card.id);
                    setLoading(true);
                  }}
                />
              </Stack>
            );
          })}
        </Stack>

        <Divider />

        <Box sx={{ p: 2, textAlign: "right" }}>
          <Button
            size="small"
            color="primary"
            endIcon={<Add />}
            onClick={() => {
              setAddCardOpen(true);
            }}
          >
            New card
          </Button>
        </Box>
      </CardComp>
    </Elements>
  );
}
