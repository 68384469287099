import {
  Autocomplete,
  Avatar,
  Box,
  Chip,
  createFilterOptions,
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { api } from "../../../services/api";
import { DocumentListResponse, Document } from "../../../structs/gen/document";
import { FieldType, Template } from "../../../structs/gen/template";
import { SelectChangeEvent } from "@mui/material/Select";
import { random } from "lodash";
import { FieldTypeEnum } from "../../../structs/gen/enums/template";
import moment from "moment";
import { getFieldValue } from "../../../utils/field";
import { UserLinkFieldTypeObject } from "./user_link_field_model";
import { User, UserListResponse } from "../../../structs/gen/user";

async function apiListUsers(): Promise<UserListResponse> {
  let resp = await api.post(`/api/user/list?current=yes`, {
    offset: 0,
    next: 100,
    filters: [],
  });
  return resp.data;
}

const UserLinkField = ({
  id,
  config,
  values,
  onChange,
  errors,
}: {
  id: string;
  config: UserLinkFieldTypeObject;
  values: string[];
  onChange: (value: string[]) => void;
  errors: string[];
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState<User[]>([]);

  if (isLoading) {
    apiListUsers().then((resp) => setUsers(resp.rows));
    setIsLoading(false);
  }
  console.log("UserLinkField", users, values, config);

  return (
    <FormControl
      fullWidth
      required={config.is_required}
      error={errors && errors.length > 0}
      disabled={config.is_disabled}
    >
      <InputLabel id="demo-multiple-chip-label">{config.label}</InputLabel>
      <Select
        labelId="demo-multiple-chip-label"
        id="demo-multiple-chip"
        multiple={config && config.is_multiple ? true : false}
        value={values}
        disabled={config.is_disabled}
        onChange={(v) => {
          if (typeof v.target.value === "string") {
            onChange([v.target.value]);
          } else {
            onChange(v.target.value);
          }
        }}
        input={
          <OutlinedInput id="demo-multiple-chip-label" label={config.label} />
        }
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => {
              const user = users.find((u) => u.id === value);
              if (!user) {
                return <></>;
              }
              return (
                <Chip
                  avatar={
                    user.avatar !== "" ? (
                      <Avatar alt={user.name} src={user.avatar} />
                    ) : undefined
                  }
                  key={user.id}
                  label={user.name}
                />
              );
            })}
          </Box>
        )}
      >
        {users
          .filter((u) => config.roles.includes(u.role))
          .map((r) => (
            <MenuItem key={r.id} value={r.id}>
              {r.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};

export default React.memo(UserLinkField);
