import * as React from "react";
import { useTheme } from "@mui/material/styles";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Label,
  ResponsiveContainer,
} from "recharts";
import { Card, CardContent, CardHeader, Link, Typography } from "@mui/material";
import { BarChart } from "@mui/icons-material";
import { LineChartStatistic } from "../../structs/gen/statistic";

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}

export default function StatisticLineChart({
  title,
  subheader,
  props,
  ...other
}: {
  title: string;
  subheader?: string;
  props: LineChartStatistic;
}) {
  const theme = useTheme();

  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} avatar={<BarChart />} />
      <React.Fragment>
        <CardContent
          style={{
            height: 300,
            width: "100%",
          }}
        >
          <ResponsiveContainer>
            <LineChart
              data={props.values}
              margin={{
                top: 16,
                right: 16,
                bottom: 0,
                left: 24,
              }}
            >
              <XAxis
                dataKey="x"
                stroke={theme.palette.text.secondary}
                style={theme.typography.body2}
              />
              <YAxis
                stroke={theme.palette.text.secondary}
                style={theme.typography.body2}
              />
              <Line
                isAnimationActive={false}
                type="monotone"
                dataKey="y"
                stroke="#82ca9d"
                // dot={false}
              />
            </LineChart>
          </ResponsiveContainer>
        </CardContent>
      </React.Fragment>
      {props.link && (
        <div>
          <Link color="primary" href={props.link} onClick={preventDefault}>
            More
          </Link>
        </div>
      )}
    </Card>
  );
}
