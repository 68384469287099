import * as React from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  ClickAwayListener,
  Container,
  Divider,
  Fade,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Modal,
  Paper,
  Popper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Page from "../../components/Page";
import { Add, Edit, FolderOpen } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { DOCUMENT_URL, TEMPLATE_EDIT_URL } from "../../structs/urls";
import TemplateRenderer from "../../components/Template";
import { Template, TemplateListResponse } from "../../structs/gen/template";
import { api } from "../../services/api";
import { useNavigate } from "react-router-dom";
import MenuModal from "../../components/menu/MenuModal";
import { Menu, MenuListResponse } from "../../structs/gen/menu";
import PopupState, { bindToggle, bindPopper } from "material-ui-popup-state";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteWithConfirm from "../../components/DeleteWithConfirm";
import MultiLanguageLabel from "../../components/MultiLanguageLabel";

async function apiList(callback: any) {
  let resp = await api.post(`/api/template/list`, {
    offset: 0,
    next: 10,
    filters: [],
  });
  callback(resp.data);
}

async function apiDelete(id: string, callback: any) {
  let resp = await api.delete(`/api/template/${id}`);
  callback(resp.data);
}

async function apiCopy(id: string, callback: any) {
  let resp = await api.post(`/api/template/${id}/copy`);
  callback(resp.data);
}

async function apiSave(id: string | undefined, data: Template): Promise<any> {
  let resp = await api.post(`/api/template/${id}`, data);
  return resp.data;
}

async function menuList(): Promise<MenuListResponse> {
  let resp = await api.post(`/api/menu/list`, {
    offset: 0,
    next: 10,
    filters: [],
  });
  return resp.data;
}

async function menuDelete(id: string): Promise<any> {
  let resp = await api.delete(`/api/menu/${id}`);
  return resp.data;
}

async function apiSaveMenu(id: string, data: Menu) {
  let resp = await api.post(`/api/menu/${id}`, data);
  return resp;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function Dashboard() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [rows, setRows] = React.useState<Template[] | undefined>(undefined);
  const [menus, setMenus] = React.useState<Menu[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [moveTemplateToMenu, setMoveTemplate] = React.useState<
    Template | undefined
  >();
  const [editFolder, setEditFolder] = React.useState<Menu | undefined>();
  const [currentMenuID, setCurrentMenuID] = React.useState<string | undefined>(
    undefined,
  );
  const [is_menu_modal_open, setMenuModalOpen] = React.useState(false);
  if (loading) {
    setLoading(false);
    apiList((resp: TemplateListResponse) => {
      setRows(resp.rows);
    });
    menuList().then((resp) => {
      setMenus(resp.rows);
    });
  }

  const moveTemplate = (t: Template | undefined) => {
    setMoveTemplate(t);
    setMenuModalOpen(true);
  };

  const closeMoveTemplate = () => {
    setMoveTemplate(undefined);
    setMenuModalOpen(false);
  };

  if (loading || !rows) {
    return <CircularProgress />;
  }
  return (
    <Page title={t("dashboard:title")}>
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <Box sx={{ flexGrow: 1 }}>
              {currentMenuID && (
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    underline="hover"
                    color="inherit"
                    onClick={() => {
                      setCurrentMenuID(undefined);
                    }}
                  >
                    Home
                  </Link>
                  {/* {currentMenuID && (<Typography color="text.primary">Breadcrumbs</Typography>)} */}
                </Breadcrumbs>
              )}
              <Grid container justifyContent="center" spacing={2}>
                <Grid item key={-1} sx={{ padding: "5px" }}>
                  <Card sx={{ width: 270, height: 300 }}>
                    <CardHeader title={"New template"} />
                    <CardActions
                      onClick={() => {
                        navigate(TEMPLATE_EDIT_URL);
                      }}
                    >
                      <CardContent style={{ width: "100%" }}>
                        <Grid container direction="column" alignItems="center">
                          <Grid item>
                            <Add sx={{ fontSize: 110 }} color={"primary"} />
                          </Grid>
                          <Grid item>
                            <Typography variant="body2" color="text.secondary">
                              Create new template or choose from examples
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </CardActions>
                  </Card>
                </Grid>
                <MenuModal
                  show={is_menu_modal_open}
                  menu_id={moveTemplateToMenu?.menu_id}
                  template_id={moveTemplateToMenu?.id || ""}
                  moveToMenu={async (menu_id) => {
                    if (moveTemplateToMenu) {
                      moveTemplateToMenu.menu_id = menu_id;
                      await apiSave(moveTemplateToMenu?.id, moveTemplateToMenu);
                      closeMoveTemplate();
                      //setLoading(true);
                      //FIXME: refresh page for reload menu items
                      window.location.reload();
                    }
                  }}
                  onClose={closeMoveTemplate}
                />
                {editFolder && (
                  <Modal
                    open={editFolder != undefined}
                    onClose={() => {
                      setEditFolder(undefined);
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        Edit Folder
                      </Typography>

                      <Grid item xs={12}>
                        <MultiLanguageLabel
                          value={editFolder!.name}
                          onChange={(v) => {
                            if (editFolder) {
                              setEditFolder({
                                ...editFolder,
                                name: v,
                              });
                            }
                          }}
                          multiValue={editFolder!.name_intl}
                          onChangeMultiLabel={(v) => {
                            if (editFolder) {
                              setEditFolder({
                                ...editFolder,
                                name_intl: v,
                              });
                            }
                          }}
                        />
                      </Grid>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Button
                          color="primary"
                          onClick={async () => {
                            if (editFolder) {
                              await apiSaveMenu(editFolder.id, editFolder);
                              setMenus([
                                ...menus.map((m) => {
                                  if (m.id === editFolder.id) {
                                    return editFolder;
                                  }
                                  return m;
                                }),
                              ]);
                            }
                            setEditFolder(undefined);
                          }}
                        >
                          Save
                        </Button>

                        <Button
                          color="error"
                          onClick={() => {
                            setEditFolder(undefined);
                          }}
                        >
                          Cancel
                        </Button>
                      </Stack>
                    </Box>
                  </Modal>
                )}
                {menus
                  .filter(
                    (r) =>
                      (r.parent_menu_id == undefined &&
                        currentMenuID == undefined) ||
                      r.parent_menu_id === currentMenuID,
                  )
                  .map((v, index) => (
                    <Grid item key={index} sx={{ padding: "5px" }}>
                      <Card sx={{ width: 270, height: 300 }}>
                        <CardHeader
                          title={<Typography variant="h6">{v.name}</Typography>}
                          style={{ height: 85 }}
                          action={
                            <PopupState
                              variant="popper"
                              popupId="demo-popup-popper"
                            >
                              {(popupState) => (
                                <div>
                                  <ClickAwayListener
                                    onClickAway={() => {
                                      popupState.close();
                                    }}
                                  >
                                    <div>
                                      <IconButton {...bindToggle(popupState)}>
                                        <MoreVertIcon />
                                      </IconButton>
                                      <Popper
                                        {...bindPopper(popupState)}
                                        transition
                                      >
                                        {({ TransitionProps }) => (
                                          <Fade
                                            {...TransitionProps}
                                            timeout={350}
                                          >
                                            <Paper>
                                              <MenuItem
                                                onClick={() => {
                                                  setCurrentMenuID(v.id);
                                                }}
                                              >
                                                <Button
                                                  startIcon={<FolderOpen />}
                                                  color="primary"
                                                >
                                                  Open
                                                </Button>
                                              </MenuItem>
                                              <MenuItem>
                                                <Button
                                                  onClick={() => {
                                                    setEditFolder(v);
                                                  }}
                                                  startIcon={<Edit />}
                                                  color="warning"
                                                >
                                                  Edit
                                                </Button>
                                              </MenuItem>
                                              <MenuItem>
                                                <DeleteWithConfirm
                                                  onClick={async () => {
                                                    await menuDelete(v.id);
                                                    setMenus([
                                                      ...menus.filter(
                                                        (m) => m.id !== v.id,
                                                      ),
                                                    ]);
                                                  }}
                                                  text={"Delete"}
                                                />
                                              </MenuItem>
                                            </Paper>
                                          </Fade>
                                        )}
                                      </Popper>
                                    </div>
                                  </ClickAwayListener>
                                </div>
                              )}
                            </PopupState>
                          }
                        />
                        <CardActions onClick={() => {}}>
                          <CardContent style={{ height: 145 }}>
                            <Typography variant="body2" color="text.secondary">
                              {/* {truncate(
                                t.data
                                  .map((d: any) => d.label && d.label)
                                  .join(", "),
                                100,
                                true,
                              )} */}
                            </Typography>
                          </CardContent>
                        </CardActions>
                        <Divider />
                        <CardActions>
                          <Button
                            endIcon={<FolderOpen />}
                            style={{ width: "100%" }}
                            onClick={() => {
                              setCurrentMenuID(v.id);
                            }}
                          >
                            Open
                          </Button>
                        </CardActions>
                      </Card>
                    </Grid>
                  ))}
                {rows
                  .filter(
                    (r) =>
                      (r.menu_id == undefined && currentMenuID == undefined) ||
                      r.menu_id === currentMenuID,
                  )
                  .sort((a, b) => -b.name.localeCompare(a.name))
                  .map((v, index) => (
                    <Grid item key={index} sx={{ padding: "5px" }}>
                      <TemplateRenderer
                        t={v}
                        onEdit={() => {
                          navigate(TEMPLATE_EDIT_URL + v.id);
                        }}
                        onCreateDocument={() => {
                          navigate(DOCUMENT_URL + v.id + "/edit/");
                        }}
                        onDelete={() => {
                          var templateID = String(v.id);
                          apiDelete(templateID, () => {
                            setRows(rows?.filter((v) => v.id !== templateID));
                            //FIXME: refresh page for reload menu items
                            window.location.reload();
                          });
                        }}
                        onMoveTemplate={() => {
                          moveTemplate(v);
                        }}
                        onViewDocs={() => {
                          navigate(DOCUMENT_URL + v.id);
                        }}
                        onCopyTemplate={() => {
                          var templateID = String(v.id);
                          apiCopy(templateID, () => {
                            window.location.reload();
                          });
                        }}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
