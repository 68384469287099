import * as React from "react";
import { Divider, TextField, useTheme } from "@mui/material";
import { Template } from "../../structs/gen/template";
import BuildFields from "../field/BuildFields";
import { Document } from "../../structs/gen/document";
import { fDateTimeToGolang } from "../../utils/formatTime";
import { createDefaultDocument } from "../../structs/document";

export default function CodeEditor({
  originTemplate,
  formula,
}: {
  originTemplate: Template;
  formula: string;
}) {
  const theme = useTheme();
  const [template, setTemplate] = React.useState<Template>(originTemplate);
  const [document, setDocument] = React.useState<Document>(
    createDefaultDocument(originTemplate),
  );
  const [errors, setErrors] = React.useState<any>({});
  return (
    <>
      <BuildFields
        templateId={template.id}
        config={template.data}
        onUpdated={(data, config, key, user_access) => {
          console.log("onUpdated", data, config, key, user_access);
          if (config != null) {
            setTemplate({
              ...template,
              data: config,
            });
          }
          let newData: any = {};
          let isUpdated: boolean = false;
          if (data != null) {
            newData.data = data;
            isUpdated = true;
          }
          if (key != null) {
            newData.key = key;
            isUpdated = true;
          }
          if (user_access != null) {
            newData.user_access = user_access;
            isUpdated = true;
          }
          console.log("isUpdated", isUpdated, newData);
          if (isUpdated) {
            setDocument({
              ...document,
              convertValues: document.convertValues,
              ...newData,
            });
          }
        }}
        setErrors={setErrors}
        document={document}
        errors={errors}
        isDev={true}
        formula={formula}
        isConstructorMode={false}
        executeOn={template.execute_on}
      />

      <Divider>Debug</Divider>
      <TextField
        id="outlined-multiline-flexible"
        label="Value"
        multiline
        maxRows={8}
        value={JSON.stringify(document.data, null, 2)}
        onChange={(e) => {
          if (e.target.value) {
            var newData = JSON.parse(e.target.value);
            setDocument({
              ...document,
              convertValues: document.convertValues,
              data: newData,
            });
          }
        }}
        fullWidth
      />
    </>
  );
}
