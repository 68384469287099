import * as React from "react";
import TableContainer from "@mui/material/TableContainer";
import { api } from "../../services/api";
import { User, UserListResponse } from "../../structs/gen/user";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Page from "../../components/Page";
import {
  Add,
  Create,
  CreateNewFolder,
  Delete,
  DeleteForever,
  FolderOpen,
  FolderRounded,
  Public,
} from "@mui/icons-material";
import DeleteWithConfirm from "../../components/DeleteWithConfirm";
import {
  File,
  Folder,
  FolderListAndFileListResponse,
} from "../../structs/gen/file";
import moment from "moment";
import { truncate } from "../../utils/formatString";

async function apiList(): Promise<FolderListAndFileListResponse> {
  let resp = await api.post(`/api/file/list`, {
    offset: 0,
    next: 100,
    filters: [],
  });
  return resp.data;
}

async function apiDelete(id: string) {
  let resp = await api.delete(`/api/file/${id}`);
  return resp.data;
}

export default function FileList() {
  const navigate = useNavigate();
  const [files, setFiles] = React.useState<File[]>([]);
  const [folders, setFolders] = React.useState<Folder[]>([]);
  const [loading, setLoading] = React.useState(true);

  if (loading) {
    setLoading(false);
    apiList().then((resp) => {
      setFiles(resp.rows);
    });
  }

  return (
    <>
      <Page title="Files">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              File explorer
            </Typography>
            <Button
              variant="contained"
              component="label"
              startIcon={<Create />}
            >
              Create File
              <input
                type="file"
                hidden
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  console.log("input onChange");
                  if (e.target && e.target.files) {
                    for (var id = 0; id < e.target.files.length; id++) {
                      let formData = new FormData();
                      formData.append("file", e.target.files[id]);
                      api.post("/api/file/", formData).then((res: any) => {
                        setLoading(true);
                      });
                    }
                    console.log("event.target.files", e.target.files);
                  }
                }}
              />
            </Button>
          </Stack>
          <Grid container justifyContent="center">
            {files.map((f) => {
              return (
                <Grid>
                  <FileCard file={f} setLoading={setLoading} />{" "}
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </Page>
    </>
  );
}

// export const createArchive = (formData, resolve, reject) => {
//     const config = getConfig();
//     api
//       .post(`/archive/`, formData, config)
//       .then((response) => resolve?.(response))
//       .catch((error) => reject?.(error));
//   };

//   save = () => {
//     const {
//       intl: { formatMessage },
//     } = this.props;
//     const { fileList } = this.state;
//     const formData = new FormData();

//     formData.append('file', fileList[0]);

//     const formValue = this.props.form.getFieldsValue();

//     let json = {
//       comment: formValue.comment,
//       client_id: formValue.clients.length > 0 ? +formValue.clients[0] : null,
//       contract_id: formValue.contracts,
//       user_id: formValue.users,
//     };

//     formData.append('json', JSON.stringify(json));

//     this.setState({
//       uploading: true,
//     });

//     createArchive(
//       formData,
//       (Response) => {
//         this.setState({
//           fileList: [],
//           uploading: false,
//         });
//         message.success(
//           formatMessage(messages.uploadSuccess, { name: fileList[0].name })
//         );
//       },
//       (error) => {
//         this.setState({
//           uploading: false,
//         });
//         message.error(
//           formatMessage(messages.uploadFailed, { name: fileList[0].name })
//         );
//       }
//     );
//   };

function FolderCard({ folder }: { folder: Folder }) {
  return (
    <Card sx={{ maxWidth: 245, m: 1 }}>
      <CardHeader
        // avatar={
        //   <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
        //     R
        //   </Avatar>
        // }
        action={
          <IconButton aria-label="settings" color="error">
            <DeleteForever />
          </IconButton>
        }
        title={<Typography variant="h6">{folder.name}</Typography>}
        subheader={moment(folder.updated_at).format("YYYY-MM-DD hh:mm:ss")}
      />
      <FolderOpen sx={{ fontSize: 180 }} />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          Size: {folder.size_kb} kb
          <br />
          Updated: {moment(folder.updated_at).format("YYYY-MM-DD hh:mm:ss")}
        </Typography>
      </CardContent>
      {/* <CardActions>
        <Button size="small" color="error">
          Delete
        </Button>
      </CardActions> */}
    </Card>
  );
}

function FileCard({ file, setLoading }: { file: File; setLoading: any }) {
  return (
    <Card sx={{ maxWidth: 245, m: 1 }}>
      <CardHeader
        // avatar={
        //   <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
        //     R
        //   </Avatar>
        // }
        action={
          <IconButton
            aria-label="settings"
            color="error"
            onClick={async () => {
              await apiDelete(file.id);
              setLoading(true);
            }}
          >
            <DeleteForever />
          </IconButton>
        }
        title={truncate(file.name, 15, true)}
        subheader={moment(file.updated_at).format("YYYY-MM-DD hh:mm:ss")}
      />
      <CardMedia component="img" height="140" image={file.url} />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          Size: {file.size_kb} kb
          <br />
          {file.is_public && (
            <>
              <br />
              <Public />
            </>
          )}
        </Typography>
      </CardContent>
      {/* <CardActions>
        <Button size="small" color="error">
          Delete
        </Button>
      </CardActions> */}
    </Card>
  );
}
