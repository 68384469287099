import * as React from "react";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  TextField,
} from "@mui/material";
import { api } from "../services/api";
import OTPInput from "../components/OTPInput";
import { User } from "../structs/gen/user";
import {
  PasswordOutlined,
  RemoveCircle,
  SecurityOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import Password from "../components/settings/Password";
import AvatarUpload from "../components/settings/AvatarUpload";

async function getUserSettings() {
  let resp = await api.get(`/api/user/settings`);
  console.log("resp.data", resp.data);
  return resp.data;
}

async function getQR() {
  let resp = await api.get(`/api/2fa/qr.png`);
  console.log("resp.data", resp.data);
  return resp.data;
}

async function confirmCode(code: string) {
  let resp = await api.post(`/api/2fa/code`, {
    code: code,
  });
  console.log("resp.data", resp.data);
  return resp.data;
}

async function delete2Fa() {
  let resp = await api.delete(`/api/2fa`);
  return resp.data;
}

async function apiSave(id: string | undefined, data: User, callback: any) {
  let resp = await api.post(`/api/user/${id}`, data);
  callback(resp.data);
}

export default function UserSettings() {
  const [isLoading, setIsLoading] = React.useState(true);
  const [qrCode, setQRCode] = React.useState("");
  const [code, setCode] = React.useState("");
  const [user, setUser] = React.useState<User | undefined>(undefined);

  if (isLoading) {
    getUserSettings().then((v: User) => {
      setUser(v);
    });
    setIsLoading(false);
  }

  if (!user) {
    return <CircularProgress />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <Paper elevation={9} sx={{ mt: 1 }} style={{ padding: "20px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AvatarUpload
                src={user && user!.avatar}
                onChange={(avatar) => {
                  setUser({
                    ...user,
                    convertValues: user.convertValues,
                    avatar: avatar,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Name"
                defaultValue={user.name}
                name="Name"
                autoFocus
                onChange={(e) => {
                  setUser({
                    ...user,
                    convertValues: user.convertValues,
                    name: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                defaultValue={user!.email}
                name="email"
                autoComplete="email"
                onChange={(e) => {
                  setUser({
                    ...user,
                    convertValues: user.convertValues,
                    email: e.target.value,
                  });
                }}
              />
            </Grid>
            <Button
              variant="contained"
              fullWidth
              style={{ margin: "20px" }}
              color="success"
              onClick={() => {
                apiSave(user.id, user, () => {
                  getUserSettings().then((v: User) => {
                    window.location.reload();
                  });
                });
              }}
            >
              Save
            </Button>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper elevation={9} sx={{ mt: 1 }} style={{ padding: "20px" }}>
          <Grid container spacing={2}>
            <Password email={user && user.email} />
          </Grid>
        </Paper>
        <Paper elevation={9} sx={{ mt: 1 }} style={{ padding: "20px" }}>
          <Container maxWidth="xl">
            {user && user.is_two_factor_auth_enabled ? (
              <Button
                color="error"
                startIcon={<RemoveCircle />}
                onClick={async () => {
                  await delete2Fa();
                  getUserSettings().then((v: User) => {
                    setUser(v);
                  });
                }}
              >
                Disable two factor authintification
              </Button>
            ) : qrCode === "" ? (
              <Button
                color="success"
                startIcon={<SecurityOutlined />}
                onClick={async () => {
                  var qr = await getQR();
                  console.log("qr", qr);
                  setQRCode(qr);
                }}
              >
                Enable two factor authintification
              </Button>
            ) : (
              ConfirmOTP(qrCode, setCode, code, setUser)
            )}
          </Container>
        </Paper>
      </Grid>
    </Grid>
  );
}

function ConfirmOTP(qrCode: any, setCode: any, code: any, setUser: any) {
  return (
    <FormControl>
      <FormLabel id="scan-qr-code-label">Scan QR code:</FormLabel>
      <img src={qrCode} />
      <FormLabel id="confirm-code-label" style={{ marginTop: "20px" }}>
        Confirm code:
      </FormLabel>
      <OTPInput
        autoFocus
        isNumberInput
        length={6}
        className="otpContainer"
        inputClassName="otpInput"
        onChangeOTP={(otp) => {
          setCode(otp);
        }}
      />
      <Button
        onClick={async () => {
          await confirmCode(code);
          getUserSettings().then((v: User) => {
            setUser(v);
          });
        }}
      >
        Confirm
      </Button>
    </FormControl>
  );
}
