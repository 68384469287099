// Libraries
import React, { useState, useEffect, useCallback, useMemo } from "react";
import PropTypes, { bool } from "prop-types";
import { useForm } from "react-hook-form";
import { object } from "yup";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import merge from "lodash/merge";
import reduce from "lodash/reduce";
import update from "immutability-helper";
import { Droppable, Draggable } from "react-beautiful-dnd";
import BuildField from "./FieldRenderer";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  LinearProgress,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import { IFieldType } from "./generic";
import { ChevronRightOutlined } from "@mui/icons-material";
import { api, axios_external, getAuthToken } from "../../services/api";
import {
  ExecDevRequest,
  ExecRequest,
  TemplateExecResp,
} from "../../structs/gen/template";
import { Document } from "../../structs/gen/document";
import { Config } from "./Config";
import { ExecuteOnEnum } from "../../structs/gen/enums/template";

async function templateExec(
  id: string | undefined,
  data: ExecRequest,
): Promise<TemplateExecResp> {
  let resp = await api.post(`/api/template/${id}/exec`, data);
  return resp.data;
}

async function templateExecDev(
  data: ExecDevRequest,
): Promise<TemplateExecResp> {
  let resp = await api.post(`/api/template/exec_dev`, data);
  return resp.data;
}

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? "none" : "none",
  overflow: "scroll",
});

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  // change background colour if dragging
  background: isDragging ? "none" : "none",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const modalStyle = {
  // position: "absolute" as "absolute",
  // top: "50%",
  // left: "50%",
  // transform: "translate(-50%, -50%)",

  position: "absolute" as "absolute",
  marginLeft: "auto",
  marginRight: "auto",
  left: 0,
  right: 0,
  textAlign: "center",
  marginTop: "25px",
  marginBottom: "25px",

  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface BuildFieldsParams {
  templateId: string;
  config: any;
  errors?: any;
  onUpdated: (
    data: any | undefined,
    config: any | undefined,
    name: string | undefined,
    user_access: string[] | undefined,
  ) => void;
  document: Document;
  isDev: boolean;
  formula?: string;
  setErrors?: (newData: any) => void;
  isConstructorMode: boolean;
  onExecuting?: (isExecuting: boolean) => void;
  executeOn: string[];
}

const BuildFields = ({
  templateId,
  config,
  errors,
  onUpdated,
  document,
  isDev = false,
  formula,
  setErrors,
  isConstructorMode = true,
  onExecuting,
  executeOn,
}: BuildFieldsParams) => {
  console.log("BuildFields", document.data);
  const [isDraggingAnyCard, setIsDraggingAnyCard] = useState(false);
  const [isInit, setIsInit] = useState(true);
  const [isExecuting, setIsExecuting] = useState(false);
  const [isShowingLogs, setIsShowingLogs] = useState(false);
  const [logs, setLogs] = useState<string[]>([]);

  function setIsExecutingFn(isExec: boolean): void {
    setIsExecuting(isExec);
    if (onExecuting) {
      onExecuting(isExec);
    }
  }

  async function onChange(modifiedData: any): Promise<void> {
    try {
      var result: TemplateExecResp;
      setIsExecutingFn(true);
      if (isDev) {
        if (formula !== undefined) {
          result = await templateExecDev({
            formula,
            config,
            data: modifiedData,
          });
        } else {
          return;
        }
      } else {
        result = await templateExec(templateId, {
          data: modifiedData,
          key: document.key,
          user_access: document.user_access,
        });
      }
      console.log("Exec result", result);
      setIsExecutingFn(false);
      onUpdated(result.data, result.config, result.key, result.user_access);
      if (result.logs.length > 1) {
        //0-info:  [{type: "info", out: ["new VM"]}]
        var errorLogs = [];
        for (var logID in result.logs) {
          var log = result.logs[logID];
          if (log.type !== "info") {
            for (var outID in log.out) {
              errorLogs.push(log.out[outID]);
            }
          }
        }

        if (errorLogs.length > 0) {
          setLogs(errorLogs);
          setIsShowingLogs(true);
        }
      }
      console.log("exec logs", result.logs);
    } catch (e) {
      setIsExecutingFn(false);
      console.log(e);
    }
    // if (
    //   errors &&
    //   Object.keys(errors).length > 0 &&
    //   Object.getPrototypeOf(errors) === Object.prototype
    // ) {
    if (setErrors !== undefined) {
      console.log("validateDocument");
      validateDocument({
        config: config,
        data: modifiedData,
        setErrors: setErrors,
      });
    }
    //}
  }

  if (isInit) {
    if (executeOn.includes(ExecuteOnEnum.on_init)) {
      onChange(document.data);
    }
    setIsInit(false);
  }

  var i = 0;

  let fields = map(config, (fieldConfig) => {
    const key = fieldConfig.key;
    let value = document.data && document.data[key];

    const field_errors = errors && errors[key] && errors[key];

    let item: React.ReactNode =
      fieldConfig.hasOwnProperty("is_visible") &&
      fieldConfig.is_visible === false &&
      !isConstructorMode ? (
        <></>
      ) : (
        <BuildField
          config={{
            ...fieldConfig,
            is_disabled: isConstructorMode ? true : fieldConfig.is_disabled, //if we are updating configuration -> disable all elements
          }}
          key={key}
          //errors={formErrors}
          value={value}
          onChange={async (k, v) => {
            let modifiedData = {
              ...document.data,
            };
            modifiedData[k] = v;

            onChange(modifiedData);
          }}
          errors={field_errors}
        />
      );

    if (isConstructorMode) {
      let index = i;
      i++;
      return (
        <Draggable key={key} draggableId={key} index={index}>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={getItemStyle(
                snapshot.isDragging,
                provided.draggableProps.style,
              )}
            >
              <Config
                key={key}
                config={fieldConfig}
                id={key}
                index={index}
                // moveCard={(dragIndex, hoverIndex) => {
                //   moveCard(config, dragIndex, hoverIndex);
                //   setIsDraggingAnyCard(false);
                // }}
                isDraggingAnyCard={isDraggingAnyCard}
                onDragging={(v) => {
                  setIsDraggingAnyCard(v);
                }}
                onUpdate={(
                  key: string,
                  newFieldConfig: IFieldType | undefined,
                ) => {
                  console.log("onUpdate", key, newFieldConfig, config);
                  if (newFieldConfig) {
                    config = config.map((f: IFieldType) => {
                      if (f.key === key) {
                        f = newFieldConfig;
                      }
                      return f;
                    });
                  } else {
                    //Delete field
                    config = config.filter((f: IFieldType) => f.key !== key);
                  }
                  onUpdated(undefined, config, undefined, undefined);
                }}
              >
                {item}
              </Config>
            </div>
          )}
        </Draggable>
      );
    }
    if (isDev) {
      return <ShowTextOnHover text={"Key: " + key}>{item}</ShowTextOnHover>;
    }
    return item;
  });

  console.log("fields", fields);

  if (isConstructorMode && fields) {
    return (
      <>
        <Droppable droppableId="items">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {fields}
              {provided.placeholder}
              <Box height={20}></Box>
              {!config || config.length < 1 ? (
                <Box height={100}>
                  <Typography
                    variant="h4"
                    textAlign={"center"}
                    color={"#bdbdbd"}
                  >
                    Drag and Drop a field
                    <br />
                    from the list
                    <ChevronRightOutlined
                      color="disabled"
                      sx={{ fontSize: 30 }}
                    />
                  </Typography>
                </Box>
              ) : (
                <></>
              )}
            </div>
          )}
        </Droppable>
      </>
    );
  }

  return (
    <>
      {isShowingLogs && (
        <Modal
          open={isShowingLogs}
          onClose={() => {
            setLogs([]);
            setIsShowingLogs(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            {logs.map((e) => {
              return <Typography>{e}</Typography>;
            })}
          </Box>
        </Modal>
      )}
      {fields}
      {isExecuting && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress color="success" />
        </Box>
      )}
    </>
  );
};

const ShowTextOnHover = ({
  text,
  children,
}: {
  text: string;
  children: any;
}) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  return (
    <div onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
      {isHovering && <Typography variant="caption">{text}</Typography>}
      {children}
    </div>
  );
};

export function validateDocument({
  config,
  data,
  setErrors,
}: {
  config: any;
  data: any;
  setErrors: any;
}): boolean {
  console.log("documentData", data);
  var isValid = true;
  var errors: any = {};
  config.map((f: any) => {
    if (f.is_required && (f.is_visible || f.is_visible === undefined)) {
      var isKeyExists = false;
      for (var k in data) {
        if (k === f.key) {
          isKeyExists = true;
          break;
        }
      }
      if (!isKeyExists) {
        isValid = false;
        errors[f.key] = ["Please provide a value"];
      } else {
        if (data[f.key] === undefined) {
          isValid = false;
          errors[f.key] = ["Value is not defined"];
        }
        if (data[f.key] === "") {
          isValid = false;
          errors[f.key] = ["Value is empty"];
        }
        if (Array.isArray(data[f.key])) {
          if (data[f.key].length === 0) {
            isValid = false;
            errors[f.key] = ["Incorrect value"];
          }
        }
      }
    }
  });
  setErrors({
    ...errors,
  });
  console.log("errors", errors);
  return isValid;
}

export default React.memo(BuildFields);
