import * as React from "react";
import Paper from "@mui/material/Paper";
import { api } from "../../services/api";
import {
  Avatar,
  AvatarGroup,
  Badge,
  BadgeProps,
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Template } from "../../structs/gen/template";
import BuildFields from "../../components/field/BuildFields";
import { DOCUMENT_URL } from "../../structs/urls";
import {
  DeltaDocumentHistory,
  Document,
  DocumentHistory,
  DocumentHistoryListResponse,
} from "../../structs/gen/document";
import { ChevronLeft, ChevronRight, Edit, Image } from "@mui/icons-material";
import { User, UserListResponse } from "../../structs/gen/user";
import { convertUTCDateToLocalDate, fToNow } from "../../utils/formatTime";
import { createDefaultDocument } from "../../structs/document";
import Access from "./Access";

async function apiGetTemplate(id: string, callback: any) {
  let resp = await api.get(`/api/template/${id}`);
  callback(resp.data);
}

async function apiGet(id: string, callback: any) {
  let resp = await api.get(`/api/document/${id}`);
  callback(resp.data);
}

async function apiSave(id: string | undefined, data: Document, callback: any) {
  if (id) {
    let resp = await api.post(`/api/document/${id}`, data);
    callback(resp.data);
  } else {
    let resp = await api.post(`/api/document/`, data);
    callback(resp.data);
  }
}

const DrawerHeader = styled("div")(({ theme }) => ({
  marginTop: "100px",
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

export default function DocumentEdit() {
  const theme = useTheme();
  const navigate = useNavigate();
  const params = useParams();
  const templateID = params["template_id"];
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const id = params["id"];
  const [template, setTemplate] = React.useState<Template | undefined>(
    undefined,
  );
  const [document, setDocument] = React.useState<Document | undefined>(
    undefined,
  );
  const [loading, setLoading] = React.useState(true);
  const [errors, setErrors] = React.useState<any>({});
  const [execError, setExecError] = React.useState<string | undefined>();
  const [isSubmitted, setIsSubmitted] = React.useState(false);

  if (loading) {
    setLoading(false);
    apiGetTemplate(String(templateID!), (resp: Template) => {
      setTemplate(resp);
      if (!id || id === "") {
        setDocument(createDefaultDocument(resp));
      }
    });
    if (id && String(id) !== "") {
      apiGet(String(id), (resp: Document) => {
        setDocument({
          ...resp,
          convertValues: resp.convertValues,
        });
      });
    }
  }

  if (!template || !document) {
    return <CircularProgress />;
  }

  return (
    <>
      {!isMenuOpen && (
        <Button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          style={{ right: 0, position: "absolute" }}
          startIcon={<ChevronLeft />}
        >
          Info
        </Button>
      )}
      <Box display="flex" justifyContent="center">
        <Paper
          elevation={9}
          sx={{ mt: 1 }}
          style={{ padding: "20px", width: "700px" }}
        >
          <BuildFields
            templateId={template.id}
            config={template.data}
            onUpdated={(data, config, key, user_access) => {
              if (config != null) {
                setTemplate({
                  ...template,
                  data: config,
                });
              }
              let newData: any = {};
              let isUpdated: boolean = false;
              if (data != null) {
                newData.data = data;
                isUpdated = true;
              }
              if (key != null) {
                console.log("key", key);
                newData.key = key;
                isUpdated = true;
              }
              if (user_access != null) {
                newData.user_access = user_access;
                isUpdated = true;
              }
              if (isUpdated) {
                setDocument({
                  ...document,
                  convertValues: document.convertValues,
                  ...newData,
                });
              }
            }}
            formula={template.formula}
            setErrors={setErrors}
            document={document}
            errors={errors}
            isDev={false}
            isConstructorMode={false}
            onExecuting={(isSubmitted) => {
              setIsSubmitted(isSubmitted);
            }}
            executeOn={template.execute_on}
          />
          <Button
            variant="contained"
            fullWidth
            color="success"
            disabled={isSubmitted}
            style={{ marginTop: "20px" }}
            onClick={() => {
              if (errors.length === undefined || errors.length === 0) {
                apiSave(document.id, document, (resp: any) => {
                  navigate(DOCUMENT_URL + templateID);
                });
              }
            }}
          >
            Save
          </Button>
        </Paper>
      </Box>
      <Drawer
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(!isMenuOpen)}
        variant="persistent"
        PaperProps={{
          sx: {
            width: 300,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: 300,
              boxSizing: "border-box",
            },
          },
        }}
        anchor={"right"}
      >
        <DrawerHeader>
          <Button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            startIcon={<ChevronRight />}
          >
            Information
          </Button>
        </DrawerHeader>
        <Divider />
        {document.id && template && (
          <History documentID={document.id} template={template} />
        )}
        {template.is_access_restricted && (
          <Access
            userAccess={document.user_access}
            onChange={(ua) => {
              setDocument({
                ...document,
                convertValues: document.convertValues,
                user_access: ua,
              });
            }}
          />
        )}
      </Drawer>
    </>
  );
}

async function historyList(
  documentID: string,
): Promise<DocumentHistoryListResponse> {
  let resp = await api.post(`/api/document/${documentID}/history/list`, {
    offset: 0,
    next: 10,
    filters: [],
  });
  return resp.data;
}

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 10,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  minWidth: "50%",
  maxHeight: "70%",
};

function History({
  documentID,
  template,
}: {
  documentID: string;
  template: Template;
}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [fullDocumentHistory, setFullDocumentHistory] = React.useState<
    DocumentHistory | undefined
  >(undefined);
  const [rows, setRows] = React.useState<DocumentHistory[]>([]);
  const [loading, setLoading] = React.useState(true);

  if (loading) {
    setLoading(false);
    historyList(documentID).then((list) => setRows(list.rows));
  }

  console.log("template.data", template.data);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {fullDocumentHistory && (
            <div>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {fullDocumentHistory.user_name}
              </Typography>
              <Typography
                id="modal-modal-title"
                variant="subtitle1"
                component="h2"
              >
                {fullDocumentHistory.created_at &&
                  fToNow(new Date(fullDocumentHistory.created_at.toString()))}
              </Typography>
              {fullDocumentHistory.delta &&
                DisplayDiff(template, fullDocumentHistory.delta)}
            </div>
          )}
        </Box>
      </Modal>
      <Card sx={{ m: 1, p: 1 }}>
        <CardHeader title="History" />
        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            position: "relative",
            overflow: "auto",
            maxHeight: 300,
            "& ul": { padding: 0 },
          }}
        >
          {rows.map((r) => {
            return (
              <>
                <StyledBadge badgeContent={r.delta.length} color="secondary">
                  <ListItem>
                    <ListItemAvatar>
                      {r.user_avatar ? (
                        <Avatar alt={r.user_name} src={r.user_avatar} />
                      ) : (
                        <Avatar>
                          <Image />
                        </Avatar>
                      )}
                    </ListItemAvatar>
                    <ListItemText
                      primary={r.user_name}
                      onClick={() => {
                        setFullDocumentHistory(r);
                        handleOpen();
                      }}
                      secondary={
                        <React.Fragment>
                          {/* <Typography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          ></Typography>
                          <br /> */}
                          {/* {r.created_at} */}
                          {r.created_at &&
                            fToNow(new Date(r.created_at.toString()))}
                          {/*  */}
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </StyledBadge>
                <Divider variant="inset" component="li" />
              </>
            );
          })}
        </List>
      </Card>
    </>
  );
}

function DisplayDiff(template: Template, rows: DeltaDocumentHistory[]) {
  return (
    <TableContainer component={Paper} style={{ height: 300 }}>
      <Table sx={{ minWidth: 250 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Field</TableCell>
            <TableCell align="right">Old value</TableCell>
            <TableCell align="right">New value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.Position && getTemplateFileName(template, row.Position)}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.Position && getTemplateFileName(template, row.Position)}
              </TableCell>
              <TableCell align="right">
                {row.OldValue && row.OldValue}
              </TableCell>
              <TableCell align="right">
                {row.NewValue && row.NewValue}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function getTemplateFileName(template: Template, key: string) {
  var d = template.data.filter((t: any) => t.key === key);
  if (d && d[0] && d[0].label) {
    return d[0].label;
  }
  return "";
}
