import { Delete, Upload } from "@mui/icons-material";
import { Avatar, Button, IconButton, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { createRef, useState } from "react";
import { convertBase64 } from "../../utils/avatar";
import { api } from "../../services/api";
import { File as FileUploaded } from "../../structs/gen/file";

function AvatarUpload({
  src,
  onChange,
}: {
  src: string | undefined;
  onChange: (src: string | undefined) => void;
}) {
  const [image, _setImage] = useState(src);
  const inputFileRef = createRef<any>();

  const cleanup = () => {
    if (image) {
      URL.revokeObjectURL(image);
    }
    inputFileRef.current.value = null;
  };

  const setImage = (newImage: any) => {
    if (image) {
      cleanup();
    }
    _setImage(newImage);
  };

  return (
    <div style={{ textAlign: "center" }}>
      <Avatar
        alt="Avatar"
        src={image !== "" ? image : "/static/img/avatars/default-profile.svg"}
        imgProps={{
          style: {
            maxHeight: "100%",
            maxWidth: "100%",
            objectFit: "cover",
          },
        }}
        style={{
          width: "120px",
          height: "120px",
        }}
      />
      <input
        ref={inputFileRef}
        accept="image/*"
        hidden
        id="avatar-image-upload"
        type="file"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const newImage = e.target?.files?.[0];
          console.log("newImage", newImage);
          if (newImage) {
            let formData = new FormData();
            formData.append("file", newImage);
            api.post("/api/file/", formData).then((res: any) => {
              var f: FileUploaded = res.data;
              setImage(f.url);
              onChange(f.url);
            });
          }
        }}
      />
      <label htmlFor="avatar-image-upload">
        <Button
          variant="contained"
          color="primary"
          component="span"
          onClick={(event: any) => {
            if (image) {
              event.preventDefault();
              setImage(null);
              onChange(undefined);
            }
          }}
        >
          {image ? <Delete /> : <Upload />}
          {image ? "Remove image" : "Upload image"}
        </Button>
      </label>
      <Typography variant="caption" display="block" gutterBottom>
        For best results, an image of at least 128 x 128 pixels in .png format
      </Typography>
    </div>
  );
}

export default AvatarUpload;
