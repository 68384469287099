import { Save } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import MultiLanguageLabel from "../../MultiLanguageLabel";
import { LabelFieldTypeObject } from "./label_field_model";

export default function LabelConfig({
  config,
  onSave,
}: {
  config: LabelFieldTypeObject;
  onSave: (newConfig: LabelFieldTypeObject) => void;
}) {
  const [newConfig, setNewConfig] = useState(config);

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      <div>
        <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <TextField
            fullWidth
            label="Font size"
            value={newConfig.font_size}
            type={"number"}
            onChange={(e) => {
              setNewConfig({
                ...newConfig,
                font_size: +e.target.value,
              });
            }}
          />
        </FormControl>
        <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <MultiLanguageLabel
            value={newConfig.label}
            onChange={(v) => {
              setNewConfig({
                ...newConfig,
                label: v,
              });
            }}
            multiValue={newConfig.label_intl}
            onChangeMultiLabel={(v) => {
              setNewConfig({
                ...newConfig,
                label_intl: v,
              });
            }}
          />
        </FormControl>

        <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <FormControlLabel
            control={
              <Switch
                defaultChecked={newConfig.is_visible}
                value={newConfig.is_visible}
                onChange={(e) => {
                  setNewConfig({
                    ...newConfig,
                    is_visible: e.target.checked,
                  });
                }}
              />
            }
            label="Is visible"
          />
        </FormControl>

        <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <FormControlLabel
            control={
              <Switch
                defaultChecked={newConfig.is_bold}
                value={newConfig.is_bold}
                onChange={(e) => {
                  setNewConfig({
                    ...newConfig,
                    is_bold: e.target.checked,
                  });
                }}
              />
            }
            label="Is bold"
          />
        </FormControl>
        <Button
          onClick={() => {
            console.log("newConfig", newConfig);
            onSave(newConfig);
          }}
          startIcon={<Save />}
          color={"success"}
        >
          Save
        </Button>
      </div>
    </Box>
  );
}
