import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { SignatureFieldTypeObject } from "./signature_field_model";
import SignatureCanvas from "react-signature-canvas";
import { string } from "prop-types";
import { Clear, Save } from "@mui/icons-material";

const SignatureField = ({
  id,
  config,
  onChange,
  value,
  errors,
}: {
  id: string;
  config: SignatureFieldTypeObject;
  onChange: (value: string) => void;
  value: string;
  errors: string[];
}) => {
  const { i18n } = useTranslation();
  const [sigPad, setSigPad] = useState<any>({});

  return (
    <FormControl
      fullWidth
      disabled={config.is_disabled}
      required={config.is_required}
      error={errors && errors.length > 0}
    >
      <FormLabel component="legend">
        {(config.label_intl &&
          config.label_intl[i18n.language] &&
          config.label_intl[i18n.language]) ||
          config.label}
      </FormLabel>
      {/* <button className={styles.buttons} onClick={this.clear}>
          Clear
        </button>
        <button className={styles.buttons} onClick={this.trim}>
          Trim
        </button> */}
      <Divider />
      <SignatureCanvas
        velocityFilterWeight={1}
        ref={(ref) => {
          if (ref && value) {
            ref.fromDataURL(value);
          }
          setSigPad(ref);
        }}
        penColor="black"
        //backgroundColor={"#f7f7f7"}
        canvasProps={{
          height: 170,
          width: config.is_disabled ? 500 : undefined,
        }}
        clearOnResize={false}
        onEnd={(e) => {
          var v = sigPad.toDataURL("image/png");
          console.log(v);
          onChange(sigPad.toDataURL("image/png"));
        }}
      />
      <Divider />
      <Button
        onClick={() => {
          sigPad.clear();
          onChange("");
        }}
        startIcon={<Clear />}
        color="error"
        disabled={config.is_disabled}
      >
        Clear
      </Button>
      {/* {errors && errors[name] && (
        <FormHelperText error>{errors[name].message}</FormHelperText>
      )} */}
    </FormControl>
  );
};

export default React.memo(SignatureField);
