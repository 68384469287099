import * as React from "react";
import { useRef, useState } from "react";
// material
import { alpha } from "@mui/material/styles";
import { Box, MenuItem, Stack, IconButton } from "@mui/material";
// components
import MenuPopover from "./menu/MenuPopover";
import { api } from "../services/api";
import "./../i18n";
import { useTranslation } from "react-i18next";
import { availableLanguages } from "./../i18n";

// ----------------------------------------------------------------------

const LANGS = [
  {
    value: "en",
    label: "English",
    icon: "/static/icons/ic_flag_en.svg",
  },
  {
    value: "ua",
    label: "Ukrainian",
    icon: "/static/icons/ic_flag_ua.svg",
  },
  {
    value: "it",
    label: "Italian",
    icon: "/static/icons/ic_flag_it.svg",
  },
  {
    value: "de",
    label: "German",
    icon: "/static/icons/ic_flag_de.svg",
  },
  {
    value: "fr",
    label: "French",
    icon: "/static/icons/ic_flag_fr.svg",
  },
];

// ----------------------------------------------------------------------

async function apiSaveLang(lang: string) {
  let resp = await api.post(`/api/language`, {
    language: lang,
  });
  console.log("resp", resp);
  return;
}

export default function LanguagePopover({
  lang,
}: {
  lang: string | undefined;
}) {
  const [isLoading, setIsLoading] = useState(true);
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [currentLang, setCurrentLang] = useState(0);
  const { t, i18n } = useTranslation();
  console.log("current language", i18n.language);
  if (isLoading) {
    setIsLoading(false);
    for (var i = 0; i < LANGS.length; i++) {
      if (LANGS[i].value === i18n.language) {
        if (currentLang !== i) {
          setCurrentLang(i);
        }
      }
    }
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.focusOpacity,
              ),
          }),
        }}
      >
        <img src={LANGS[currentLang].icon} alt={LANGS[currentLang].label} />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 180,
          "& .MuiMenuItem-root": {
            px: 1,
            typography: "body2",
            borderRadius: 0.75,
          },
        }}
      >
        <Stack spacing={0.75}>
          {LANGS.filter((l) => availableLanguages.indexOf(l.value) > -1).map(
            (option, i) => (
              <MenuItem
                key={option.value}
                selected={option.value === LANGS[currentLang].value}
                onClick={async () => {
                  await apiSaveLang(option.value);
                  setCurrentLang(i);
                  i18n.changeLanguage(option.value);
                  handleClose();
                }}
              >
                <Box
                  component="img"
                  alt={option.label}
                  src={option.icon}
                  sx={{ width: 28, mr: 2 }}
                />

                {option.label}
              </MenuItem>
            ),
          )}
        </Stack>
      </MenuPopover>
    </>
  );
}
