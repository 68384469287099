import { Template } from "./gen/template";
import { Document } from "./gen/document";
import { FieldTypeEnum } from "./gen/enums/template";
import { fDateTimeToGolang } from "../utils/formatTime";
import { getFieldValue } from "../utils/field";

export function createDefaultDocument(t: Template): Document {
    var doc:Document = {
        user_access: [],
        data: {},
        id: "",
        convertValues: new Document().convertValues,
        key: "",
        template_id: t.id,
        created_at: fDateTimeToGolang(new Date()),
        updated_at: fDateTimeToGolang(new Date()),
        template_data: {},
        template_name: "",
    }

    for(var id in t.data){
        var field = t.data[id];
        if (field.type === FieldTypeEnum.text){
            if(field.default_value){
                doc.data[field.key] = field.default_value;
            }
        }
    }
    
    return doc;
}

export function getDocumentDataAsText(data: any, template_data: any): string[] {
  let fields: string[] = [];
  for (var d in data) {
    if (!data[d]) continue;
    var field = "";
    try {
      for (var t in template_data) {
        if (template_data[t].key === d) {
          if (template_data[t].label) {
            field += template_data[t].label + ": ";
            break;
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
    try {
      var value = getFieldValue(d, data[d], template_data);
      if (value === "") continue;
      field += value;
    } catch (e) {
      console.log(e);
    }
    fields.push(field);
  }
  return fields;
}
