/* Do not change, this code is generated from Golang structs */


export class Time {


    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);

    }
}
export class Document {
    id: string;
    key: string;
    data: any;
    template_id: string;
    user_access: string[];
    created_at: Time;
    updated_at: Time;
    template_data: any;
    template_name: string;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.id = source["id"];
        this.key = source["key"];
        this.data = source["data"];
        this.template_id = source["template_id"];
        this.user_access = source["user_access"];
        this.created_at = this.convertValues(source["created_at"], Time);
        this.updated_at = this.convertValues(source["updated_at"], Time);
        this.template_data = source["template_data"];
        this.template_name = source["template_name"];
    }

	convertValues(a: any, classs: any, asMap: boolean = false): any {
	    if (!a) {
	        return a;
	    }
	    if (a.slice) {
	        return (a as any[]).map(elem => this.convertValues(elem, classs));
	    } else if ("object" === typeof a) {
	        if (asMap) {
	            for (const key of Object.keys(a)) {
	                a[key] = new classs(a[key]);
	            }
	            return a;
	        }
	        return new classs(a);
	    }
	    return a;
	}
}
export class DocumentListResponse {
    count: number;
    rows: Document[];

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.count = source["count"];
        this.rows = this.convertValues(source["rows"], Document);
    }

	convertValues(a: any, classs: any, asMap: boolean = false): any {
	    if (!a) {
	        return a;
	    }
	    if (a.slice) {
	        return (a as any[]).map(elem => this.convertValues(elem, classs));
	    } else if ("object" === typeof a) {
	        if (asMap) {
	            for (const key of Object.keys(a)) {
	                a[key] = new classs(a[key]);
	            }
	            return a;
	        }
	        return new classs(a);
	    }
	    return a;
	}
}
export class DeltaDocumentHistory {
    NewValue?: string;
    OldValue?: string;
    Position: string;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.NewValue = source["NewValue"];
        this.OldValue = source["OldValue"];
        this.Position = source["Position"];
    }
}
export class DocumentHistory {
    delta: DeltaDocumentHistory[];
    created_at: Time;
    user_name: string;
    user_avatar?: string;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.delta = this.convertValues(source["delta"], DeltaDocumentHistory);
        this.created_at = this.convertValues(source["created_at"], Time);
        this.user_name = source["user_name"];
        this.user_avatar = source["user_avatar"];
    }

	convertValues(a: any, classs: any, asMap: boolean = false): any {
	    if (!a) {
	        return a;
	    }
	    if (a.slice) {
	        return (a as any[]).map(elem => this.convertValues(elem, classs));
	    } else if ("object" === typeof a) {
	        if (asMap) {
	            for (const key of Object.keys(a)) {
	                a[key] = new classs(a[key]);
	            }
	            return a;
	        }
	        return new classs(a);
	    }
	    return a;
	}
}
export class DocumentHistoryListResponse {
    count: number;
    rows: DocumentHistory[];

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.count = source["count"];
        this.rows = this.convertValues(source["rows"], DocumentHistory);
    }

	convertValues(a: any, classs: any, asMap: boolean = false): any {
	    if (!a) {
	        return a;
	    }
	    if (a.slice) {
	        return (a as any[]).map(elem => this.convertValues(elem, classs));
	    } else if ("object" === typeof a) {
	        if (asMap) {
	            for (const key of Object.keys(a)) {
	                a[key] = new classs(a[key]);
	            }
	            return a;
	        }
	        return new classs(a);
	    }
	    return a;
	}
}
export class BulkInsertDocuments {
    template_id: string;
    data_array: any[];

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.template_id = source["template_id"];
        this.data_array = source["data_array"];
    }
}