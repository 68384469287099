import * as React from "react";
import Paper from "@mui/material/Paper";
import { api } from "../../services/api";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CircularProgress,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  styled,
  Switch,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Template } from "../../structs/gen/template";
import BuildFields from "../../components/field/BuildFields";

import { TEMPLATE_LIST_URL } from "../../structs/urls";
import { MenuFieldType } from "../../structs/field_type";
import {
  ChevronLeft,
  ChevronRight,
  Code,
  ContentCopy,
  DriveFileRenameOutline,
  LineStyle,
  LinkRounded,
  PanToolAlt,
  Redo,
  Undo,
} from "@mui/icons-material";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import useUndo from "../../services/undo";
import { useTranslation } from "react-i18next";
import {
  ChoiceTypeEnum,
  ExecuteOnEnum,
  FieldTypeEnum,
  InputValueTypeEnum,
  SourceTypeEnum,
} from "../../structs/gen/enums/template";
import { useCallbackPrompt } from "../../hooks/useCallbackPrompt";
import DialogBox from "../../components/DialogBox.component";
import { useStateCallback } from "../../hooks/useStateCallback";
import { fDateTimeToGolang } from "../../utils/formatTime";
import BuildField from "../../components/field/FieldRenderer";
import CodeEditor from "../../components/edit_template/code_editor";

import AceEditor from "react-ace";
// import mode-<language> , this imports the style and colors for the selected language.
import "ace-builds/src-noconflict/mode-javascript";
// there are many themes to import, I liked monokai.
import "ace-builds/src-noconflict/theme-gruvbox_dark_hard";
// this is an optional import just improved the interaction.
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/ext-beautify";
import { reorderList } from "../../utils/order";
import { FieldTypes } from "./TemplateFieldTypes";
import { createDefaultDocument } from "../../structs/document";
import { Document } from "../../structs/gen/document";

async function apiGet(id: string, callback: any) {
  let resp = await api.get(`/api/template/${id}`);
  callback(resp.data);
}

async function apiSave(id: string | undefined, data: Template, callback: any) {
  let resp = await api.post(`/api/template/${id}`, data);
  callback(resp.data);
}

const DrawerHeader = styled("div")(({ theme }) => ({
  marginTop: "100px",
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  margin: theme.spacing(2),
  color: theme.palette.text.secondary,
}));

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? "lightblue" : "none",
  overflow: "scroll",
});

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  // change background colour if dragging
  background: isDragging ? "none" : "none",

  // styles we need to apply on draggables
  ...draggableStyle,
});

// options: {
//   type: "select",
//   label: "Options",
//   icon: CollectionsBookmark,
//   items: [
//     { id: 1, name: "Yes" },
//     { id: 2, name: "No" },
//   ],
//   validation: yup.string().required(),
//   tooltip: "Yes/No Option", // --> can be used in any input
// },

export default function Templates() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  //const divRef = React.useRef<null | HTMLDivElement>(null);
  const params = useParams();
  const [template, setTemplate] = React.useState<Template | undefined>(
    undefined,
  );
  const [
    currentFieldsState,
    {
      set: setFields,
      reset: restFields,
      undo: undoFieldsChanges,
      redo: redoFieldsChanges,
      canUndo,
      canRedo,
    },
  ] = useUndo([]);
  const { present: presentFields } = currentFieldsState;
  console.log("test", presentFields);
  const [loading, setLoading] = React.useState(true);
  const [isFieldTypeOpen, setFieldTypeOpen] = React.useState(true);
  const [isFieldsEditing, setIsFieldsEditing] = React.useState(true);
  const fieldTypes = FieldTypes();
  const [showDialog, setShowDialog] = useStateCallback(false);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);

  const onSetTemplate = (t: Template) => {
    setShowDialog(true);
    setTemplate(t);
  };

  const [document, setDocument] = React.useState<Document>(new Document());

  // const [{ isOver }, dropRef] = useDrop({
  //   accept: "pet",
  //   drop: (item: MenuFieldType) => {
  //     console.log("item", item);
  //     let key = item.type + "_" + (Math.random() + 1).toString(36).substring(7);
  //     let config: any = {
  //       ...item.config,
  //       key: key,
  //     };
  //     console.log("template", template);
  //     onSetTemplate({
  //       ...template!,
  //       data: [...template!.data, config],
  //     });
  //     divRef!.current!.scrollIntoView({
  //       // top: divRef!.current!.scrollHeight,
  //       behavior: "smooth",
  //       block: "end",
  //     });
  //   },
  //   collect: (monitor: any) => ({
  //     isOver: monitor.isOver(),
  //   }),
  // });

  if (loading) {
    setLoading(false);
    if (params.id && String(params.id) !== "") {
      apiGet(String(params.id), (resp: Template) => {
        setTemplate(resp);
        restFields(resp.data);
      });
      return <CircularProgress />;
    } else {
      let newTemplate = {
        id: "",
        name: "New Template",
        is_public: false,
        is_access_restricted: false,
        data: [],
        formula: `async (data, config, options) => {
    return { data, config };
}`,
        major_version: 0,
        minor_version: 1,
        created_at: fDateTimeToGolang(new Date()),
        updated_at: fDateTimeToGolang(new Date()),
        execute_on: [ExecuteOnEnum.on_updated],
        formula_version: 1,
      };
      setTemplate(newTemplate);
    }
  }

  if (!template) {
    return <CircularProgress />;
  }

  const updateFields = (fields: any) => {
    setFields(fields);
    onSetTemplate({
      ...template,
      data: fields,
    });
  };

  const onDragEnd = (result: DropResult) => {
    console.log("onDragEnd", onDragEnd);
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (
      result.source.droppableId === "items" &&
      result.destination.droppableId === "items"
    ) {
      const reordered = reorderList(
        template.data,
        result.source.index,
        result.destination.index,
      );

      updateFields([...reordered!]);
    } else if (
      result.source.droppableId === "new_items" &&
      result.destination.droppableId === "items"
    ) {
      let item = fieldTypes.find((v, index) => index === result.source.index);
      if (item) {
        let key =
          item?.type + "_" + (Math.random() + 1).toString(36).substring(7);
        let config: any = {
          ...item?.config,
          key: key,
        };
        template!.data.splice(result.destination.index, 0, config);
        updateFields([...template!.data]);
      }
    }

    //   if (source.droppableId === destination.droppableId) {
    //     const items = reorder(
    //       this.getList(source.droppableId),
    //       source.index,
    //       destination.index,
    //     );

    //     let state = { items };

    //     if (source.droppableId === "droppable2") {
    //       state = { selected: items };
    //     }

    //     this.setState(state);
    //   } else {
    //     const result = move(
    //       this.getList(source.droppableId),
    //       this.getList(destination.droppableId),
    //       source,
    //       destination,
    //     );

    //     this.setState({
    //       items: result.droppable,
    //       selected: result.droppable2,
    //     });
    //   }
  };

  console.log("formData", template.data);

  // const handleSubmit = React.useCallback((formData: any) => {
  //   console.log("formData", formData);
  // }, []);

  // const handleBackAction = React.useCallback(() => {
  //   console.log("back btn clicked");
  // }, []);
  // const handleRefresh = React.useCallback(() => {
  //   console.log("back btn clicked");
  // }, []);
  return (
    <div>
      <DialogBox
        // @ts-ignore
        showDialog={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <Grid container spacing={2}>
        <Grid item xs={isFieldsEditing ? 12 : 6}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Button
              onClick={() => setFieldTypeOpen(true)}
              style={{ right: 0, position: "absolute" }}
              startIcon={<ChevronLeft />}
            >
              Fields
            </Button>
            <Box
              display="flex"
              justifyContent="center"
              style={{ marginRight: isFieldTypeOpen ? "200px" : "" }}
            >
              <Paper
                elevation={9}
                sx={{ mt: 1 }}
                style={{
                  padding: "20px",
                  maxWidth: "700px",
                }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="nae"
                  label={t("template_edit:name")}
                  name="name"
                  autoComplete="name"
                  value={template.name}
                  helperText={
                    template.id !== "" &&
                    "Version: " +
                      template.major_version +
                      "." +
                      template.minor_version
                  }
                  onChange={(v) => {
                    onSetTemplate({
                      ...template,
                      name: v.target.value,
                    });
                  }}
                  autoFocus
                />
                <FormControl fullWidth sx={{ m: 1 }}>
                  <FormControlLabel
                    control={
                      <Switch
                        defaultChecked={template.is_public}
                        value={template.is_public}
                        disabled={template.id === ""}
                        onChange={(e) => {
                          onSetTemplate({
                            ...template,
                            is_public: e.target.checked,
                          });
                        }}
                      />
                    }
                    label={
                      t("template_edit:is_public") +
                      (template.id === ""
                        ? " (Save before making template public)"
                        : "")
                    }
                  />
                  <FormHelperText>
                    {"Anyone on the internet with the link can open"}
                  </FormHelperText>
                </FormControl>
                {template.is_public && (
                  <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                    <Input
                      id="standard-adornment-amount"
                      value={`https://app.elena.sh/document/fill/${template.id}`}
                      startAdornment={
                        <LinkRounded style={{ marginRight: 10 }} />
                      }
                      endAdornment={
                        <IconButton
                          color="primary"
                          onClick={() => {
                            navigator.clipboard
                              .writeText(
                                `https://app.elena.sh/document/fill/${template.id}`,
                              )
                              .then(
                                function () {
                                  console.log(
                                    "Async: Copying to clipboard was successful!",
                                  );
                                },
                                function (err) {
                                  console.error(
                                    "Async: Could not copy text: ",
                                    err,
                                  );
                                },
                              );
                          }}
                        >
                          <ContentCopy />
                        </IconButton>
                      }
                      readOnly
                    />
                  </FormControl>
                )}
                <FormControl fullWidth sx={{ m: 1 }}>
                  <FormControlLabel
                    control={
                      <Switch
                        defaultChecked={template.is_access_restricted}
                        value={template.is_access_restricted}
                        onChange={(e) => {
                          onSetTemplate({
                            ...template,
                            is_access_restricted: e.target.checked,
                          });
                        }}
                      />
                    }
                    label={"Ristricted access for company users"}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel id="execute-on-label">Execute on</InputLabel>
                  <Select
                    labelId="execute-on-label"
                    id="execute-on"
                    multiple
                    value={template.execute_on}
                    label="Execute on"
                    onChange={(e) => {
                      const {
                        target: { value },
                      } = e;
                      onSetTemplate({
                        ...template,
                        execute_on:
                          typeof value === "string" ? value.split(",") : value,
                      });
                    }}
                  >
                    <MenuItem
                      value={ExecuteOnEnum.on_init}
                      key={ExecuteOnEnum.on_init}
                    >
                      On init
                    </MenuItem>
                    <MenuItem
                      value={ExecuteOnEnum.on_updated}
                      key={ExecuteOnEnum.on_updated}
                    >
                      On updated
                    </MenuItem>
                    <MenuItem
                      value={ExecuteOnEnum.on_save}
                      key={ExecuteOnEnum.on_save}
                    >
                      On save
                    </MenuItem>
                  </Select>
                </FormControl>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    "& > *": {
                      m: 1,
                    },
                  }}
                >
                  <ButtonGroup>
                    <Button
                      startIcon={<PanToolAlt />}
                      onClick={() => {
                        setIsFieldsEditing(true);
                        setFieldTypeOpen(true);
                      }}
                      variant={isFieldsEditing ? "contained" : "outlined"}
                    >
                      Fields
                    </Button>
                    <Button
                      endIcon={<Code />}
                      onClick={() => {
                        setIsFieldsEditing(false);
                        setFieldTypeOpen(false);
                      }}
                      variant={isFieldsEditing ? "outlined" : "contained"}
                    >
                      Code
                    </Button>
                  </ButtonGroup>
                </Box>
                {isFieldsEditing ? (
                  <>
                    <FormControl fullWidth sx={{ m: 1 }}>
                      <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="space-between"
                      >
                        <Button
                          key="undo"
                          onClick={() => {
                            console.log("was presentFields", presentFields);
                            undoFieldsChanges((newFields) => {
                              onSetTemplate({
                                ...template,
                                data: [...newFields],
                              });
                            });
                          }}
                          disabled={!canUndo}
                          aria-label="undo"
                          startIcon={<Undo />}
                          color={"primary"}
                        >
                          {t("template_edit:undo")}
                        </Button>
                        <Button
                          key="redo"
                          onClick={() => {
                            redoFieldsChanges((newFields) => {
                              onSetTemplate({
                                ...template,
                                data: [...newFields],
                              });
                            });
                          }}
                          disabled={!canRedo}
                          endIcon={<Redo />}
                          color={"primary"}
                        >
                          {t("template_edit:redo")}
                        </Button>
                      </Stack>
                    </FormControl>
                    <Card
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        padding: "10px",
                      }}
                    >
                      <BuildFields
                        templateId={template.id}
                        config={template.data}
                        onUpdated={(data, config, name, user_access) => {
                          console.log("newConfig", config);
                          if (config != null) {
                            updateFields([...config!]);
                          }
                        }}
                        isConstructorMode={true}
                        isDev={false}
                        document={document}
                        executeOn={template.execute_on}
                      />
                    </Card>
                  </>
                ) : (
                  <CodeEditor
                    originTemplate={template}
                    formula={template.formula!}
                  />
                )}
                <Button
                  variant="contained"
                  fullWidth
                  color="success"
                  onClick={() => {
                    console.log("template", template);
                    apiSave(template.id, template, () => {
                      setShowDialog(false, () => {
                        navigate(TEMPLATE_LIST_URL);
                        if (template.id === "") {
                          //FIXME: 2 second delay for display as menu updated
                          setTimeout(function () {
                            //HOTFIX
                            window.location.reload();
                          }, 1500);
                        }
                      });
                    });
                  }}
                >
                  {t("template_edit:save")}
                </Button>
              </Paper>
            </Box>
            {isFieldsEditing && (
              <Drawer
                open={isFieldTypeOpen}
                onClose={() => setFieldTypeOpen(false)}
                variant="persistent"
                PaperProps={{
                  sx: {
                    width: 200,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: {
                      width: 200,
                      boxSizing: "border-box",
                    },
                  },
                }}
                anchor={"right"}
              >
                <DrawerHeader>
                  <Button
                    onClick={() => setFieldTypeOpen(false)}
                    startIcon={
                      theme.direction === "rtl" ? (
                        <ChevronLeft />
                      ) : (
                        <ChevronRight />
                      )
                    }
                  >
                    Fields
                  </Button>
                </DrawerHeader>
                <Divider />

                <Droppable droppableId="new_items" isDropDisabled>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {fieldTypes.map((ft, index) => (
                        <Draggable
                          key={ft.name}
                          draggableId={ft.name}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style,
                              )}
                            >
                              {snapshot.isDragging ? (
                                <div style={{ maxWidth: "700px" }}>
                                  <BuildField
                                    config={ft.config}
                                    key={index + ""}
                                    onChange={(key: string, v: any) => {}}
                                    errors={[]}
                                    value={undefined}
                                  />
                                </div>
                              ) : (
                                <FieldTypeRenderer
                                  name={ft.name}
                                  icon={ft.icon}
                                  type={ft.type}
                                  config={ft.config}
                                />
                              )}
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </Drawer>
            )}
          </DragDropContext>
        </Grid>
        <Grid item xs={6}>
          {!isFieldsEditing && (
            <Paper elevation={9} sx={{ mt: 1 }} style={{ padding: "20px" }}>
              <AceEditor
                style={{
                  width: "100%",
                }}
                value={template.formula}
                mode="javascript"
                theme={
                  theme.palette.mode === "dark"
                    ? "gruvbox_dark_hard"
                    : undefined
                }
                showPrintMargin={false}
                showGutter={true}
                highlightActiveLine={true}
                onChange={(v) => {
                  console.log("v", v);
                  onSetTemplate({
                    ...template,
                    formula: v,
                  });
                }}
                setOptions={{
                  enableBasicAutocompletion: true,
                  enableLiveAutocompletion: true,
                  enableSnippets: true,
                  showLineNumbers: true,
                  tabSize: 4,
                }}
              />
            </Paper>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export const FieldTypeRenderer = (mft: MenuFieldType) => {
  const { t } = useTranslation();
  var translatedName = "No translation";
  switch (mft.type) {
    case FieldTypeEnum.choice:
      translatedName = t("template_edit:fields.choice");
      break;
    case FieldTypeEnum.label:
      translatedName = t("template_edit:fields.label");
      break;
    case FieldTypeEnum.text:
      translatedName = t("template_edit:fields.input");
      break;
    case FieldTypeEnum.datetime:
      translatedName = t("template_edit:fields.datetime");
      break;
    case FieldTypeEnum.divider:
      translatedName = t("template_edit:fields.divider");
      break;
    case FieldTypeEnum.select:
      translatedName = t("template_edit:fields.select");
      break;
    case FieldTypeEnum.media:
      translatedName = t("template_edit:fields.media");
      break;
    case FieldTypeEnum.file:
      translatedName = t("template_edit:fields.file");
      break;
    case FieldTypeEnum.signature:
      translatedName = t("template_edit:fields.signature");
      break;
    case FieldTypeEnum.user_link:
      translatedName = t("template_edit:fields.user_link");
      break;
    case FieldTypeEnum.table:
      translatedName = t("template_edit:fields.table");
      break;
  }
  return (
    <Item>
      {mft.icon && mft.icon}
      <Typography mt={2}>{translatedName}</Typography>
    </Item>
  );
};
