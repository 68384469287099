import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Template } from "../structs/gen/template";
import { fToNow } from "../utils/formatTime";
import { stringToColor } from "../utils/avatar";
import {
  Add,
  ArrowRight,
  ContentCopy,
  Create,
  Delete,
  DeleteOutline,
  Edit,
  Folder,
  Forward,
  MoreHoriz,
  Start,
} from "@mui/icons-material";
import DeleteWithConfirm from "./DeleteWithConfirm";
import PopupState, { bindToggle, bindPopper } from "material-ui-popup-state";
import {
  AvatarGroup,
  Box,
  Button,
  CardActionArea,
  ClickAwayListener,
  Divider,
  Fade,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Popover,
  Popper,
  Stack,
} from "@mui/material";
import { truncate } from "../utils/formatString";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function TemplateRenderer({
  t,
  onDelete,
  onEdit,
  onViewDocs,
  onCreateDocument,
  onMoveTemplate,
  onCopyTemplate,
}: {
  t: Template;
  onDelete: any;
  onEdit: any;
  onViewDocs: any;
  onCreateDocument: any;
  onMoveTemplate: any;
  onCopyTemplate: any;
}) {
  const [expanded, setExpanded] = React.useState(false);
  const [open, setOpen] = React.useState<any>(null);

  return (
    <Card sx={{ width: 270, height: 300 }}>
      <CardHeader
        // avatar={
        //   <Avatar sx={{ bgcolor: stringToColor(t.name) }} aria-label="recipe">
        //     {Array.from(t.name)[0]}
        //   </Avatar>
        // }
        title={
          <Typography variant="h6" style={{ width: 200 }}>
            {t.name}
          </Typography>
        }
        // subheader={fToNow(new Date(t.updated_at))}
        style={{ height: 85 }}
        action={
          <PopupState variant="popper" popupId="demo-popup-popper">
            {(popupState) => (
              <div>
                <ClickAwayListener
                  onClickAway={() => {
                    popupState.close();
                  }}
                >
                  <div>
                    <IconButton {...bindToggle(popupState)}>
                      <MoreVertIcon />
                    </IconButton>
                    <Popper {...bindPopper(popupState)} transition>
                      {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                          <Paper>
                            <MenuItem>
                              <Button
                                onClick={onCreateDocument}
                                startIcon={<Add />}
                                color={"primary"}
                              >
                                New doc
                              </Button>
                            </MenuItem>
                            <MenuItem>
                              <Button
                                onClick={onEdit}
                                startIcon={<Edit />}
                                color="warning"
                              >
                                Edit
                              </Button>
                            </MenuItem>
                            <MenuItem>
                              <Button
                                onClick={onMoveTemplate}
                                startIcon={<Folder />}
                                color={"secondary"}
                              >
                                Move
                              </Button>
                            </MenuItem>
                            <MenuItem>
                              <Button
                                onClick={onCopyTemplate}
                                startIcon={<ContentCopy />}
                                color={"primary"}
                              >
                                Copy
                              </Button>
                            </MenuItem>
                            <MenuItem>
                              <DeleteWithConfirm
                                onClick={onDelete}
                                text={"Delete"}
                              />
                            </MenuItem>
                          </Paper>
                        </Fade>
                      )}
                    </Popper>
                  </div>
                </ClickAwayListener>
              </div>
            )}
          </PopupState>
        }
      />
      <CardActions onClick={onViewDocs}>
        <CardContent style={{ height: 145 }}>
          <Typography variant="body2" color="text.secondary">
            {truncate(
              t.data.map((d: any) => d.label && d.label).join(", "),
              100,
              true,
            )}
          </Typography>
        </CardContent>
      </CardActions>

      {/* <AvatarGroup max={4}>
        <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
        <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
        <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
        <Avatar alt="Agnes Walker" src="/static/images/avatar/4.jpg" />
        <Avatar alt="Trevor Henderson" src="/static/images/avatar/5.jpg" />
      </AvatarGroup> */}
      <Divider />
      <CardActions>
        <Button
          variant="outlined"
          endIcon={<Start />}
          style={{ width: "100%" }}
          onClick={onViewDocs}
        >
          All documents
        </Button>
      </CardActions>
    </Card>
  );
}
