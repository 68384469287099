import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface DialogBoxProps {
  showDialog: boolean;
  cancelNavigation: any;
  confirmNavigation: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const DialogBox: React.FC<DialogBoxProps> = ({
  showDialog,
  cancelNavigation,
  confirmNavigation,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      open={showDialog}
      onClose={cancelNavigation}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {t("dialog:title")}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {t("dialog:description")}
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Button color="error" onClick={confirmNavigation}>
            {t("dialog:leave")}
          </Button>

          <Button color="primary" onClick={cancelNavigation}>
            {t("dialog:cancel")}
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};
export default DialogBox;
