import * as React from "react";
import {
  alpha,
  AppBar,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Link,
  Stack,
  styled,
  Toolbar,
  Typography,
} from "@mui/material";
import Page from "../../components/Page";
import { useTranslation } from "react-i18next";
import { DOCUMENT_URL, TEMPLATE_EDIT_URL } from "../../structs/urls";
import TemplateRenderer from "../../components/Template";
import { Template, TemplateListResponse } from "../../structs/gen/template";
import { api } from "../../services/api";
import { useNavigate } from "react-router-dom";
import LanguagePopover from "../../components/LanguagePopover";
import { Start } from "@mui/icons-material";

async function apiList(callback: any) {
  let resp = await api.post(`/api/template/examples`, {
    offset: 0,
    next: 10,
    filters: [],
  });
  callback(resp.data);
}

const ToolbarRootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up("lg")]: {
    width: `100%`,
  },
}));

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

export default function TemplateExamples() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [rows, setRows] = React.useState<Template[] | undefined>(undefined);
  const [loading, setLoading] = React.useState(true);
  const [currentMenuID, setCurrentMenuID] = React.useState<string | undefined>(
    undefined,
  );
  if (loading) {
    setLoading(false);
    apiList((resp: TemplateListResponse) => {
      setRows(resp.rows);
    });
  }

  if (loading || !rows) {
    return <CircularProgress />;
  }
  return (
    <>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <ToolbarRootStyle>
          <ToolbarStyle>
            {/* <SearchbarStyle>
                <Input
                  autoFocus
                  disableUnderline
                  placeholder="Search…"
                  endAdornment={
                    <InputAdornment position="start">
                      <SearchOutlined
                        style={{
                          color: "text.disabled",
                          width: 20,
                          height: 20,
                        }}
                      />
                    </InputAdornment>
                  }
                  sx={{ mr: 1, fontWeight: "fontWeightBold" }}
                />
              </SearchbarStyle> */}
            <Box sx={{ flexGrow: 1 }} />
            <Typography color={"black"} textAlign={"center"} variant={"h5"}>
              Template examples
            </Typography>
            <Box sx={{ flexGrow: 1 }} />

            <Stack
              direction="row"
              alignItems="center"
              spacing={{ xs: 0.5, sm: 1.5 }}
            >
              <LanguagePopover lang={i18n.language} />
              {/* <NotificationsPopover />*/}
            </Stack>
          </ToolbarStyle>
        </ToolbarRootStyle>
      </AppBar>
      <Page title={t("dashboard:title")}>
        <Box display="flex" justifyContent="center" style={{ paddingTop: 100 }}>
          <Container maxWidth="xl">
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container justifyContent="center" spacing={2}>
                    {rows
                      .filter(
                        (r) =>
                          (r.menu_id == undefined &&
                            currentMenuID == undefined) ||
                          r.menu_id === currentMenuID,
                      )
                      .map((t, index) => (
                        <Grid item key={index} sx={{ padding: "5px" }}>
                          <Card sx={{ width: 270, height: 350 }}>
                            <CardHeader
                              title={
                                <Typography variant="h6">{t.name}</Typography>
                              }
                            />
                            <CardActions>
                              <CardContent style={{ height: 145 }}>
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                >
                                  {truncate(
                                    t.data
                                      .map((d: any) => d.label && d.label)
                                      .join(", "),
                                    100,
                                    true,
                                  )}
                                </Typography>
                              </CardContent>
                            </CardActions>
                            <Divider />
                            <CardActions>
                              <Button
                                variant="outlined"
                                style={{ width: "100%" }}
                                onClick={() => {
                                  navigate(`/document/fill/${t.id}`);
                                }}
                              >
                                Fill
                              </Button>
                            </CardActions>
                          </Card>
                        </Grid>
                      ))}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Page>
    </>
  );
}

function truncate(str: string, n: number, useWordBoundary: boolean) {
  if (str.length <= n) {
    return str;
  }
  const subString = str.slice(0, n - 1); // the original check
  return (
    (useWordBoundary
      ? subString.slice(0, subString.lastIndexOf(" "))
      : subString) + "..."
  );
}
