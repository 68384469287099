import * as React from "react";
import {
  Avatar,
  Box,
  Button,
  Container,
  createTheme,
  CssBaseline,
  Grid,
  Paper,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { api } from "../services/api";
import { DASHBOARD_URL } from "../structs/urls";
import { useNavigate } from "react-router-dom";
import { Password } from "@mui/icons-material";
import OTPInput from "../components/OTPInput";

async function confirmCode(code: string) {
  let resp = await api.post(`/api/2fa/code`, {
    code: code,
  });
  console.log("resp.data", resp.data);
  return resp.data;
}
const theme = createTheme();

export default function TwoStepAuthConfirm() {
  const navigate = useNavigate();
  const [code, setCode] = React.useState("");

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <Password />
          </Avatar>
          <Typography component="h1" variant="h5">
            Confirm OTP
          </Typography>

          <OTPInput
            autoFocus
            isNumberInput
            length={6}
            className="otpContainer"
            inputClassName="otpInput"
            onChangeOTP={(otp) => {
              setCode(otp);
            }}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={async () => {
              await confirmCode(code);
              navigate(DASHBOARD_URL);
            }}
          >
            Confirm
          </Button>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
