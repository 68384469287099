import * as React from "react";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { Card, CardContent, CardHeader, Paper } from "@mui/material";
import { Numbers } from "@mui/icons-material";
import { ValueStatistic } from "../../structs/gen/statistic";

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}

export default function StatisticValue({
  title,
  subheader,
  props,
  ...other
}: {
  title: string;
  subheader?: string;
  props: ValueStatistic;
}) {
  return (
    <Card {...other}>
      <CardHeader title={title} subheader={subheader} avatar={<Numbers />} />
      <CardContent>
        <React.Fragment>
          <Typography component="p" variant="h4">
            {props.value}
          </Typography>
          {props.secondary_value && (
            <Typography color="text.secondary" sx={{ flex: 1 }}>
              {props.secondary_value}
            </Typography>
          )}

          {props.link && (
            <div>
              <Link color="primary" href={props.link} onClick={preventDefault}>
                More
              </Link>
            </div>
          )}
        </React.Fragment>
      </CardContent>
    </Card>
  );
}
