/* Do not change, this code is generated from Golang structs */


export enum FieldTypeEnum {
    label = "label",
    text = "text",
    datetime = "datetime",
    choice = "choice",
    divider = "divider",
    select = "select",
    media = "media",
    file = "file",
    signature = "signature",
    user_link = "user_link",
    table = "table",
}
export enum InputValueTypeEnum {
    any = "any",
    email = "email",
    number = "number",
}
export enum ChoiceTypeEnum {
    dropdown = "dropdown",
    checkbox = "checkbox",
    radio = "radio",
    button = "button",
}
export enum DateTimeTypeEnum {
    date = "date",
    datetime = "datetime",
    time = "time",
}
export enum SourceTypeEnum {
    template = "template",
    languages = "languages",
}
export enum ExecuteOnEnum {
    on_init = "on_init",
    on_updated = "on_updated",
    on_save = "on_save",
}