import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { api, setToken } from "../services/api";
import { useNavigate, useParams } from "react-router-dom";
import {
  CONFIRM_URL,
  DASHBOARD_URL,
  LOGIN_URL,
  REGISTER_URL,
} from "./../structs/urls";

const theme = createTheme();
async function forgotPassword(email: string) {
  try {
    let resp = await api.post(`/api/forgot_password`, {
      email: email,
    });
    return resp.data;
  } catch (e: any) {
    console.log("resp", e.response.data);
    return e.response.data;
  }
}

export default function ForgotPassword() {
  const params = useParams();
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState<undefined | string>(undefined);
  const navigate = useNavigate();

  const passwordAction = async () => {
    var respData = await forgotPassword(email.trim());
    if (respData.error && respData.error_description) {
      setError(respData.error_description);
    } else {
      navigate(LOGIN_URL);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Forgot password
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={email}
              onChange={(e) => {
                setError(undefined);
                setEmail(e.target.value);
              }}
              autoFocus
            />
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={passwordAction}
            >
              Restore password
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
