import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
} from "react-router-dom";
// material
import { alpha, useTheme, styled } from "@mui/material/styles";
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  Typography,
} from "@mui/material";
import Icon from "@mui/material/Icon";

import { Menu } from "../../structs/menu";
import { useTranslation } from "react-i18next";
//

// ----------------------------------------------------------------------

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: "inherit",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
  onClick: PropTypes.func,
};

function NavItem({
  item,
  active,
  onClick,
}: {
  item: Menu;
  active: any;
  onClick: any;
}) {
  const { t } = useTranslation();
  const itemsTranslactions: any = t("menu:items.files");
  console.log("itemsTranslactions", itemsTranslactions);

  const theme = useTheme();
  const { pathname } = useLocation();
  const isActiveRoot = item.url === "/" ? pathname === "/" : active(item.url);

  const { label, key, icon, url } = item;

  let translatedLabel = label;
  switch (key) {
    case "users":
      translatedLabel = t("menu:items.users");
      break;
    case "files":
      translatedLabel = t("menu:items.files");
      break;
    case "templates":
      translatedLabel = t("menu:items.templates");
      break;
    case "pdf":
      translatedLabel = t("menu:items.pdf");
      break;
    case "subscription":
      translatedLabel = t("menu:items.subscription");
      break;
  }

  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev: boolean) => !prev);
  };

  const activeRootStyle = {
    color: "primary.main",
    fontWeight: "fontWeightMedium",
    bgcolor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity,
    ),
  };

  const activeSubStyle = {
    color: "text.primary",
    fontWeight: "fontWeightMedium",
  };

  return (
    <>
      <ListItemButton
        component={RouterLink}
        onClick={
          item.sub_menu && item.sub_menu.length > 0 ? handleOpen : onClick
        }
        to={url}
        style={{
          height: 48,
          position: "relative",
          textTransform: "capitalize",
          color: theme.palette.text.secondary,
          borderRadius: theme.shape.borderRadius,
        }}
        sx={{
          ...(isActiveRoot && activeRootStyle),
        }}
      >
        <Icon
          style={{
            width: 22,
            height: 22,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: 10,
          }}
          color={isActiveRoot ? "primary" : "inherit"}
        >
          {icon && icon}
        </Icon>
        <Typography>{translatedLabel}</Typography>
        {/* {info && info} */}
      </ListItemButton>

      {item.sub_menu && item.sub_menu.length > 0 && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <div style={{ marginLeft: 25 }}>
            <MenuItem navConfig={item.sub_menu} onClick={onClick} />
          </div>
        </Collapse>
      )}
    </>
  );
}

MenuItem.propTypes = {
  navConfig: PropTypes.array,
  onClick: PropTypes.func,
};

export default function MenuItem({ navConfig, onClick, ...other }: any) {
  const { pathname } = useLocation();

  const match = (path: string) =>
    path ? !!matchPath({ path, end: false }, pathname) : false;

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {navConfig.map((item: Menu) => (
          <NavItem
            key={item.key}
            item={item}
            active={match}
            onClick={onClick}
          />
        ))}
      </List>
    </Box>
  );
}
