import { Save } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import MultiLanguageLabel from "../../MultiLanguageLabel";
import { SignatureFieldTypeObject } from "./signature_field_model";

export default function SignatureConfig({
  config,
  onSave,
}: {
  config: SignatureFieldTypeObject;
  onSave: (newConfig: SignatureFieldTypeObject) => void;
}) {
  const [newConfig, setNewConfig] = useState(config);

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      <div>
        <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <MultiLanguageLabel
            value={newConfig.label}
            onChange={(v) => {
              setNewConfig({
                ...newConfig,
                label: v,
              });
            }}
            multiValue={newConfig.label_intl}
            onChangeMultiLabel={(v) => {
              setNewConfig({
                ...newConfig,
                label_intl: v,
              });
            }}
          />
        </FormControl>

        <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <FormControlLabel
            control={
              <Switch
                defaultChecked={newConfig.is_visible}
                value={newConfig.is_visible}
                onChange={(e) => {
                  setNewConfig({
                    ...newConfig,
                    is_visible: e.target.checked,
                  });
                }}
              />
            }
            label="Is visible"
          />
        </FormControl>

        <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <FormControlLabel
            control={
              <Switch
                defaultChecked={newConfig.is_disabled}
                value={newConfig.is_disabled}
                onChange={(e) => {
                  setNewConfig({
                    ...newConfig,
                    is_disabled: e.target.checked,
                  });
                }}
              />
            }
            label="Is disabled"
          />
        </FormControl>
        <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <FormControlLabel
            control={
              <Switch
                defaultChecked={newConfig.is_required}
                value={newConfig.is_required}
                onChange={(e) => {
                  setNewConfig({
                    ...newConfig,
                    is_required: e.target.checked,
                  });
                }}
              />
            }
            label="Is required"
          />
        </FormControl>
        <Button
          onClick={() => {
            console.log("newConfig", newConfig);
            onSave(newConfig);
          }}
          fullWidth
          startIcon={<Save />}
          color={"success"}
          variant="contained"
        >
          Save
        </Button>
      </div>
    </Box>
  );
}
