import { Add, Delete, DragIndicatorOutlined, Save } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import React, { useRef, useState } from "react";
import {
  ChoiceTypeEnum,
  FieldTypeEnum,
} from "../../../structs/gen/enums/template";
import { ChoiceFieldType, OptionChoice } from "../../../structs/gen/template";
import MultiLanguageLabel from "../../MultiLanguageLabel";
import Page from "../../Page";
import { ChoiceFieldTypeObject } from "./choice_field_model";

import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { reorderList } from "../../../utils/order";

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? "lightblue" : "none",
  overflow: "scroll",
});

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",

  // change background colour if dragging
  background: isDragging ? "none" : "none",

  // styles we need to apply on draggables
  ...draggableStyle,
  left: 0,
});

function ChoiceConfig({
  config,
  onSave,
}: {
  config: ChoiceFieldTypeObject;
  onSave: (newConfig: ChoiceFieldTypeObject) => void;
}) {
  const [newConfig, setNewConfig] = useState(config);
  const [newOptions, setNewOptions] = useState<OptionChoice[]>(config.options);

  console.log("newConfig", newConfig);
  const optionsRef = useRef<null | HTMLDivElement>(null);

  const onDragEnd = (result: DropResult) => {
    console.log("onDragEnd", onDragEnd, result);
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reordered = reorderList(
      newOptions,
      result.source.index,
      result.destination.index,
    );

    console.log("reordered", reordered);

    setNewOptions([...reordered]);
  };

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      <div>
        <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <MultiLanguageLabel
            value={newConfig.label}
            onChange={(v) => {
              setNewConfig({
                ...newConfig,
                label: v,
                convertValues: newConfig.convertValues,
              });
            }}
            multiValue={newConfig.label_intl}
            onChangeMultiLabel={(v) => {
              setNewConfig({
                ...newConfig,
                label_intl: v,
                convertValues: newConfig.convertValues,
              });
            }}
          />
        </FormControl>
        <Page style={{ maxHeight: 300, overflow: "scroll" }}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="choice_config">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {newOptions.map((opt, i) => (
                    <Draggable key={opt.key} draggableId={opt.key} index={i}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                          )}
                        >
                          <Card
                            sx={{ p: 1, display: "flex", alignItems: "center" }}
                            key={opt.key}
                            id={opt.key}
                            ref={optionsRef}
                          >
                            <DragIndicatorOutlined />
                            <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
                              <MultiLanguageLabel
                                value={opt.label}
                                onChange={(v) => {
                                  setNewOptions([
                                    ...newOptions.map((f) => {
                                      if (f.key === opt.key) {
                                        f.label = v;
                                      }
                                      return f;
                                    }),
                                  ]);
                                }}
                                multiValue={opt.label_intl}
                                onChangeMultiLabel={(v) => {
                                  setNewOptions([
                                    ...newOptions.map((f) => {
                                      if (f.key === opt.key) {
                                        f.label_intl = v;
                                      }
                                      return f;
                                    }),
                                  ]);
                                }}
                              />
                            </FormControl>
                            <IconButton
                              onClick={() => {
                                setNewOptions([
                                  ...newOptions.filter(
                                    (f) => f.key !== opt.key,
                                  ),
                                ]);
                              }}
                              sx={{ p: "10px" }}
                            >
                              <Delete style={{ width: "36px" }} color="error" />
                            </IconButton>
                          </Card>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Page>
        <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <Button
            startIcon={<Add />}
            color={"success"}
            onClick={() => {
              setNewOptions([
                ...newOptions,
                {
                  key: generateOptionKey(),
                  label: "Option",
                  label_intl: {},
                },
              ]);
              setTimeout(function () {
                optionsRef.current?.scrollIntoView({ behavior: "smooth" });
              }, 100);
            }}
          >
            New option
          </Button>
        </FormControl>

        <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <FormControlLabel
            control={
              <Switch
                defaultChecked={newConfig.is_multiple}
                value={newConfig.is_multiple}
                onChange={(e) => {
                  setNewConfig({
                    ...newConfig,
                    convertValues: newConfig.convertValues,
                    is_multiple: e.target.checked,
                  });
                }}
              />
            }
            label="Is multiple"
          />
        </FormControl>

        <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <InputLabel id="demo-simple-select-label">Type</InputLabel>
          <Select
            labelId="demo-simple-select-type"
            id="demo-simple-type"
            value={newConfig.value_type}
            label="type"
            onChange={(e) => {
              var v: ChoiceTypeEnum = ChoiceTypeEnum.checkbox;
              switch (e.target.value) {
                case "checkbox":
                  v = ChoiceTypeEnum.checkbox;
                  break;
                case "dropdown":
                  v = ChoiceTypeEnum.dropdown;
                  break;
                case "radio":
                  v = ChoiceTypeEnum.radio;
                  break;
                case "button":
                  v = ChoiceTypeEnum.button;
                  break;
              }
              setNewConfig({
                ...newConfig,
                convertValues: newConfig.convertValues,
                value_type: v,
              });
            }}
          >
            <MenuItem value={ChoiceTypeEnum.checkbox}>Checkbox</MenuItem>
            <MenuItem value={ChoiceTypeEnum.dropdown}>Dropdown</MenuItem>
            <MenuItem value={ChoiceTypeEnum.radio}>Radio</MenuItem>
            <MenuItem value={ChoiceTypeEnum.button}>Button</MenuItem>
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <FormControlLabel
            control={
              <Switch
                defaultChecked={newConfig.is_required}
                value={newConfig.is_required}
                onChange={(e) => {
                  setNewConfig({
                    ...newConfig,
                    convertValues: newConfig.convertValues,
                    is_required: e.target.checked,
                  });
                }}
              />
            }
            label="Is required"
          />
        </FormControl>

        <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <FormControlLabel
            control={
              <Switch
                defaultChecked={newConfig.is_disabled}
                value={newConfig.is_disabled}
                onChange={(e) => {
                  setNewConfig({
                    ...newConfig,
                    convertValues: newConfig.convertValues,
                    is_disabled: e.target.checked,
                  });
                }}
              />
            }
            label="Is disabled"
          />
        </FormControl>

        <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <FormControlLabel
            control={
              <Switch
                defaultChecked={newConfig.is_visible}
                value={newConfig.is_visible}
                onChange={(e) => {
                  setNewConfig({
                    ...newConfig,
                    convertValues: newConfig.convertValues,
                    is_visible: e.target.checked,
                  });
                }}
              />
            }
            label="Is visible"
          />
        </FormControl>
        <Button
          onClick={() => {
            newConfig.options = newOptions;
            onSave(newConfig);
          }}
          fullWidth
          startIcon={<Save />}
          color={"success"}
          variant="contained"
        >
          Save
        </Button>
      </div>
    </Box>
  );
}

export function generateOptionKey() {
  return "option_" + (Math.random() + 1).toString(36).substring(7);
}

export default React.memo(ChoiceConfig);
