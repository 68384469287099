import * as React from "react";
import { api } from "../../services/api";
import {
  LineChartStatistic,
  Statistic,
  StatisticListResponse,
  ValueStatistic,
} from "../../structs/gen/statistic";
import { useTranslation } from "react-i18next";
import { CircularProgress, Grid } from "@mui/material";
import {
  StatisticBlockSize,
  StatisticType,
} from "../../structs/gen/enums/statistic";
import StatisticLineChart from "./StatisticLineChart";
import StatisticValue from "./StatisticValue";

async function statisticList(): Promise<StatisticListResponse> {
  let resp = await api.get(`/api/statistic/list`);
  console.log("statisticList resp.data", resp.data);
  return resp.data;
}

export default function Statistics() {
  const { t } = useTranslation();
  const [statistics, setStatistics] = React.useState<any[] | undefined>(
    undefined,
  );

  const [loading, setLoading] = React.useState(true);

  if (loading) {
    setLoading(false);
    statisticList().then((resp) => setStatistics(resp.statistics));
  }

  if (loading) {
    return <CircularProgress />;
  }

  if (!statistics || statistics.length === 0) {
    return <></>;
  }

  return (
    <>
      {statistics.map((s) => {
        return buildStatisticBlock(s);
      })}
    </>
  );
}

function buildStatisticBlock(statistic: any) {
  var s = statistic as Statistic;

  var renderContent;

  switch (s.display_type) {
    case StatisticType.line_chart:
      renderContent = (
        <>
          <StatisticLineChart
            title={s.title}
            subheader={s.sub_header}
            props={statistic as LineChartStatistic}
          />
        </>
      );
      break;
    case StatisticType.value:
      renderContent = (
        <>
          <StatisticValue
            title={s.title}
            subheader={s.sub_header}
            props={statistic as ValueStatistic}
          />
        </>
      );
      break;
  }

  switch (s.size) {
    case StatisticBlockSize.full:
      return (
        <Grid item xs={12} md={12} lg={12}>
          {renderContent}
        </Grid>
      );
    case StatisticBlockSize.half:
      return (
        <Grid item xs={12} md={6} lg={4}>
          {renderContent}
        </Grid>
      );
    case StatisticBlockSize.quarter:
      return (
        <Grid item xs={12} md={4} lg={3}>
          {renderContent}
        </Grid>
      );
  }
  return <></>;
}
