import React, { useState } from "react";
import type { FC } from "react";
import { useRef } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ClearIcon from "@mui/icons-material/Clear";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import isTouch from "is-touch-device";
import { IFieldType } from "../field/generic";
import { Delete } from "@mui/icons-material";
import { LabelFieldTypeObject } from "../field/label/label_field_model";
import LabelConfig from "../field/label/LabelConfig";
import { InputFieldTypeObject } from "../field/input/input_field_model";
import InputConfig from "../field/input/InputConfig";
import { DateTimeFieldTypeObject } from "../field/datetime/datetime_field_model";
import DateTimeConfig from "../field/datetime/DateTimeConfig";
import ChoiceConfig from "../field/choice/ChoiceConfig";
import { ChoiceFieldTypeObject } from "../field/choice/choice_field_model";
import SelectConfig from "../field/select/SelectConfig";
import { SelectFieldTypeObject } from "../field/select/select_field_model";
import MediaConfig from "../field/media/MediaConfig";
import { MediaFieldTypeObject } from "../field/media/media_field_model";
import FileConfig from "../field/file/FileConfig";
import { FileFieldTypeObject } from "../field/file/file_field_model";
import SignatureConfig from "../field/signature/SignatureConfig";
import { FieldTypeEnum } from "../../structs/gen/enums/template";
import UserLinkConfig from "../field/user_link/UserLinkConfig";
import { UserLinkFieldTypeObject } from "../field/user_link/user_link_field_model";
import TableConfig from "../field/table/TableConfig";
import { TableFieldTypeObject } from "../field/table/table_field_model";

const style = {
  // border: "1px dashed gray",
  // padding: "0.5rem 1rem",
  // marginBottom: ".5rem",
  // cursor: "move",
  cursor: "move",
  opacity: 1,
  display: "flex",
  placeContent: "center space-between",
  flexFlow: "row nowrap",
  alignItems: "center",
};

const modalStyle = {
  // position: "absolute" as "absolute",
  // top: "50%",
  // left: "50%",
  // transform: "translate(-50%, -50%)",

  position: "absolute" as "absolute",
  marginLeft: "auto",
  marginRight: "auto",
  left: 0,
  right: 0,
  textAlign: "center",
  marginTop: "25px",
  marginBottom: "25px",

  maxWidth: "700px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export interface ConfigProps {
  id: any;
  children: React.ReactNode;
  index: number;
  config: IFieldType;
  isDraggingAnyCard: boolean;
  onDragging: (v: boolean) => void;
  onUpdate: (key: string, fieldConfig: IFieldType | undefined) => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const Config: FC<ConfigProps> = ({
  id,
  children,
  index,
  config,
  //moveCard,
  isDraggingAnyCard,
  onDragging,
  onUpdate,
}) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isShownOptions, setIsShownOptions] = useState(false);
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit field
          </Typography>
          <Typography variant="body2" color="text.secondary">
            key: {config.key}
          </Typography>
          <Button
            onClick={() => {
              onUpdate(id, undefined);
              setOpen(false);
            }}
            startIcon={<Delete />}
            color={"error"}
          >
            Delete
          </Button>
          <br />
          <div
            style={{
              height: "100%",
              overflowY: "scroll",
              padding: "14px",
            }}
          >
            <ConfigField
              config={config}
              onSave={(newConfig) => {
                onUpdate(id, newConfig);
                setOpen(false);
              }}
            />
          </div>
        </Box>
      </Modal>
      <div
        style={{ ...style }}
        onMouseEnter={() => setIsShownOptions(true)}
        onMouseLeave={() => setIsShownOptions(false)}
      >
        {((isShownOptions && (!isDraggingAnyCard || isDraggingAnyCard)) ||
          isTouch()) && <DragIndicatorIcon style={{ width: "36px" }} />}
        <div style={{ width: "100%" }}>{children}</div>
        {((isShownOptions && (!isDraggingAnyCard || isDraggingAnyCard)) ||
          isTouch()) && (
          <IconButton onClick={handleOpen}>
            <SettingsIcon style={{ width: "36px" }} color="primary" />
          </IconButton>
        )}
      </div>
    </>
  );
};

export const ItemTypes = {
  CARD: "card",
};

const ConfigField = ({
  config,
  onSave,
}: {
  config: IFieldType;
  onSave: (newConfig: IFieldType) => void;
}) => {
  switch (config.type) {
    case FieldTypeEnum.label:
      return (
        <LabelConfig config={config as LabelFieldTypeObject} onSave={onSave} />
      );
    case FieldTypeEnum.text:
      return (
        <InputConfig config={config as InputFieldTypeObject} onSave={onSave} />
      );
    case FieldTypeEnum.datetime:
      return (
        <DateTimeConfig
          config={config as DateTimeFieldTypeObject}
          onSave={onSave}
        />
      );
    case FieldTypeEnum.choice:
      return (
        <ChoiceConfig
          config={config as ChoiceFieldTypeObject}
          onSave={onSave}
        />
      );
    case FieldTypeEnum.select:
      return (
        <SelectConfig
          config={config as SelectFieldTypeObject}
          onSave={onSave}
        />
      );
    case FieldTypeEnum.media:
      return (
        <MediaConfig config={config as MediaFieldTypeObject} onSave={onSave} />
      );
    case FieldTypeEnum.file:
      return (
        <FileConfig config={config as FileFieldTypeObject} onSave={onSave} />
      );
    case FieldTypeEnum.signature:
      return (
        <SignatureConfig
          config={config as SelectFieldTypeObject}
          onSave={onSave}
        />
      );
    case FieldTypeEnum.user_link:
      return (
        <UserLinkConfig
          config={config as UserLinkFieldTypeObject}
          onSave={onSave}
        />
      );
    case FieldTypeEnum.table:
      return (
        <TableConfig config={config as TableFieldTypeObject} onSave={onSave} />
      );
  }

  return <></>;
};
