import React, { useRef } from "react";
import clsx from "clsx";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  CardMedia,
  Chip,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Input,
  List,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import { CloudUpload, Delete, Download, TagFaces } from "@mui/icons-material";
import { FileFieldTypeObject } from "./file_field_model";
import { api } from "../../../services/api";
import { useTranslation } from "react-i18next";
import ImageIcon from "@mui/icons-material/Image";
import { File } from "../../../structs/gen/file";

const ListItem = styled("li")(({ theme }: { theme: any }) => ({
  margin: theme.spacing(0.5),
}));

export default function FileUpload({
  onChange,
  id,
  config,
  values,
  errors,
}: {
  onChange: (value: File[]) => void;
  id: string;
  config: FileFieldTypeObject;
  values: File[];
  errors: string[];
}) {
  const { i18n } = useTranslation();
  console.log(values, config);
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <FormControl
      fullWidth
      disabled={config.is_disabled}
      required={config.is_required}
      error={errors && errors.length > 0}
    >
      <FormLabel component="legend">
        {(config.label_intl &&
          config.label_intl[i18n.language] &&
          config.label_intl[i18n.language]) ||
          config.label}
      </FormLabel>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <input
          type="file"
          id={id}
          ref={inputRef}
          multiple={config.max_items > 1}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            console.log("input onChange");
            if (e.target && e.target.files) {
              for (var id = 0; id < e.target.files.length; id++) {
                let formData = new FormData();
                formData.append("file", e.target.files[id]);
                api.post("/api/file/", formData).then((res: any) => {
                  console.log(res);
                  onChange([...values, res.data]);
                });
              }
              console.log("event.target.files", e.target.files);
            }
          }}
          style={{
            display: "none",
          }}
        />
        <CloudUpload
          sx={{ fontSize: 50 }}
          onClick={() => {
            if (!config.is_disabled && inputRef && inputRef.current) {
              inputRef.current.click();
            }
          }}
        />
      </Box>
      {values.length > 0 && (
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={{ xs: 2, md: 2 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {values.map((v, index) => (
              <Grid item xs={2} sm={4} md={4} key={index}>
                <Card>
                  <CardHeader title={v.name} />
                  <CardMedia
                    component="img"
                    height="194"
                    image={v.thumbnail_url}
                    alt="Paella dish"
                  />
                  {/* <ListItemText primary="File" /> */}
                  <CardActions disableSpacing>
                    <IconButton
                      sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                      onClick={() => {
                        onChange(values.filter((f) => f !== v));
                      }}
                    >
                      <Delete color="error" />
                    </IconButton>

                    <IconButton
                      sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                      onClick={() => {
                        console.log("v.url", v);
                        window.open(v.url, "_blank", "noopener,noreferrer");
                      }}
                      color="error"
                    >
                      <Download color="primary" />
                    </IconButton>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
        // <ImageList cols={4} rowHeight={164} variant="quilted">
        //   {values.map((v) => (
        //     <ImageListItem key={v}>
        //       <img src={v} alt={v} loading="lazy" />
        //       <ImageListItemBar
        //         // title={item.title}
        //         // subtitle={item.author}
        //         actionIcon={
        //           <>

        //           </>
        //         }
        //       />
        //     </ImageListItem>
        //   ))}
        // </ImageList>
      )}

      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          listStyle: "none",
          p: 0.5,
          m: 0,
        }}
        component="ul"
      >
        {values.map((v) => {
          return (
            <ListItem key={v}>
              <Chip
                label={v}
                avatar={<img src={v} />}
                onDelete={() => {
                  onChange(values.filter((f) => f !== v));
                }}
              />
            </ListItem>
          );
        })}
      </Box> */}
    </FormControl>
  );
}

function isImage(url: string) {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
}

function getFileName(url: string) {
  return url.substring(url.lastIndexOf("/") + 1);
}
