import { Save } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { api } from "../../../services/api";
import { SelectFieldTypeObject } from "./select_field_model";
import {
  FieldType,
  Template,
  TemplateListResponse,
} from "../../../structs/gen/template";
import { FieldTypeEnum } from "../../../structs/gen/enums/template";
import MultiLanguageLabel from "../../MultiLanguageLabel";

async function apiList() {
  let resp = await api.post(`/api/template/list`, {
    offset: 0,
    next: 100,
    filters: [],
  });
  return resp.data;
}

function generateFieldTypes(template: Template): any[] {
  var newFieldTypes: any[] = [];
  for (var con in template.data) {
    var conf = template.data[con];
    var fieldType = conf as FieldType;
    if (
      fieldType.type === FieldTypeEnum.text ||
      fieldType.type === FieldTypeEnum.datetime
    ) {
      newFieldTypes.push(conf);
    }
  }
  return newFieldTypes;
}

export default function SelectConfig({
  config,
  onSave,
}: {
  config: SelectFieldTypeObject;
  onSave: (newConfig: SelectFieldTypeObject) => void;
}) {
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(true);
  const [newConfig, setNewConfig] = useState(config);
  const [sourceTemplates, setSourceTemplates] = useState<Template[]>([]);
  const [sourceFieldTypes, setSourceFieldTypes] = useState<any[]>([]);

  if (isLoadingTemplates) {
    setIsLoadingTemplates(false);
    apiList().then((resp: TemplateListResponse) => {
      setSourceTemplates(resp.rows);
      if (config.source_value && config.source_fields) {
        for (var r in resp.rows) {
          if (resp.rows[r].id === config.source_value) {
            setSourceFieldTypes(generateFieldTypes(resp.rows[r]));
            break;
          }
        }
      }
    });
  }

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      <div>
        {/* <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <TextField
            fullWidth
            label="Name"
            value={newConfig.name}
            type={"text"}
            onChange={(e) => {
              setNewConfig({
                ...newConfig,
                name: e.target.value,
              });
            }}
          />
        </FormControl> */}

        <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <MultiLanguageLabel
            value={newConfig.label}
            onChange={(v) => {
              setNewConfig({
                ...newConfig,
                label: v,
              });
            }}
            multiValue={newConfig.label_intl}
            onChangeMultiLabel={(v) => {
              setNewConfig({
                ...newConfig,
                label_intl: v,
              });
            }}
          />
        </FormControl>

        <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <FormControlLabel
            control={
              <Switch
                defaultChecked={newConfig.is_required}
                value={newConfig.is_required}
                onChange={(e) => {
                  setNewConfig({
                    ...newConfig,
                    is_required: e.target.checked,
                  });
                }}
              />
            }
            label="Is required"
          />
        </FormControl>

        <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <FormControlLabel
            control={
              <Switch
                defaultChecked={newConfig.is_visible}
                value={newConfig.is_visible}
                onChange={(e) => {
                  setNewConfig({
                    ...newConfig,
                    is_visible: e.target.checked,
                  });
                }}
              />
            }
            label="Is visible"
          />
        </FormControl>

        <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <FormControlLabel
            control={
              <Switch
                defaultChecked={newConfig.is_disabled}
                value={newConfig.is_disabled}
                onChange={(e) => {
                  setNewConfig({
                    ...newConfig,
                    is_disabled: e.target.checked,
                  });
                }}
              />
            }
            label="Is disabled"
          />
        </FormControl>

        <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <FormControlLabel
            control={
              <Switch
                defaultChecked={newConfig.is_multiple}
                value={newConfig.is_multiple}
                onChange={(e) => {
                  setNewConfig({
                    ...newConfig,
                    is_multiple: e.target.checked,
                  });
                }}
              />
            }
            label="Allow multiple"
          />
        </FormControl>

        <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <InputLabel id="select-source">Source template</InputLabel>
          <Select
            labelId="select-source"
            id="demo-simple-source"
            defaultValue={config.source_value}
            label="Source template"
            onChange={(e) => {
              var template_id = e.target.value;
              for (var r in sourceTemplates) {
                if (sourceTemplates[r].id === template_id) {
                  setSourceFieldTypes(generateFieldTypes(sourceTemplates[r]));
                  break;
                }
              }
              setNewConfig({
                ...newConfig,
                source_value: template_id.toString(),
              });
            }}
          >
            {sourceTemplates.map((t) => (
              <MenuItem key={t.id} value={t.id}>
                {t.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <InputLabel id="select-source">Source fields</InputLabel>
          <Select
            labelId="select-field"
            id="demo-simple-field"
            defaultValue={config.source_fields}
            label="Source field"
            input={<OutlinedInput label="Source fields" />}
            // onChange={(e) => {
            //   if (Array.isArray(e.target.value)) {
            //     setNewConfig({
            //       ...newConfig,
            //       source_fields: e.target.value,
            //     });
            //   } else {
            //     setNewConfig({
            //       ...newConfig,
            //       source_fields: [e.target.value],
            //     });
            //   }
            // }}
            onChange={(
              event: SelectChangeEvent<typeof config.source_fields>,
            ) => {
              const {
                target: { value },
              } = event;
              console.log(
                "changes",
                typeof value === "string" ? value.split(",") : value,
              );
              setNewConfig({
                ...newConfig,
                source_fields:
                  typeof value === "string" ? value.split(",") : value,
              });
            }}
            multiple
          >
            {sourceFieldTypes.map((t) => (
              <MenuItem key={t.key} value={t.key}>
                {t.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          onClick={() => {
            console.log("newConfig", newConfig);
            onSave(newConfig);
          }}
          fullWidth
          startIcon={<Save />}
          color={"success"}
          variant="contained"
        >
          Save
        </Button>
      </div>
    </Box>
  );
}
