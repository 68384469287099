import { Save } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { api } from "../../../services/api";
import {
  FieldType,
  Template,
  TemplateListResponse,
} from "../../../structs/gen/template";
import { FieldTypeEnum } from "../../../structs/gen/enums/template";
import MultiLanguageLabel from "../../MultiLanguageLabel";
import { UserLinkFieldTypeObject } from "./user_link_field_model";
import { RoleTypeEnum } from "../../../structs/gen/enums/user";

async function apiList() {
  let resp = await api.post(`/api/template/list`, {
    offset: 0,
    next: 100,
    filters: [],
  });
  return resp.data;
}

function generateFieldTypes(template: Template): any[] {
  var newFieldTypes: any[] = [];
  for (var con in template.data) {
    var conf = template.data[con];
    var fieldType = conf as FieldType;
    if (
      fieldType.type === FieldTypeEnum.text ||
      fieldType.type === FieldTypeEnum.datetime
    ) {
      newFieldTypes.push(conf);
    }
  }
  return newFieldTypes;
}

export default function UserLinkConfig({
  config,
  onSave,
}: {
  config: UserLinkFieldTypeObject;
  onSave: (newConfig: UserLinkFieldTypeObject) => void;
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [newConfig, setNewConfig] = useState(config);

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      <div>
        {/* <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <TextField
            fullWidth
            label="Name"
            value={newConfig.name}
            type={"text"}
            onChange={(e) => {
              setNewConfig({
                ...newConfig,
                name: e.target.value,
              });
            }}
          />
        </FormControl> */}

        <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <MultiLanguageLabel
            value={newConfig.label}
            onChange={(v) => {
              setNewConfig({
                ...newConfig,
                label: v,
              });
            }}
            multiValue={newConfig.label_intl}
            onChangeMultiLabel={(v) => {
              setNewConfig({
                ...newConfig,
                label_intl: v,
              });
            }}
          />
        </FormControl>

        <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <FormControlLabel
            control={
              <Switch
                defaultChecked={newConfig.is_required}
                value={newConfig.is_required}
                onChange={(e) => {
                  setNewConfig({
                    ...newConfig,
                    is_required: e.target.checked,
                  });
                }}
              />
            }
            label="Is required"
          />
        </FormControl>

        <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <FormControlLabel
            control={
              <Switch
                defaultChecked={newConfig.is_visible}
                value={newConfig.is_visible}
                onChange={(e) => {
                  setNewConfig({
                    ...newConfig,
                    is_visible: e.target.checked,
                  });
                }}
              />
            }
            label="Is visible"
          />
        </FormControl>

        <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <FormControlLabel
            control={
              <Switch
                defaultChecked={newConfig.is_disabled}
                value={newConfig.is_disabled}
                onChange={(e) => {
                  setNewConfig({
                    ...newConfig,
                    is_disabled: e.target.checked,
                  });
                }}
              />
            }
            label="Is disabled"
          />
        </FormControl>

        <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <FormControlLabel
            control={
              <Switch
                defaultChecked={newConfig.is_multiple}
                value={newConfig.is_multiple}
                onChange={(e) => {
                  setNewConfig({
                    ...newConfig,
                    is_multiple: e.target.checked,
                  });
                }}
              />
            }
            label="Allow multiple"
          />
        </FormControl>

        <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <InputLabel id="demo-simple-select-label">Allowed roles</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={newConfig.roles}
            multiline
            label="Allowed roles"
            onChange={(v) => {
              setNewConfig({
                ...newConfig,
                roles:
                  typeof v.target.value === "string"
                    ? v.target.value.split(",")
                    : v.target.value,
              });
            }}
          >
            <MenuItem key={RoleTypeEnum.admin} value={RoleTypeEnum.admin}>
              Admin
            </MenuItem>
            <MenuItem key={RoleTypeEnum.employee} value={RoleTypeEnum.employee}>
              Employee
            </MenuItem>
            <MenuItem key={RoleTypeEnum.partner} value={RoleTypeEnum.partner}>
              Partner
            </MenuItem>
            <MenuItem key={RoleTypeEnum.client} value={RoleTypeEnum.client}>
              Client
            </MenuItem>
            <MenuItem
              key={RoleTypeEnum.programmer}
              value={RoleTypeEnum.programmer}
            >
              Programmer
            </MenuItem>
          </Select>
        </FormControl>

        <Button
          onClick={() => {
            console.log("newConfig", newConfig);
            onSave(newConfig);
          }}
          fullWidth
          startIcon={<Save />}
          color={"success"}
          variant="contained"
        >
          Save
        </Button>
      </div>
    </Box>
  );
}
