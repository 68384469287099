import * as React from "react";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  TextField,
} from "@mui/material";
import { api } from "../services/api";
import OTPInput from "../components/OTPInput";
import { User } from "../structs/gen/user";
import {
  PasswordOutlined,
  RemoveCircle,
  SecurityOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import Password from "../components/settings/Password";
import AvatarUpload from "../components/settings/AvatarUpload";
import { Company } from "../structs/gen/company";

async function getCompanySettings() {
  let resp = await api.get(`/api/company/settings`);
  console.log("resp.data", resp.data);
  return resp.data;
}

async function apiSave(id: string | undefined, data: Company, callback: any) {
  let resp = await api.post(`/api/company/${id}`, data);
  callback(resp.data);
}

export default function CompanySettings() {
  const [isLoading, setIsLoading] = React.useState(true);
  const [company, setCompany] = React.useState<Company | undefined>(undefined);

  if (isLoading) {
    getCompanySettings().then((v: Company) => {
      setCompany(v);
    });
    setIsLoading(false);
  }

  if (!company) {
    return <CircularProgress />;
  }

  return (
    <Paper elevation={9} sx={{ mt: 1 }} style={{ padding: "20px" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AvatarUpload
            src={company && company!.logo}
            onChange={(logo) => {
              setCompany({
                ...company,
                convertValues: company.convertValues,
                logo: logo,
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="name"
            label="Name"
            defaultValue={company.name}
            name="Name"
            autoFocus
            onChange={(e) => {
              setCompany({
                ...company,
                convertValues: company.convertValues,
                name: e.target.value,
              });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            defaultValue={company!.email}
            name="email"
            autoComplete="email"
            onChange={(e) => {
              setCompany({
                ...company,
                convertValues: company.convertValues,
                email: e.target.value,
              });
            }}
          />
        </Grid>
        <Button
          variant="contained"
          fullWidth
          style={{ margin: "20px" }}
          color="success"
          onClick={() => {
            apiSave(company.id, company, () => {
              window.location.reload();
            });
          }}
        >
          Save
        </Button>
      </Grid>
    </Paper>
  );
}
