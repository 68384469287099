import { ImageNotSupported } from "@mui/icons-material";
import { Box } from "@mui/material";
import React from "react";
import { MediaFieldTypeObject } from "./media_field_model";

export default function FileUpload({
  id,
  config,
}: {
  id: string;
  config: MediaFieldTypeObject;
}) {
  console.log("config.src", config.src);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {config.src && config.src !== "" ? (
        <div>
          <img src={config.src} loading="lazy" width={200} />
        </div>
      ) : (
        <ImageNotSupported sx={{ fontSize: 70 }} />
      )}
    </Box>
  );
}
