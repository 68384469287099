import { Settings } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { FieldTypeEnum } from "../../structs/gen/enums/template";
import { Template } from "../../structs/gen/template";

function AutoTextArea({
  value,
  style,
  fontSize,
}: {
  style: any;
  value: string | undefined;
  fontSize: number;
}) {
  const divAreaRef = useRef<any>(null);

  return (
    <>
      <div style={{ ...style }} ref={divAreaRef}>
        {value &&
          value?.split("\n").map((v) => {
            return <Typography fontSize={fontSize}>{v}</Typography>;
          })}
      </div>
    </>
  );
}

export function TextAreaSettings({
  value,
  fontSize,
  onTextChange,
  onChangeFontSize,
}: {
  value: string | undefined;
  fontSize: number;
  onTextChange: (value: string | undefined) => void;
  onChangeFontSize: (value: number) => void;
}) {
  return (
    <>
      <FormControl fullWidth sx={{ m: 1 }}>
        <TextField
          id="filled-multiline-flexible"
          label="Text"
          multiline
          maxRows={4}
          value={value}
          variant="filled"
          onChange={(e) => {
            onTextChange(e.target.value);
          }}
        />
      </FormControl>
      <FormControl fullWidth sx={{ m: 1 }}>
        <TextField
          id="font-size"
          label="Font size"
          value={fontSize}
          variant="filled"
          type={"number"}
          onChange={(e) => {
            onChangeFontSize(+e.target.value);
          }}
        />
      </FormControl>
    </>
  );
}

export default AutoTextArea;
