import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { LabelFieldTypeObject } from "./label_field_model";

const LabelField = ({
  id,
  config,
}: {
  id: string;
  config: LabelFieldTypeObject;
}) => {
  const { i18n } = useTranslation();
  return (
    <>
      {config.label.split("\n").map((label) => (
        <Typography
          id={id}
          fontSize={config.font_size}
          sx={{ fontWeight: config.is_bold ? "bold" : "" }}
        >
          {(config.label_intl &&
            config.label_intl[i18n.language] &&
            config.label_intl[i18n.language]) ||
            config.label}
        </Typography>
      ))}
    </>
  );
};

export default React.memo(LabelField);
