import * as React from "react";
import Paper from "@mui/material/Paper";
import { api } from "../../services/api";
import { User } from "../../structs/gen/user";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { USER_LIST_URL } from "../../structs/urls";
import Access from "../../components/Access";
import { useTranslation } from "react-i18next";
import { RoleTypeEnum } from "../../structs/gen/enums/user";

async function apiGet(id: string, callback: any) {
  let resp = await api.get(`/api/user/${id}`);
  console.log("resp.data", resp.data);
  callback(resp.data);
}

async function apiSave(id: string | undefined, data: User, callback: any) {
  let resp = await api.post(`/api/user/${id && id !== "" ? id : ""}`, data);
  callback(resp.data);
}

export default function Users() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const [user, setUser] = React.useState<User | undefined>(undefined);
  const [loading, setLoading] = React.useState(true);

  if (loading) {
    setLoading(false);
    if (params.id && String(params.id) !== "") {
      apiGet(String(params.id), (resp: User) => {
        setUser(resp);
      });
      return <CircularProgress />;
    } else {
      setUser({
        access: [],
        avatar: "",
        actions: [],
        convertValues: new User().convertValues,
        email: "",
        name: "",
        id: "",
        is_blocked: false,
        is_google_auth: false,
        is_two_factor_auth_enabled: false,
        role: "",
      });
    }
    return <CircularProgress />;
  }

  if (!user) {
    return <CircularProgress />;
  }
  return (
    <Box display="flex" justifyContent="center">
      <Paper
        elevation={9}
        sx={{ mt: 1 }}
        style={{ padding: "20px", width: "700px" }}
      >
        <Grid item xs={12} sm={6}>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label={t("user_edit:name")}
              value={user.name}
              name="Name"
              autoFocus
              onChange={(e) => {
                setUser({
                  ...user,
                  name: e.target.value,
                  convertValues: user.convertValues,
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={t("user_edit:email")}
              value={user.email}
              name="email"
              autoComplete="email"
              onChange={(e) => {
                setUser({
                  ...user,
                  email: e.target.value,
                  convertValues: user.convertValues,
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  defaultChecked={user.is_blocked}
                  onChange={(e) => {
                    setUser({
                      ...user,
                      is_blocked: e.target.checked,
                      convertValues: user.convertValues,
                    });
                  }}
                />
              }
              label={t("user_edit:is_blocked")}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel id="role-label">Role</InputLabel>
              <Select
                labelId="role-label"
                id="role"
                value={user.role}
                label="Role"
                onChange={(e) => {
                  setUser({
                    ...user,
                    role: e.target.value,
                    convertValues: user.convertValues,
                  });
                }}
              >
                <MenuItem value={RoleTypeEnum.admin}>Administrator</MenuItem>
                <MenuItem value={RoleTypeEnum.employee}>Employee</MenuItem>
                <MenuItem value={RoleTypeEnum.partner}>Partner</MenuItem>
                <MenuItem value={RoleTypeEnum.programmer}>
                  Software developer
                </MenuItem>
                <MenuItem value={RoleTypeEnum.client}>Client</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sx={{ m: 1 }}>
            <Access
              values={user.access}
              onChange={(values) => {
                setUser({
                  ...user,
                  access: values,
                  convertValues: user.convertValues,
                });
              }}
              label={t("user_edit:access")}
            />
          </Grid>
          <Button
            variant="contained"
            fullWidth
            color="success"
            style={{ marginTop: 20 }}
            onClick={() => {
              apiSave(user.id, user, () => {
                navigate(USER_LIST_URL);
              });
            }}
          >
            {t("user_edit:save")}
          </Button>
        </Grid>
      </Paper>
    </Box>
  );
}
