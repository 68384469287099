import {
  ArrowDownward,
  AspectRatio,
  ControlCamera,
  MoreVert,
  MoveDown,
  PlayArrow,
  Settings,
} from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import React from "react";
import { useState, useEffect, useRef } from "react";
import { FieldTypeEnum } from "../../structs/gen/enums/template";
import { Template } from "../../structs/gen/template";
import { PDFElementType } from "./PDF";

const modalStyle = {
  // position: "absolute" as "absolute",
  // top: "50%",
  // left: "50%",
  // transform: "translate(-50%, -50%)",

  position: "absolute" as "absolute",
  marginLeft: "auto",
  marginRight: "auto",
  left: 0,
  right: 0,
  textAlign: "center",
  marginTop: "25px",
  marginBottom: "25px",

  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function Object({
  field_type,
  x,
  y,
  width,
  height,
  children,
  template,
  field_id,
  settingsOptions,
  onMove,
  onResize,
  onDelete,
  onFieldChange,
  onSetRef,
}: {
  field_type: PDFElementType;
  x: number;
  y: number;
  width: number;
  height: number;
  children: any;
  template: Template | undefined;
  field_id: string | undefined;
  settingsOptions: any | undefined;
  onMove: (x: number, y: number) => void;
  onResize: (x: number, y: number) => void;
  onDelete: () => void;
  onFieldChange: (id: string | undefined) => void;
  onSetRef: (ref: any) => void;
}) {
  const [isShowModal, setIsShowModal] = useState(false);
  const [displayArea, setDisplayArea] = useState(false);
  const handlerMove = (mouseDownEvent: any) => {
    const startSize = { x: x, y: y };
    const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };

    function onMouseMove(mouseMoveEvent: any) {
      onMove(
        startSize.x - startPosition.x + mouseMoveEvent.pageX,

        startSize.y - startPosition.y + mouseMoveEvent.pageY,
      );
    }
    function onMouseUp() {
      document.body.removeEventListener("mousemove", onMouseMove);
      // uncomment the following line if not using `{ once: true }`
      // document.body.removeEventListener("mouseup", onMouseUp);
    }

    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("mouseup", onMouseUp, { once: true });
  };

  const handlerResize = (mouseDownEvent: any) => {
    const startSize = { x: width, y: height };
    const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };

    function onMouseMove(mouseMoveEvent: any) {
      onResize(
        startSize.x - startPosition.x + mouseMoveEvent.pageX,
        startSize.y - startPosition.y + mouseMoveEvent.pageY,
      );
    }
    function onMouseUp() {
      document.body.removeEventListener("mousemove", onMouseMove);
      // uncomment the following line if not using `{ once: true }`
      // document.body.removeEventListener("mouseup", onMouseUp);
    }

    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("mouseup", onMouseUp, { once: true });
  };

  return (
    <div
      onMouseOver={() => {
        setDisplayArea(true);
      }}
      onMouseLeave={() => {
        setDisplayArea(false);
      }}
    >
      <Modal open={isShowModal} onClose={() => setIsShowModal(false)}>
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit text area
          </Typography>
          {settingsOptions && settingsOptions}
          {template && (
            <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel id="select-field-text">Select field</InputLabel>
              <Select
                labelId="select-field-text"
                label="select-field-text"
                value={field_id}
                onChange={(e) => {
                  if (e.target.value === "" || !e.target.value) {
                    onFieldChange(undefined);
                    return;
                  }
                  onFieldChange(e.target.value);
                }}
              >
                {template.data
                  .filter((t: any) => {
                    switch (field_type) {
                      case PDFElementType.text:
                        return (
                          (t.type === FieldTypeEnum.text ||
                            t.type === FieldTypeEnum.datetime ||
                            t.type === FieldTypeEnum.choice) &&
                          t.label
                        );
                      case PDFElementType.image:
                        return (
                          (t.type === FieldTypeEnum.file ||
                            t.type === FieldTypeEnum.signature) &&
                          t.label
                        );
                    }
                    return false;
                  })
                  .map((t: any) => (
                    <MenuItem key={t.key} value={t.key}>
                      {t.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
          <Button
            onClick={() => {
              setIsShowModal(false);
              onDelete();
            }}
            color="error"
          >
            Delete
          </Button>
        </Box>
      </Modal>
      <div
        style={{
          cursor: "pointer",
          position: "absolute",
          left: x + document!.getElementById("drawArea")?.offsetLeft! + "px",
          top: y + document!.getElementById("drawArea")?.offsetTop! + "px",
          zIndex: 999,
        }}
      >
        {children}
        <div
          style={{
            position: "relative",
            float: "right",
            display: displayArea ? "block" : "none",
            backgroundColor: "rgba(0, 0, 0, 0.23)",
            borderRadius: "25px",
          }}
        >
          <IconButton color="primary" onMouseDown={handlerMove}>
            <ControlCamera />
          </IconButton>

          <IconButton
            onClick={() => {
              setIsShowModal(true);
            }}
          >
            <Settings color={"warning"} />
          </IconButton>
          <IconButton color="primary" onMouseDown={handlerResize}>
            <AspectRatio />
          </IconButton>
        </div>
      </div>
    </div>
  );
}

export default Object;
