import React, { useEffect, useState } from "react";
import Router from "./routes";
// import Home from "./pages/Home";
import ErrorBoundary from "./pages/Error";
import { UserData } from "./structs/gen/user";

export const UserContext = React.createContext<{
  userData: UserData | undefined;
  setUserData: (newUser: UserData | undefined) => void;
}>({
  userData: undefined,
  setUserData: (newUser: UserData | undefined) => {},
});

function App() {
  const [userData, setUserData] = useState<UserData | undefined>(undefined);

  // useEffect(() => {
  //   if (user) {
  //     setUser(user);
  //   }
  // }, [user]);

  return (
    <ErrorBoundary>
      <UserContext.Provider
        value={{ userData: userData, setUserData: setUserData }}
      >
        <Router />
      </UserContext.Provider>
    </ErrorBoundary>
  );
}
export default App;
