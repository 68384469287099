/* Do not change, this code is generated from Golang structs */


export class Time {


    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);

    }
}
export class Message {
    id: string;
    value: string;
    name: string;
    avatar: string;
    is_read: boolean;
    updated_at: Time;
    created_at: Time;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.id = source["id"];
        this.value = source["value"];
        this.name = source["name"];
        this.avatar = source["avatar"];
        this.is_read = source["is_read"];
        this.updated_at = this.convertValues(source["updated_at"], Time);
        this.created_at = this.convertValues(source["created_at"], Time);
    }

	convertValues(a: any, classs: any, asMap: boolean = false): any {
	    if (!a) {
	        return a;
	    }
	    if (a.slice) {
	        return (a as any[]).map(elem => this.convertValues(elem, classs));
	    } else if ("object" === typeof a) {
	        if (asMap) {
	            for (const key of Object.keys(a)) {
	                a[key] = new classs(a[key]);
	            }
	            return a;
	        }
	        return new classs(a);
	    }
	    return a;
	}
}
export class MessageListResponse {
    count: number;
    messages: Message[];

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.count = source["count"];
        this.messages = this.convertValues(source["messages"], Message);
    }

	convertValues(a: any, classs: any, asMap: boolean = false): any {
	    if (!a) {
	        return a;
	    }
	    if (a.slice) {
	        return (a as any[]).map(elem => this.convertValues(elem, classs));
	    } else if ("object" === typeof a) {
	        if (asMap) {
	            for (const key of Object.keys(a)) {
	                a[key] = new classs(a[key]);
	            }
	            return a;
	        }
	        return new classs(a);
	    }
	    return a;
	}
}