import { Save } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { api } from "../../../services/api";
import { FileFieldTypeObject } from "./file_field_model";
import {
  FieldType,
  Template,
  TemplateListResponse,
} from "../../../structs/gen/template";
import { FieldTypeEnum } from "../../../structs/gen/enums/template";
import MultiLanguageLabel from "../../MultiLanguageLabel";

export default function SelectConfig({
  config,
  onSave,
}: {
  config: FileFieldTypeObject;
  onSave: (newConfig: FileFieldTypeObject) => void;
}) {
  const [newConfig, setNewConfig] = useState(config);

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      <div>
        <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <MultiLanguageLabel
            value={newConfig.label}
            onChange={(v) => {
              setNewConfig({
                ...newConfig,
                label: v,
              });
            }}
            multiValue={newConfig.label_intl}
            onChangeMultiLabel={(v) => {
              setNewConfig({
                ...newConfig,
                label_intl: v,
              });
            }}
          />
        </FormControl>
        <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <TextField
            fullWidth
            label="Max size"
            value={newConfig.max_size}
            type={"number"}
            onChange={(e) => {
              setNewConfig({
                ...newConfig,
                max_size: +e.target.value,
              });
            }}
          />
        </FormControl>
        <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <TextField
            fullWidth
            label="Max items"
            value={newConfig.max_items}
            type={"number"}
            onChange={(e) => {
              setNewConfig({
                ...newConfig,
                max_items: +e.target.value,
              });
            }}
          />
        </FormControl>

        <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <FormControlLabel
            control={
              <Switch
                defaultChecked={newConfig.is_required}
                value={newConfig.is_required}
                onChange={(e) => {
                  setNewConfig({
                    ...newConfig,
                    is_required: e.target.checked,
                  });
                }}
              />
            }
            label="Is required"
          />
        </FormControl>

        <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <FormControlLabel
            control={
              <Switch
                defaultChecked={newConfig.is_visible}
                value={newConfig.is_visible}
                onChange={(e) => {
                  setNewConfig({
                    ...newConfig,
                    is_visible: e.target.checked,
                  });
                }}
              />
            }
            label="Is visible"
          />
        </FormControl>

        <FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
          <FormControlLabel
            control={
              <Switch
                defaultChecked={newConfig.is_disabled}
                value={newConfig.is_disabled}
                onChange={(e) => {
                  setNewConfig({
                    ...newConfig,
                    is_disabled: e.target.checked,
                  });
                }}
              />
            }
            label="Is disabled"
          />
        </FormControl>

        <Button
          onClick={() => {
            console.log("newConfig", newConfig);
            onSave(newConfig);
          }}
          fullWidth
          startIcon={<Save />}
          color={"success"}
          variant="contained"
        >
          Save
        </Button>
      </div>
    </Box>
  );
}
