/* Do not change, this code is generated from Golang structs */


export class Template {
    id: string;
    name: string;
    is_public: boolean;
    is_access_restricted: boolean;
    data: any;
    formula?: string;
    created_at: string;
    updated_at: string;
    menu_id?: string;
    major_version: number;
    minor_version: number;
    formula_version: number;
    execute_on: string[];

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.id = source["id"];
        this.name = source["name"];
        this.is_public = source["is_public"];
        this.is_access_restricted = source["is_access_restricted"];
        this.data = source["data"];
        this.formula = source["formula"];
        this.created_at = source["created_at"];
        this.updated_at = source["updated_at"];
        this.menu_id = source["menu_id"];
        this.major_version = source["major_version"];
        this.minor_version = source["minor_version"];
        this.formula_version = source["formula_version"];
        this.execute_on = source["execute_on"];
    }
}
export class TemplateListResponse {
    count: number;
    rows: Template[];

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.count = source["count"];
        this.rows = this.convertValues(source["rows"], Template);
    }

	convertValues(a: any, classs: any, asMap: boolean = false): any {
	    if (!a) {
	        return a;
	    }
	    if (a.slice) {
	        return (a as any[]).map(elem => this.convertValues(elem, classs));
	    } else if ("object" === typeof a) {
	        if (asMap) {
	            for (const key of Object.keys(a)) {
	                a[key] = new classs(a[key]);
	            }
	            return a;
	        }
	        return new classs(a);
	    }
	    return a;
	}
}
export class FieldType {
    key: string;
    type: string;
    is_visible: boolean;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.key = source["key"];
        this.type = source["type"];
        this.is_visible = source["is_visible"];
    }
}
export class LabelFieldType {
    key: string;
    type: string;
    is_visible: boolean;
    label: string;
    label_intl: {[key: string]: string};
    font_size: number;
    is_bold: boolean;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.key = source["key"];
        this.type = source["type"];
        this.is_visible = source["is_visible"];
        this.label = source["label"];
        this.label_intl = source["label_intl"];
        this.font_size = source["font_size"];
        this.is_bold = source["is_bold"];
    }
}
export class InputFieldType {
    key: string;
    type: string;
    is_visible: boolean;
    label: string;
    label_intl: {[key: string]: string};
    default_value?: string;
    is_required: boolean;
    is_disabled: boolean;
    value_type: string;
    min_lines: number;
    max_lines: number;
    placeholder: string;
    placeholder_intl: {[key: string]: string};

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.key = source["key"];
        this.type = source["type"];
        this.is_visible = source["is_visible"];
        this.label = source["label"];
        this.label_intl = source["label_intl"];
        this.default_value = source["default_value"];
        this.is_required = source["is_required"];
        this.is_disabled = source["is_disabled"];
        this.value_type = source["value_type"];
        this.min_lines = source["min_lines"];
        this.max_lines = source["max_lines"];
        this.placeholder = source["placeholder"];
        this.placeholder_intl = source["placeholder_intl"];
    }
}
export class GroupFieldType {
    key: string;
    type: string;
    is_visible: boolean;
    group: FieldType[];

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.key = source["key"];
        this.type = source["type"];
        this.is_visible = source["is_visible"];
        this.group = this.convertValues(source["group"], FieldType);
    }

	convertValues(a: any, classs: any, asMap: boolean = false): any {
	    if (!a) {
	        return a;
	    }
	    if (a.slice) {
	        return (a as any[]).map(elem => this.convertValues(elem, classs));
	    } else if ("object" === typeof a) {
	        if (asMap) {
	            for (const key of Object.keys(a)) {
	                a[key] = new classs(a[key]);
	            }
	            return a;
	        }
	        return new classs(a);
	    }
	    return a;
	}
}
export class DividerFieldType {
    key: string;
    type: string;
    is_visible: boolean;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.key = source["key"];
        this.type = source["type"];
        this.is_visible = source["is_visible"];
    }
}
export class MediaFieldType {
    key: string;
    type: string;
    is_visible: boolean;
    src: string;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.key = source["key"];
        this.type = source["type"];
        this.is_visible = source["is_visible"];
        this.src = source["src"];
    }
}
export class SelectFieldType {
    key: string;
    type: string;
    is_visible: boolean;
    label: string;
    label_intl: {[key: string]: string};
    is_required: boolean;
    is_multiple: boolean;
    source_type: string;
    source_value: string;
    source_fields: string[];
    is_disabled: boolean;
    default_values: string[];

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.key = source["key"];
        this.type = source["type"];
        this.is_visible = source["is_visible"];
        this.label = source["label"];
        this.label_intl = source["label_intl"];
        this.is_required = source["is_required"];
        this.is_multiple = source["is_multiple"];
        this.source_type = source["source_type"];
        this.source_value = source["source_value"];
        this.source_fields = source["source_fields"];
        this.is_disabled = source["is_disabled"];
        this.default_values = source["default_values"];
    }
}
export class DateTimeFieldType {
    key: string;
    type: string;
    is_visible: boolean;
    label: string;
    label_intl: {[key: string]: string};
    is_required: boolean;
    is_disabled: boolean;
    default_value: string;
    value_type: string;
    placeholder: string;
    placeholder_intl: {[key: string]: string};

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.key = source["key"];
        this.type = source["type"];
        this.is_visible = source["is_visible"];
        this.label = source["label"];
        this.label_intl = source["label_intl"];
        this.is_required = source["is_required"];
        this.is_disabled = source["is_disabled"];
        this.default_value = source["default_value"];
        this.value_type = source["value_type"];
        this.placeholder = source["placeholder"];
        this.placeholder_intl = source["placeholder_intl"];
    }
}
export class FileFieldType {
    key: string;
    type: string;
    is_visible: boolean;
    label: string;
    label_intl: {[key: string]: string};
    is_required: boolean;
    is_disabled: boolean;
    is_preview: boolean;
    max_items: number;
    max_size: number;
    mimetypes: string[];

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.key = source["key"];
        this.type = source["type"];
        this.is_visible = source["is_visible"];
        this.label = source["label"];
        this.label_intl = source["label_intl"];
        this.is_required = source["is_required"];
        this.is_disabled = source["is_disabled"];
        this.is_preview = source["is_preview"];
        this.max_items = source["max_items"];
        this.max_size = source["max_size"];
        this.mimetypes = source["mimetypes"];
    }
}
export class OptionChoice {
    key: string;
    label: string;
    label_intl: {[key: string]: string};

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.key = source["key"];
        this.label = source["label"];
        this.label_intl = source["label_intl"];
    }
}
export class ChoiceFieldType {
    key: string;
    type: string;
    is_visible: boolean;
    is_required: boolean;
    is_disabled: boolean;
    is_multiple: boolean;
    value_type: string;
    default_values: string[];
    label: string;
    label_intl: {[key: string]: string};
    options: OptionChoice[];

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.key = source["key"];
        this.type = source["type"];
        this.is_visible = source["is_visible"];
        this.is_required = source["is_required"];
        this.is_disabled = source["is_disabled"];
        this.is_multiple = source["is_multiple"];
        this.value_type = source["value_type"];
        this.default_values = source["default_values"];
        this.label = source["label"];
        this.label_intl = source["label_intl"];
        this.options = this.convertValues(source["options"], OptionChoice);
    }

	convertValues(a: any, classs: any, asMap: boolean = false): any {
	    if (!a) {
	        return a;
	    }
	    if (a.slice) {
	        return (a as any[]).map(elem => this.convertValues(elem, classs));
	    } else if ("object" === typeof a) {
	        if (asMap) {
	            for (const key of Object.keys(a)) {
	                a[key] = new classs(a[key]);
	            }
	            return a;
	        }
	        return new classs(a);
	    }
	    return a;
	}
}
export class SignatureFieldType {
    key: string;
    type: string;
    is_visible: boolean;
    label: string;
    label_intl: {[key: string]: string};
    is_required: boolean;
    is_disabled: boolean;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.key = source["key"];
        this.type = source["type"];
        this.is_visible = source["is_visible"];
        this.label = source["label"];
        this.label_intl = source["label_intl"];
        this.is_required = source["is_required"];
        this.is_disabled = source["is_disabled"];
    }
}
export class UserLinkFieldType {
    key: string;
    type: string;
    is_visible: boolean;
    label: string;
    label_intl: {[key: string]: string};
    is_required: boolean;
    is_multiple: boolean;
    is_disabled: boolean;
    roles: string[];

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.key = source["key"];
        this.type = source["type"];
        this.is_visible = source["is_visible"];
        this.label = source["label"];
        this.label_intl = source["label_intl"];
        this.is_required = source["is_required"];
        this.is_multiple = source["is_multiple"];
        this.is_disabled = source["is_disabled"];
        this.roles = source["roles"];
    }
}
export class TemplateExecLogResp {
    type: string;
    out: string[];

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.type = source["type"];
        this.out = source["out"];
    }
}
export class TemplateExecResp {
    data: {[key: string]: any};
    config: any;
    user_access?: string[];
    key?: string;
    logs: TemplateExecLogResp[];

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.data = source["data"];
        this.config = source["config"];
        this.user_access = source["user_access"];
        this.key = source["key"];
        this.logs = this.convertValues(source["logs"], TemplateExecLogResp);
    }

	convertValues(a: any, classs: any, asMap: boolean = false): any {
	    if (!a) {
	        return a;
	    }
	    if (a.slice) {
	        return (a as any[]).map(elem => this.convertValues(elem, classs));
	    } else if ("object" === typeof a) {
	        if (asMap) {
	            for (const key of Object.keys(a)) {
	                a[key] = new classs(a[key]);
	            }
	            return a;
	        }
	        return new classs(a);
	    }
	    return a;
	}
}

export class Time {


    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);

    }
}
export class DeltaTemplateHistory {
    NewValue?: string;
    OldValue?: string;
    Position: string;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.NewValue = source["NewValue"];
        this.OldValue = source["OldValue"];
        this.Position = source["Position"];
    }
}
export class TemplateHistory {
    delta: DeltaTemplateHistory[];
    created_at: Time;
    user_name: string;
    user_avatar?: string;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.delta = this.convertValues(source["delta"], DeltaTemplateHistory);
        this.created_at = this.convertValues(source["created_at"], Time);
        this.user_name = source["user_name"];
        this.user_avatar = source["user_avatar"];
    }

	convertValues(a: any, classs: any, asMap: boolean = false): any {
	    if (!a) {
	        return a;
	    }
	    if (a.slice) {
	        return (a as any[]).map(elem => this.convertValues(elem, classs));
	    } else if ("object" === typeof a) {
	        if (asMap) {
	            for (const key of Object.keys(a)) {
	                a[key] = new classs(a[key]);
	            }
	            return a;
	        }
	        return new classs(a);
	    }
	    return a;
	}
}
export class TemplateHistoryListResponse {
    count: number;
    rows: TemplateHistory[];

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.count = source["count"];
        this.rows = this.convertValues(source["rows"], TemplateHistory);
    }

	convertValues(a: any, classs: any, asMap: boolean = false): any {
	    if (!a) {
	        return a;
	    }
	    if (a.slice) {
	        return (a as any[]).map(elem => this.convertValues(elem, classs));
	    } else if ("object" === typeof a) {
	        if (asMap) {
	            for (const key of Object.keys(a)) {
	                a[key] = new classs(a[key]);
	            }
	            return a;
	        }
	        return new classs(a);
	    }
	    return a;
	}
}
export class TableFieldHeader {
    label: string;
    label_intl: {[key: string]: string};

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.label = source["label"];
        this.label_intl = source["label_intl"];
    }
}
export class TableFieldType {
    key: string;
    type: string;
    is_visible: boolean;
    headers: TableFieldHeader[];

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.key = source["key"];
        this.type = source["type"];
        this.is_visible = source["is_visible"];
        this.headers = this.convertValues(source["headers"], TableFieldHeader);
    }

	convertValues(a: any, classs: any, asMap: boolean = false): any {
	    if (!a) {
	        return a;
	    }
	    if (a.slice) {
	        return (a as any[]).map(elem => this.convertValues(elem, classs));
	    } else if ("object" === typeof a) {
	        if (asMap) {
	            for (const key of Object.keys(a)) {
	                a[key] = new classs(a[key]);
	            }
	            return a;
	        }
	        return new classs(a);
	    }
	    return a;
	}
}

export class ExecRequest {
    data: {[key: string]: any};
    key?: string;
    user_access?: string[];

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.data = source["data"];
        this.key = source["key"];
        this.user_access = source["user_access"];
    }
}
export class ExecDevRequest {
    formula: string;
    config: number[];
    data: {[key: string]: any};
    key?: string;
    user_access?: string[];

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.formula = source["formula"];
        this.config = source["config"];
        this.data = source["data"];
        this.key = source["key"];
        this.user_access = source["user_access"];
    }
}