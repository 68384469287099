import React, { useEffect, useState } from "react";
import { PDFDocument, PDFImage, rgb, StandardFonts } from "pdf-lib";
import {
  ImagePDFElement,
  PDFElement,
  PDFElementType,
  TextPDFElement,
} from "./PDF";

function ModifyPage({
  resetButtonType,
  buttonType,
  pdf,
  result,
  bounds,
  fileName,
}: {
  resetButtonType: any;
  buttonType: string;
  pdf: string;
  result: PDFElement[];
  bounds: any;
  fileName: string;
}) {
  useEffect(() => {
    if (buttonType === "download") {
      modifyPdf();
      resetButtonType();
    }
  }, [buttonType]);

  async function modifyPdf() {
    const existingPdfBytes = await fetch(pdf).then((res) => {
      console.log("existingPdfBytes", res);
      return res.arrayBuffer();
    });
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const pages = pdfDoc.getPages();
    var bar = new Promise<void>((resolve, reject) => {
      result.forEach(async (res: PDFElement, index: any, array: any) => {
        // console.log(
        //   "rest",
        //   res,
        //   bounds,
        //   document!.getElementById("drawArea")?.offsetLeft,
        //   document!.getElementById("drawArea")?.offsetTop,
        //   pages[res.page - 1].getSize(),
        // );
        switch (res.type) {
          case PDFElementType.text:
            var text = res as TextPDFElement;
            pages[res.page - 1].drawText(text.text!, {
              x: res.x,
              y: pages[res.page - 1].getSize().height - res.y - text.fontSize,
              size: text.fontSize,
              //font: helveticaFont,
              color: rgb(0, 0, 0), //rgb(0.95, 0.1, 0.1),
              //maxWidth: res.ref.current.getBoundingClientRect().width,
              lineHeight: 15,
            });
            break;
          case PDFElementType.image:
            var img = res as ImagePDFElement;
            var pdfImg = await getImage(pdfDoc, img.url);
            if (!pdfImg) console.log("error pdfImg is not defined");
            else {
              pages[res.page - 1].drawImage(pdfImg, {
                x: res.x,
                y: pages[res.page - 1].getSize().height - res.y - res.height,
                width: res.width,
                height: res.height,
              });
            }
            break;
        }

        if (index === array.length - 1) resolve();
        // if (res.type === "freehand") {
        //   const pathData =
        //     "M " +
        //     res.arr
        //       .map((p: any) => {
        //         return `${p.get("x")},${p.get("y")}`;
        //       })
        //       .join(" L ");
        //   pages[res.page - 1].moveTo(0, pages[0].getHeight());
        //   pages[res.page - 1].drawSvgPath(pathData, {
        //     borderColor: rgb(0.95, 0.1, 0.1),
        //   });
        // }
      });
    });

    bar.then(async () => {
      const pdfBytes = await pdfDoc.save();

      let blob = new Blob([pdfBytes], { type: "application/pdf" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    });
  }

  return (
    <div>
      {/*<button style = {{marginTop: "1%"}} onClick = {modifyPdf}>Download PDF</button>*/}
    </div>
  );
}

async function getImage(
  pdfDoc: PDFDocument,
  url: string | undefined,
): Promise<PDFImage | undefined> {
  if (!url) url = "/no-image.png";
  var arrayBuffer;
  var extension;
  if (url.indexOf("data:image/png;base64") > -1) {
    //processing signature
    arrayBuffer = url;
    extension = "png";
  } else {
    extension = url.split(".").pop();
    arrayBuffer = await fetch(url).then((res) => res.arrayBuffer());
  }

  switch (extension) {
    case "png":
    case "PNG":
      var png = await pdfDoc.embedPng(arrayBuffer);
      return png;
    case "jpg":
    case "JPG":
      var jpg = await pdfDoc.embedJpg(arrayBuffer);
      return jpg;
  }
}

export default ModifyPage;
