export const DASHBOARD_URL = "/";
export const LOGIN_URL = "/login";
export const REGISTER_URL = "/register";
export const FORGOT_PASSWORD_URL = "/forgot";
export const CHECK_EMAIL_URL = "/check_email";
export const CONFIRM_URL = "/confirm";
export const USER_LIST_URL = "/user/list";
export const USER_EDIT_URL = "/user/edit/";
export const TEMPLATE_EDIT_URL = "/template/edit/";
export const TEMPLATE_LIST_URL = "/template/list/";
export const DOCUMENT_URL = "/document/";
export const FILE_LIST_URL = "/file/list";
export const PDF_LIST_URL = "/pdf/list";
export const PDF_EDIT_URL = "/pdf/edit/";
export const EMAIL_LIST_URL = "/email/list";
export const EMAIL_EDIT_URL = "/email/edit/";
