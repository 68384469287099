import {
  Autocomplete,
  createFilterOptions,
  FormControl,
  FormLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { api } from "../../../services/api";
import { DocumentListResponse, Document } from "../../../structs/gen/document";
import { FieldType, Template } from "../../../structs/gen/template";
import { SelectFieldTypeObject } from "./select_field_model";
import { SelectChangeEvent } from "@mui/material/Select";
import { random } from "lodash";
import { FieldTypeEnum } from "../../../structs/gen/enums/template";
import moment from "moment";
import { getFieldValue } from "../../../utils/field";

async function apiGet(id: string): Promise<Template> {
  let resp = await api.get(`/api/template/${id}`);
  return resp.data as Template;
}

async function apiList(template_name: string): Promise<DocumentListResponse> {
  let resp = await api.post(`/api/document/list/${template_name}`, {
    offset: 0,
    next: 100000,
    filters: [],
  });
  return resp.data;
}

const SelectField = ({
  id,
  config,
  values,
  onChange,
  errors,
}: {
  id: string;
  config: SelectFieldTypeObject;
  values: Document[];
  onChange: (value: Document[]) => void;
  errors: string[];
}) => {
  const [isLoadingDocuments, setIsLoadingDocuments] = useState(true);
  const [isDone, setIsDone] = useState(false);

  const [template, setTemplate] = useState<Template>();
  const [variants, setVariants] = useState<Document[]>([]);

  if (isLoadingDocuments) {
    setIsLoadingDocuments(false);
    if (config.source_value && config.source_value !== "") {
      apiGet(config.source_value).then((resp) => {
        setTemplate(resp);
        apiList(config.source_value).then((r) => {
          setVariants(r.rows);
          setIsDone(true);
        });
      });
    } else {
      setIsDone(true);
    }
  }

  const v = values.map((value) => variants.filter((v) => v.id === value.id)[0]);
  const vtd = v
    ? config && config.is_multiple
      ? v
      : v.length > 0
      ? v[0]
      : null
    : null;
  console.log("vtd", vtd, values);

  if (!isDone) {
    return <></>;
  }

  return (
    <FormControl
      fullWidth
      required={config.is_required}
      error={errors && errors.length > 0}
      disabled={config.is_disabled}
    >
      <Autocomplete
        options={variants.sort(
          (a, b) =>
            -getLabel(config, b, template).localeCompare(
              getLabel(config, a, template),
            ),
        )} //
        disablePortal
        multiple={config && config.is_multiple ? true : false}
        value={vtd}
        isOptionEqualToValue={(option, value) =>
          getLabel(config, option, template) ===
          getLabel(config, value, template)
        }
        getOptionLabel={(option) => getLabel(config, option, template)}
        onChange={(event: any, newValues: Document | Document[] | null) => {
          var res = newValues
            ? Array.isArray(newValues)
              ? newValues
              : [newValues]
            : [];
          onChange([...res]);
        }}
        disabled={config.is_disabled}
        renderInput={(params) => <TextField {...params} label={config.label} />} //
      />
    </FormControl>
  );
};

function getLabel(
  config: SelectFieldTypeObject,
  doc: Document,
  template: Template | undefined,
): string {
  var v = "";
  var removeLast = false;
  for (var sf in config.source_fields) {
    if (doc && doc.data && doc.data[config.source_fields[sf]]) {
      var sourceFieldValue = "";

      if (template) {
        sourceFieldValue = getFieldValue(
          config.source_fields[sf],
          doc.data[config.source_fields[sf]],
          template.data,
        );
      }

      v += sourceFieldValue + ", ";
      removeLast = true;
    }
  }
  if (removeLast) {
    v = v.slice(0, -2);
  }
  v = v.trim();
  if (v === "") {
    v = "$No data$";
  }
  return v;
}

export default React.memo(SelectField);
