import { Box, Button, Container, Typography } from "@mui/material";
import * as React from "react";
import Page from "../components/Page";
import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/material/styles";

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

export default class ErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Page title="Error">
          <Container>
            <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
              <Typography variant="h3" paragraph>
                Something went wrong.
              </Typography>

              <Typography sx={{ color: "text.secondary" }}>
                Sorry, we couldn’t find the page you’re looking for. Perhaps
                you’ve mistyped the URL? Be sure to check your spelling.
              </Typography>

              <Box
                component="img"
                src="/static/illustrations/illustration_404.svg"
                sx={{ height: 260, mx: "auto", my: { xs: 5, sm: 10 } }}
              />

              <Button
                size="large"
                variant="contained"
                onClick={() => {
                  window.location.reload();
                }}
              >
                Restore
              </Button>
            </ContentStyle>
          </Container>
        </Page>
      );
    }

    return this.props.children;
  }
}
