import PropTypes from "prop-types";
import { set, sub } from "date-fns";
import { noCase } from "change-case";
import { useState } from "react";
import { alpha, useTheme } from "@mui/material/styles";
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Popover,
  Typography,
  IconButton,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
  TextField,
  OutlinedInput,
} from "@mui/material";
import {
  ChatBubble,
  Done,
  Notifications,
  PunchClock,
  Send,
} from "@mui/icons-material";
import Scrollbar from "./Scrollbar";
import { fDateTimeToGolang, fToNow } from "../utils/formatTime";
import { Message, MessageListResponse } from "../structs/gen/message";
import { time } from "console";
import { api } from "../services/api";
import { MentionsInput, Mention } from "react-mentions";
import { User, UserListResponse } from "../structs/gen/user";
import { Template, TemplateListResponse } from "../structs/gen/template";

async function apiList(): Promise<MessageListResponse> {
  let resp = await api.post(`/api/message/list`, {
    offset: 0,
    next: 100000,
    filters: [],
  });
  return resp.data;
}

async function create(msg: Message): Promise<void> {
  let resp = await api.post(`/api/message/`, msg);
  return resp.data;
}

async function apiListUsers(): Promise<UserListResponse> {
  let resp = await api.post(`/api/user/list?current=yes`, {
    offset: 0,
    next: 100,
    filters: [],
  });
  return resp.data;
}

async function apiListTemplates(): Promise<TemplateListResponse> {
  let resp = await api.post(`/api/template/list`, {
    offset: 0,
    next: 100,
    filters: [],
  });
  return resp.data;
}

export default function ChatPopover() {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState<Message[]>([]);
  const [newMessage, setNewMessage] = useState<Message>(new Message());
  const [users, setUsers] = useState<User[]>([]);
  const [templates, setTemplates] = useState<Template[]>([]);

  const totalUnRead = messages.filter((item) => !item.is_read).length;

  const [open, setOpen] = useState(null);

  const handleOpen = (event: any) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMarkAllAsRead = () => {
    setMessages(
      messages.map((m) => ({
        ...m,
        convertValues: m.convertValues,
        read_at: Date.now(),
      })),
    );
  };

  if (loading) {
    setLoading(false);
    apiList().then((resp) => {
      setMessages(resp.messages);
    });
    apiListUsers().then((resp) => {
      setUsers([
        {
          name: "eLena",
          email: "info@elena.sh",
          id: "elena",
          role: "employee",
          is_google_auth: false,
          is_blocked: false,
          actions: [],
          access: [],
          is_two_factor_auth_enabled: false,
          convertValues: () => {},
        },
        ...resp.rows,
      ]);
    });
    apiListTemplates().then((resp) => {
      setTemplates(resp.rows);
    });
  }

  return (
    <>
      <IconButton
        color={open ? "primary" : "default"}
        onClick={handleOpen}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <ChatBubble />
        </Badge>
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            mt: 1.5,
            ml: 0.75,
            width: 360,
          },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
          <MentionsInput
            value={newMessage.value}
            style={{
              ...defaultMentionStyle,
            }}
            placeholder={
              "Mention people using '@', '#' for templates and `$` for fields"
            }
            a11ySuggestionsListLabel={"Suggested mentions"}
            onChange={(v) => {
              setNewMessage({
                ...newMessage,
                convertValues: newMessage.convertValues,
                value: v.target.value,
                created_at: fDateTimeToGolang(new Date()),
                updated_at: fDateTimeToGolang(new Date()),
              });
            }}
          >
            <Mention
              trigger="@"
              // displayTransform={(id) => {
              //   var user = users.find((u) => u.id === id);
              //   if (user) {
              //     return user.name;
              //   }
              //   return "Not Found";
              // }}
              // markup="@(__id__)"
              displayTransform={(_id, display) => `@${display}`}
              markup="@__id__[__display__]"
              data={(query, callback) => {
                if (!query)
                  return users.map((u) => {
                    return {
                      id: u.id,
                      display: u.name,
                    };
                  });

                const filteredUsers = users
                  .filter((user) => user.name.toLowerCase().includes(query))
                  .map((u) => {
                    return {
                      id: u.id,
                      display: u.name,
                    };
                  });
                callback(filteredUsers);
              }}
              style={{ backgroundColor: "#cee4e5" }}
              onAdd={(e) => {
                console.log("onAdd", e);
              }}
              // renderSuggestion={(id, display, search, highlightedDisplay) => {
              //   return <div className="suggestion-item">@{display}</div>;
              // }}
            />
            <Mention
              trigger="#"
              displayTransform={(_id, display) => `#${display}`}
              markup="#__id__[__display__]"
              data={(query, callback) => {
                if (!query)
                  return templates.map((t) => {
                    return {
                      id: t.id,
                      display: t.name,
                    };
                  });

                const filteredUsers = templates
                  .filter((t) => t.name.toLowerCase().includes(query))
                  .map((t) => {
                    return {
                      id: t.id,
                      display: t.name,
                    };
                  });
                callback(filteredUsers);
              }}
              style={{ backgroundColor: "#63c5da" }}
              onAdd={(e) => {
                console.log("onAdd", e);
              }}
            />
            <Mention
              trigger="$"
              displayTransform={(_id, display) => `$${display}`}
              markup="$__id__[__display__]"
              data={(query, callback) => {
                var fields = [];
                for (var t in templates) {
                  for (var f in templates[t].data) {
                    var field = templates[t].data[f];
                    if (field && field.label) {
                      fields.push({
                        id: field.key,
                        display: field.label,
                      });
                    }
                  }
                }
                if (!query) {
                  return fields;
                }

                const filteredUsers = fields
                  .filter((t) => t.display.toLowerCase().includes(query))
                  .map((t) => {
                    return {
                      id: t.id,
                      display: t.display,
                    };
                  });
                callback(filteredUsers);
              }}
              style={{ backgroundColor: "#757c88" }}
              onAdd={(e) => {
                console.log("onAdd", e);
              }}
            />
          </MentionsInput>
          <IconButton
            color="primary"
            onClick={() => {
              create(newMessage).then((v) => {
                setNewMessage({
                  ...newMessage,
                  convertValues: newMessage.convertValues,
                  value: "",
                });
                setLoading(true);
              });
            }}
            disabled={newMessage.value === ""}
          >
            <Send />
          </IconButton>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Scrollbar sx={{ height: { xs: 340, sm: "auto" } }}>
          {messages.filter((m) => !m.is_read).length > 0 && (
            <List
              disablePadding
              subheader={
                <ListSubheader
                  disableSticky
                  sx={{ py: 1, px: 2.5, typography: "overline" }}
                >
                  New
                </ListSubheader>
              }
            >
              {messages
                .filter((m) => !m.is_read)
                .map((m) => (
                  <MessageItem key={m.id} message={m} />
                ))}
            </List>
          )}

          {messages
            .filter((m) => m.is_read)
            .map((m) => (
              <MessageItem key={m.id} message={m} />
            ))}
        </Scrollbar>

        {/* <Divider sx={{ borderStyle: "dashed" }} />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple>
            View All
          </Button>
        </Box> */}
      </Popover>
    </>
  );
}

// ----------------------------------------------------------------------

MessageItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date),
    id: PropTypes.string,
    isUnRead: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
  }),
};

function MessageItem({ message }: { message: Message }) {
  const { avatar, title } = renderContent(message);

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: "1px",
        ...(!message.is_read && {
          bgcolor: "action.selected",
        }),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: "background.neutral" }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: "flex",
              alignItems: "center",
              color: "text.disabled",
            }}
          >
            {fToNow(new Date(message.created_at.toString()))}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(message: Message) {
  var msg = message.value;
  const regexp = /@([^[]+)\[([^\]]+)\]/g;
  var mentions = message.value.match(regexp);

  if (mentions) {
    for (var i = 0; i < mentions.length; i++) {
      var name = mentions[i].match(/\[(.*?)\]/g);
      if (name && name.length > 0) {
        msg = msg.replaceAll(
          mentions[i],
          "@" + name[0].replaceAll(/[\[\]']+/g, ""),
        );
      }
    }
  }

  const title = (
    <Typography variant="subtitle2">
      {message.name}
      <Typography
        component="span"
        variant="body2"
        sx={{ color: "text.secondary" }}
      >
        &nbsp; {msg}
      </Typography>
    </Typography>
  );
  return {
    avatar: message.avatar ? (
      <Avatar alt={message.name} src={message.avatar} />
    ) : null,
    title,
  };
}

const defaultMentionStyle = {
  width: "100%",
  control: {
    backgroundColor: "#fff",
    fontSize: 14,
    fontWeight: "normal",
  },

  "&multiLine": {
    control: {
      fontFamily: "monospace",
      minHeight: 63,
    },
    highlighter: {
      padding: 9,
      border: "1px solid transparent",
    },
    input: {
      padding: 9,
      border: "1px solid silver",
    },
  },

  "&singleLine": {
    display: "inline-block",
    width: 180,

    highlighter: {
      padding: 1,
      border: "2px inset transparent",
    },
    input: {
      padding: 1,
      border: "2px inset",
    },
  },

  suggestions: {
    list: {
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.15)",
      fontSize: 14,
    },
    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      color: "black",
      "&focused": {
        backgroundColor: "#cee4e5",
      },
    },
  },
};
