import * as React from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  EditOutlined,
  DeleteOutline,
  Add,
  TaskAlt,
  AddCircle,
} from "@mui/icons-material";
// @mui
import {
  Card,
  Stack,
  Divider,
  MenuItem,
  IconButton,
  CardHeader,
  CircularProgress,
  Box,
  Button,
  Modal,
  Typography,
  Chip,
  List,
} from "@mui/material";
// components
// import Iconify from '../../../components/Iconify';
import MenuPopover from "../menu/MenuPopover";
import { api } from "../../services/api";
import { Task } from "../../structs/gen/task";
import { useTranslation } from "react-i18next";
import { Template, TemplateListResponse } from "../../structs/gen/template";
import { User, UserListResponse } from "../../structs/gen/user";
import { TaskStatus } from "../../structs/gen/enums/task";
import { Document } from "../../structs/gen/document";
import TaskForm from "./Task";

// ----------------------------------------------------------------------

Tasks.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
};

async function taskList(callback: any) {
  let resp = await api.post(`/api/task/list`, {
    offset: 0,
    next: 10,
    filters: [],
  });
  console.log("resp.data", resp.data);
  callback(resp.data);
}

async function apiListUsers(): Promise<UserListResponse> {
  let resp = await api.post(`/api/user/list?current=yes`, {
    offset: 0,
    next: 100,
    filters: [],
  });
  return resp.data;
}

async function taskDelete(id: string) {
  let resp = await api.delete(`/api/task/${id}`);
  return resp;
}

async function apiListTemplates(): Promise<TemplateListResponse> {
  let resp = await api.post(`/api/template/list`, {
    offset: 0,
    next: 100,
    filters: [],
  });
  return resp.data;
}

export default function Tasks({ title, subheader, ...other }: any) {
  const { t } = useTranslation();
  const [tasks, setTasks] = React.useState<Task[] | undefined>(undefined);
  const [users, setUsers] = React.useState<User[] | undefined>(undefined);
  const [templates, setTemplates] = React.useState<Template[]>([]);
  const [documents, setDocuments] = React.useState<Document[]>([]);
  const [task, setTask] = React.useState<Task | undefined>(undefined);
  const [loading, setLoading] = React.useState(true);
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    setTask(undefined);
  };

  if (loading) {
    setLoading(false);
    apiListTemplates().then((resp) => {
      setTemplates(resp.rows);
    });
    apiListUsers().then((resp) => {
      setUsers(resp.rows);
    });
    taskList((resp: any) => {
      setTasks(resp.tasks);
    });
    return <CircularProgress />;
  }

  if (!tasks) {
    return <CircularProgress />;
  }

  console.log("Task", task, openModal);

  return (
    <>
      {task && (
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <TaskForm
            task={task}
            setTask={setTask}
            documents={documents}
            templates={templates}
            users={users}
            onSave={() => {
              setLoading(true);
              handleCloseModal();
            }}
          />
        </Modal>
      )}
      <Card {...other}>
        <CardHeader
          title={title}
          subheader={subheader}
          avatar={<TaskAlt />}
          action={
            <IconButton
              aria-label="add"
              color="primary"
              onClick={() => {
                setTask(new Task({ status: TaskStatus.todo }));
                handleOpenModal();
              }}
            >
              <AddCircle />
            </IconButton>
          }
        />

        <List
          sx={{
            width: "100%",
            position: "relative",
            overflow: "auto",
            maxHeight: 300,
          }}
        >
          {tasks.map((task: Task) => (
            <TaskItem
              task={task}
              key={task.id}
              handleOpenModal={handleOpenModal}
              setLoading={setLoading}
              setTask={setTask}
            />
          ))}
        </List>
      </Card>
    </>
  );
}

// ----------------------------------------------------------------------

TaskItem.propTypes = {
  task: PropTypes.object,
};

function TaskItem({
  task,
  setTask,
  handleOpenModal,
  setLoading,
  ...other
}: {
  task: Task;
  setTask: any;
  handleOpenModal: any;
  setLoading: any;
}) {
  const { t } = useTranslation();
  const [openMenu, setOpenMenu] = useState(null);

  const handleOpenMenu = (event: any) => {
    setOpenMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenu(null);
  };

  return (
    <Stack
      direction="row"
      sx={{
        px: 2,
        py: 0.75,
        // ...(checked && {
        //   color: "text.disabled",
        //   textDecoration: "line-through",
        // }),
      }}
    >
      <Stack direction="row" style={{ marginRight: 10 }}>
        {task.status === TaskStatus.todo ? (
          <Chip label="to do" color="primary" />
        ) : task.status === TaskStatus.doing ? (
          <Chip label="doing" color="secondary" />
        ) : (
          <Chip label="done" color="success" />
        )}
      </Stack>

      <Typography
        sx={{ flexGrow: 1, m: 0 }}
        onClick={() => {
          setTask(task);
          handleCloseMenu();
          handleOpenModal();
        }}
      >
        {task.title}
      </Typography>

      <MoreMenuButton
        open={openMenu}
        onClose={handleCloseMenu}
        onOpen={handleOpenMenu}
        actions={
          <>
            {/* <MenuItem
                    onClick={() => {
                      handleCloseMenu();
                    }}
                  >
                    <DoneOutline />
                    {t("task:mark_as_complete")}
                  </MenuItem> */}

            <MenuItem
              onClick={() => {
                setTask(task);
                handleCloseMenu();
                handleOpenModal();
              }}
            >
              <EditOutlined />
              {t("task:edit")}
            </MenuItem>

            <Divider sx={{ borderStyle: "dashed" }} />

            <MenuItem
              onClick={async () => {
                await taskDelete(task.id);
                setLoading(true);
                handleCloseMenu();
              }}
              sx={{ color: "error.main" }}
            >
              <DeleteOutline />
              {t("task:delete")}
            </MenuItem>
          </>
        }
      />
    </Stack>
  );
}

// ----------------------------------------------------------------------

MoreMenuButton.propTypes = {
  actions: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  open: PropTypes.object,
};

function MoreMenuButton({ actions, open, onOpen, onClose }: any) {
  return (
    <>
      <IconButton
        size="large"
        color="inherit"
        sx={{ opacity: 0.48 }}
        onClick={onOpen}
      >
        <MoreVertIcon width={20} height={20} />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={onClose}
        arrow="right-top"
        sx={{
          mt: -0.5,
          width: "auto",
          "& .MuiMenuItem-root": {
            px: 1,
            typography: "body2",
            borderRadius: 0.75,
            "& svg": { mr: 2, width: 20, height: 20 },
          },
        }}
      >
        {actions}
      </MenuPopover>
    </>
  );
}
