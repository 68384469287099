import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  IconButton,
} from "@mui/material";
import { Add, AddCircle, CalendarMonth } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { red } from "@mui/material/colors";

function getDates(): Date[] {
  var dates: Date[] = [];

  for (var i = 0; i < 8; i++) {
    var d = new Date();
    d.setDate(new Date().getDate() + i);
    dates.push(d);
  }

  return dates;
}

export default function CalendarEvents({ title, subheader }: any) {
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader
        title={title}
        subheader={subheader}
        avatar={<CalendarMonth />}
        action={
          <IconButton aria-label="add" color="primary">
            <AddCircle />
          </IconButton>
        }
      />
      <List
        sx={{
          width: "100%",
          position: "relative",
          overflow: "auto",
          maxHeight: 300,
          "& ul": { padding: 0 },
        }}
        subheader={<li />}
      >
        {getDates().map((d) => (
          <li key={`section-${d}`}>
            <ul>
              <ListSubheader>{moment(d).format("DD-MM-YYYY")}</ListSubheader>
              {/* {[0, 1, 2].map((item) => (
                <ListItem key={`item-${d}-${item}`}>
                  <ListItemText primary={`Item ${item}`} />
                </ListItem>
              ))} */}
              <ListItem key={`no events`}>
                <ListItemText primary={`No events`} />
              </ListItem>
            </ul>
          </li>
        ))}
      </List>
    </Card>
  );
}
