import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { api } from "../../services/api";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { FieldType, Template } from "../../structs/gen/template";
import { Card, Container, Input, Stack, TableContainer } from "@mui/material";
import { DOCUMENT_URL } from "../../structs/urls";
import { useNavigate, useParams } from "react-router-dom";
import Page from "../../components/Page";
import { Add, Clear, Search } from "@mui/icons-material";
import { FieldTypeEnum } from "../../structs/gen/enums/template";
import { InputFieldTypeObject } from "../../components/field/input/input_field_model";
import DeleteWithConfirm from "../../components/DeleteWithConfirm";
import { FilterListDto } from "../../structs/gen/basic";
import { DocumentListResponse } from "../../structs/gen/document";
import { getFieldValue } from "../../utils/field";

async function apiList(
  templateID: string,
  offset: number,
  next: number,
): Promise<DocumentListResponse> {
  var f: FilterListDto = new FilterListDto({
    offset: offset,
    next: next,
    filters: [],
  });
  let resp = await api.post(`/api/document/list/${templateID}`, f);
  return resp.data;
}

const nextTake = 1000;

async function apiDelete(id: string) {
  let resp = await api.delete(`/api/document/${id}`);
  return resp.data;
}

async function apiGetTemplate(id: string): Promise<Template> {
  let resp = await api.get(`/api/template/${id}`);
  return resp.data;
}

// async function lazyLoad(
//   templateID: string,
//   offset: number,
//   next: number,
//   data: any,
//   setData: any,
// ) {
//   var resp = await apiList(templateID, offset, next);
//   var result: any[] = [];
//   for (var r in resp.rows) {
//     var doc = resp.rows[r];
//     var row: any = {
//       id: doc.id,
//     };
//     for (var d in doc.data) {
//       row[d] = doc.data[d];
//     }
//     result.push(row);
//   }
//   var newData = [...data, ...result];
//   setData(newData);
//   if (resp.rows.length === nextTake) {
//     await lazyLoad(templateID, offset + nextTake, nextTake, newData, setData);
//   }
// }

export default function DocumentList() {
  const params = useParams();
  const [templateID, setTemplateID] = React.useState<string | undefined>(
    params["template_id"] ? String(params["template_id"]!) : undefined,
  );
  const [template, setTemplate] = React.useState<Template | undefined>();
  const navigate = useNavigate();
  const [rows, setRows] = React.useState<any[] | undefined>();
  const [loading, setLoading] = React.useState(true);
  const [isDone, setIsDone] = React.useState(false);
  const [searchText, setSearchText] = React.useState<string>("");

  let onDelete = async (params: any) => {
    var id = params.row._id;
    await apiDelete(id);
    console.log("rows", rows);
    setLoading(true);
  };

  const defaultFields = [
    {
      field: "id",
      headerName: "ID",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params: any) => {
        return <DeleteWithConfirm onClick={() => onDelete(params)} />;
      },
    },
  ];
  const [columns, setColumns] = React.useState<GridColDef[]>(defaultFields);

  if (
    templateID &&
    params["template_id"] &&
    templateID !== String(params["template_id"]!)
  ) {
    //Reset
    setLoading(true);
    setTemplateID(String(params["template_id"]));
    setColumns(defaultFields);
    setIsDone(false);
  }

  if (loading) {
    setLoading(false);
    apiGetTemplate(templateID!).then((respTemplate: Template) => {
      setTemplate(respTemplate);
      var newColumns = [];
      for (var t in respTemplate.data) {
        const field: FieldType = respTemplate.data[t];
        if (
          field.key &&
          field.is_visible &&
          (field.type === FieldTypeEnum.text ||
            field.type === FieldTypeEnum.datetime ||
            field.type === FieldTypeEnum.choice ||
            field.type === FieldTypeEnum.select)
        ) {
          const input: InputFieldTypeObject = respTemplate.data[t];
          newColumns.push({
            field: field.key,
            headerName: input.label,
          });
        }
      }
      const firstLoad: number = 100000;
      apiList(templateID!, 0, firstLoad).then((resp) => {
        setIsDone(true);
        var result: any[] = [];
        for (var r in resp.rows) {
          var doc = resp.rows[r];
          var row: any = {
            _id: doc.id,
            id: doc.key !== "" ? doc.key : doc.id,
          };
          for (var d in doc.data) {
            if (!doc.data[d]) continue;
            row[d] = getFieldValue(d, doc.data[d], respTemplate.data);
          }
          result.push(row);
        }
        setRows(result);
        // if (resp.rows.length === firstLoad) {
        //   lazyLoad(templateID!, firstLoad, nextTake, result, setRows);
        // }
      });
      setColumns([defaultFields[0], ...newColumns, defaultFields[1]]);
    });
  }

  const Header = () => (
    <input style={{ height: 20 }} onChange={(event) => {}} />
  );

  return (
    <>
      <Page title={template && template.name}>
        <Container maxWidth={false}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              {template && template.name}
            </Typography>
            <Input
              autoFocus
              fullWidth
              style={{
                marginLeft: "40px",
                marginRight: "40px",
              }}
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              startAdornment={<Search />}
              endAdornment={
                searchText !== "" && (
                  <IconButton
                    onClick={() => {
                      setSearchText("");
                    }}
                  >
                    <Clear />
                  </IconButton>
                )
              }
            />
            <Button
              variant="contained"
              onClick={() => {
                navigate(DOCUMENT_URL + templateID! + "/edit/");
              }}
              startIcon={<Add />}
              style={{
                width: "140px",
              }}
            >
              Create
            </Button>
          </Stack>

          <Card>
            <TableContainer sx={{ minWidth: 800 }}>
              <DataGrid
                rows={
                  rows
                    ? rows.filter((r: any) => {
                        return JSON.stringify(r).indexOf(searchText) > -1;
                      })
                    : []
                }
                columns={columns}
                sx={{ overflowX: "scroll" }}
                pageSize={10}
                disableSelectionOnClick
                onCellClick={(e) => {
                  if (e.field !== "action")
                    navigate(DOCUMENT_URL + templateID! + "/edit/" + e.row._id);
                }}
                autoHeight
                loading={!isDone}
              />
            </TableContainer>
          </Card>
        </Container>
      </Page>
    </>
  );
}
